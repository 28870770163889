import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { useSnackbar } from 'notistack';
import { setToggleButton } from '../../../../_reducers/buttonReducer';
import axios from 'axios';

const { getDiffBetweenPixelData } = cornerstoneTools.importInternal(
  'util/segmentationUtils',
);

const StyledButton = withStyles({
  root: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: '#58a5f0',
    borderRadius: 3,
    border: 0,
    color: 'black',
    '&:hover': {
      backgroundColor: '#004c8c',
      color: 'white',
    },
    height: '28px',
    minWidth: '28px',
    padding: '0px',
    margin: '8px 4px',
    boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const BackProBtn = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  //BackProjection eventListener 등록 check flag
  const [backproAddEvent, setBackproAddEvent] = useState(false);

  const dispatch = useDispatch();
  const reduxDicom = useSelector((state) => state.dicom);
  const imgIds = useSelector((state) => state.dicom.imgIds);
  const btnState = useSelector(
    (state) => state.button,
    () => {},
  );
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const stateSeg = useSelector((state) => state.seg);
  const labelindex = useSelector(
    (state) => state.label[currentDicomIdx].labelindex,
  );
  const userId = useSelector((state) => state.user.userData._id);
  const currentDicomName = useSelector(
    (state) => state.dicom.fileNames[currentDicomIdx],
  );
  //material ui snack bar
  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };

  let keyCode = null;

  const { getters, setters } = cornerstoneTools.getModule('segmentation');

  function BackPro() {
    let element = document.querySelector('.viewport-element');
    if (btnState[4]) {
      //BackProjection disable
      dispatch(setToggleButton('backprojection', false));
      window.removeEventListener('keydown', KeyDown);
      element.removeEventListener('cornerstonetoolsmouseup', BackproRelease);
      setBackproAddEvent(false);
      // zoom,pan 기능 비활성화
      cornerstoneTools.setToolPassiveForElement(element, 'RectangleScissors');
      cornerstoneTools.setToolPassiveForElement(element, 'ZoomMouseWheel');
      cornerstoneTools.setToolPassiveForElement(element, 'Pan');
      cornerstoneTools.setToolPassiveForElement(element, 'Wwwc');
    } else {
      //BackProjection Active
      dispatch(setToggleButton('backprojection', true));
      window.addEventListener('keydown', KeyDown);
      element.addEventListener('cornerstonetoolsmouseup', BackproRelease);
      cornerstoneTools.setToolActiveForElement(element, 'RectangleScissors', {
        mouseButtonMask: 1,
      });
      //mouse wheel 동작 시 zoom in / out
      cornerstoneTools.setToolActiveForElement(element, 'ZoomMouseWheel', {
        mouseButtonMask: 5,
      });
      //mouse wheel button drag 시 image move
      cornerstoneTools.setToolActiveForElement(element, 'Pan', {
        mouseButtonMask: 4,
      });
      //windowing active
      cornerstoneTools.setToolActiveForElement(element, 'Wwwc', {
        mouseButtonMask: 2,
      });
      setBackproAddEvent(true);
    }
    cornerstone.updateImage(element);
  }

  function BackproReleaseCallback(e) {
    let element = document.querySelector('.viewport-element');

    let { labelmap3D } = getters.labelmap2D(element);
    //mouse left drag일때에만 동작
    if (e.detail.event.button === 0) {
      // mouse left drag로 rectangle 그리기
      try {
        //rectangle draw history 삭제
        setters.undo(element);
        labelmap3D.redo.pop();
        //'A' 버튼 누른후 다시그릴때까지 현재 labelmap 저장할 array
        let previousPixelData =
          labelmap3D.labelmaps2D[currentDicomIdx].pixelData.slice();

        let brushColor = stateSeg.brushColorIndex;
        let pointS = e.detail.startPoints.image;
        let pointL = e.detail.lastPoints.image;
        let point = [pointS.x, pointS.y, pointL.x, pointL.y];

        //labelreducer-rect에 저장하기전 가지고 있을 변수
        let xMin, xMax, yMin, yMax, width, height;

        let enabledElement = cornerstone.getEnabledElement(element);
        let elementWidth = enabledElement.image.width;
        let elementHeight = enabledElement.image.height;
        //point 좌표가 음수인경우(dicom이미지 보다 마우스 위치가 바깥쪽일때 발생함.) -> 0으로 처리
        //point 좌표가 512>= 인경우(dicom이미지 보다 마우스 위치가 바깥쪽일때 발생함.) -> 512로 처리
        for (let i = 0; i < 4; i++) {
          if (point[i] < 0) point[i] = 0;
        }
        for (let i = 0; i < 4; i++) {
          if (i % 2 === 0) {
            if (point[i] >= elementWidth) point[i] = elementWidth;
          } else {
            if (point[i] >= elementWidth) point[i] = elementHeight;
          }
        }
        // set xMin, xMax
        if (point[0] < point[2]) {
          xMin = point[0];
          xMax = point[2];
        } else {
          xMin = point[2];
          xMax = point[0];
        }
        //set ymin, ymax
        if (point[1] < point[3]) {
          yMin = point[1];
          yMax = point[3];
        } else {
          yMin = point[3];
          yMax = point[1];
        }
        //set width, height
        width = xMax - xMin;
        height = yMax - yMin;

        // dispatch(updateStartEndPos([xMin, yMin, xMax, yMax]));

        //reactangle 그릴 1회용 canvas 생성
        const rectangleCanvas = document.createElement('canvas');
        rectangleCanvas.width = elementWidth;
        rectangleCanvas.height = elementHeight;
        const rectangleCtx = rectangleCanvas.getContext('2d');

        //좌표로 rectangle draw
        rectangleCtx.lineWidth = 2;
        rectangleCtx.strokeStyle = 'red';

        //parameter : 좌상단x,좌상단y,width,height

        rectangleCtx.strokeRect(
          parseInt(xMin),
          parseInt(yMin),
          parseInt(width),
          parseInt(height),
        );
        var imgData = rectangleCtx.getImageData(
          0,
          0,
          elementWidth,
          elementHeight,
        );
        const len = imgData.data.length; // width * height * 4;

        //그려진 reactangle labelmap에 input
        let seg = new Uint16Array(elementWidth * elementHeight);
        for (let i = 0, k = 0; i < len; i += 4, k++) {
          //data[i] ->r
          //data[i+1] ->g
          //data[i+2] ->b
          //data[i+3] ->a
          if (imgData.data[i] === 255) {
            seg[k] = brushColor;
          }
        }
        labelmap3D.labelmaps2D[currentDicomIdx].pixelData = seg;
        cornerstone.updateImage(element);

        rectangleCanvas.remove();

        // backprojection 동작을 위한 parameter
        //rectangle draw 후 formdata flask로 전송
        let voi = cornerstone.getViewport(element).voi;
        let formData = new FormData();
        formData.append('voi', JSON.stringify(voi));
        formData.append(
          'startendpos',
          JSON.stringify([
            parseInt(xMin),
            parseInt(yMin),
            parseInt(xMax),
            parseInt(yMax),
          ]),
        );
        formData.append('color', stateSeg.brushColorIndex);
        formData.append('userId', userId);
        formData.append('objId', searchParams.get('pjId'));
        formData.append('dicomName', currentDicomName);
        formData.append(
          'sopUid',
          reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
        );

        // VFSS인 경우 pj_category parameter 추가
        if (location.pathname === '/labeling/main/VFSS') {
          formData.append('pj_category', '101003');
          // console.log(reduxDicom.imgIds[0].split('&')[3].split('=')[1]);
        }

        axios
          .post(`${process.env.REACT_APP_FLASK_URL}/BackPro`, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then((response) => {
            //이미지 flask 서버에 전송 후 result 가져옴
            let binary_string = window.atob(response.data.result);
            let len = binary_string.length;
            let bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binary_string.charCodeAt(i);
            }
            let seg = new Uint16Array(bytes.buffer);

            let segMax = seg.reduce(function (previous, current) {
              return previous > current ? previous : current;
            });
            let newPixelData = previousPixelData.slice();
            // let seg = Uint16Array.from(response.data.result);
            //keycode가 A인 경우 저장된 labelmap에 response labelmap merge
            //merge 후 keycode 초기화
            if (keyCode === 65) {
              let len = seg.length;
              for (let i = 0; i < len; i++) {
                if (newPixelData[i] === 0 && seg[i] !== 0) {
                  newPixelData[i] = seg[i];
                }
              }
              labelmap3D.labelmaps2D[currentDicomIdx].pixelData = newPixelData;
            } else if (keyCode === 68) {
              let len = seg.length;
              for (let i = 0; i < len; i++) {
                if (seg[i] !== 0) {
                  newPixelData[i] = 0;
                }
              }
              labelmap3D.labelmaps2D[currentDicomIdx].pixelData = newPixelData;
            } else {
              labelmap3D.labelmaps2D[currentDicomIdx].pixelData = seg;
            }
            labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap = [0];
            labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap = [
              ...labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap,
              segMax,
            ];
            cornerstone.updateImage(element);

            //undo, redo를 이용하기 위하여 pixeldata 비교하여 pushState
            // keycode 65,68 : "a","d"키 입력 분기처리
            if ((keyCode === 65) | (keyCode === 68)) {
              let operation = {
                imageIdIndex: currentDicomIdx,
                diff: getDiffBetweenPixelData(previousPixelData, newPixelData),
              };
              setters.pushState(element, [operation]);
            } else {
              let operation = {
                imageIdIndex: currentDicomIdx,
                diff: getDiffBetweenPixelData(previousPixelData, seg),
              };
              setters.pushState(element, [operation]);
            }

            keyCode = null;
            handleClickVariant('변경사항 적용완료', 'success');

            cornerstoneTools.setToolActiveForElement(
              element,
              'RectangleScissors',
              {
                mouseButtonMask: 1,
              },
            );
          })
          //error
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }

    cornerstoneTools.setToolActiveForElement(element, 'RectangleScissors', {
      mouseButtonMask: 1,
    });
  }

  function KeyDownCallback(e) {
    keyCode = e.keyCode;
    if (e.keyCode === 65) {
      handleClickVariant('추가 할 영역을 선택 하세요.', 'info');
    } else if (e.keyCode === 68) {
      handleClickVariant('제거 할 영역을 선택 하세요.', 'info');
    }
  }

  const BackproRelease = useCallback(
    (e) => {
      BackproReleaseCallback(e);
    },
    // eslint-disable-next-line
    [currentDicomIdx, imgIds, stateSeg.brushColorIndex],
  );

  const KeyDown = useCallback(
    (e) => {
      KeyDownCallback(e);
    },
    // eslint-disable-next-line
    [currentDicomIdx, imgIds, stateSeg.brushColorIndex],
  );

  //다른 버튼 클릭시  evenlistener가 등록되있는지 확인후 등록되있으면 remove
  useEffect(() => {
    return () => {
      if (backproAddEvent && btnState[4]) {
        let element = document.querySelector('.viewport-element');
        window.removeEventListener('keydown', KeyDown);
        element.removeEventListener('cornerstonetoolsmouseup', BackproRelease);
        setBackproAddEvent(false);
        // zoom,pan 기능 비활성화
        cornerstoneTools.setToolPassiveForElement(element, 'RectangleScissors');
        // cornerstoneTools.setToolPassiveForElement(element, 'ZoomMouseWheel');
        // cornerstoneTools.setToolPassiveForElement(element, 'Pan');
        // cornerstoneTools.setToolPassiveForElement(element, 'Wwwc');
      }
    };
    // eslint-disable-next-line
  }, [btnState]);

  //버튼 활성화 된상태에서 labellist가 없을 경우 조건처리
  useEffect(() => {
    if (labelindex.length <= 0 && backproAddEvent) {
      return () => {
        let element = document.querySelector('.viewport-element');
        window.removeEventListener('keydown', KeyDown);
        if (element !== null) {
          element.removeEventListener(
            'cornerstonetoolsmouseup',
            BackproRelease,
          );

          setBackproAddEvent(false);
          dispatch(setToggleButton('backprojection', false));
          // zoom,pan 기능 비활성화
          cornerstoneTools.setToolPassiveForElement(
            element,
            'RectangleScissors',
          );
          cornerstoneTools.setToolPassiveForElement(element, 'ZoomMouseWheel');
          cornerstoneTools.setToolPassiveForElement(element, 'Pan');
          cornerstoneTools.setToolPassiveForElement(element, 'Wwwc');
        }
      };
    }
  });

  // 버튼 활성화 상태에서 dicomidx 이동 시 버튼 비활성화
  useEffect(() => {
    return () => {
      let element = document.querySelector('.viewport-element');

      window.removeEventListener('keydown', KeyDown);
      if (element !== null) {
        element.removeEventListener('cornerstonetoolsmouseup', BackproRelease);
        setBackproAddEvent(false);
        dispatch(setToggleButton('backprojection', false));
        // zoom,pan 기능 비활성화
        cornerstoneTools.setToolPassiveForElement(element, 'RectangleScissors');
        cornerstoneTools.setToolPassiveForElement(element, 'ZoomMouseWheel');
        cornerstoneTools.setToolPassiveForElement(element, 'Pan');
        cornerstoneTools.setToolPassiveForElement(element, 'Wwwc');
      }
    };
    // eslint-disable-next-line
  }, [currentDicomIdx, imgIds]);

  return (
    <div>
      <StyledButton
        onClick={BackPro}
        disabled={props.btndisable}
        style={{
          backgroundColor:
            props.btndisable === false
              ? btnState[4] === true
                ? '#004c8c'
                : '#58a5f0'
              : '',
          color:
            props.btndisable === false
              ? btnState[4] === true
                ? 'white'
                : 'black'
              : '',
        }}
      >
        {props.btndisable === false ? (
          btnState[4] === true ? (
            <img
              src={process.env.PUBLIC_URL + '/imgs/icons/backpro.png'}
              alt="backpro"
              style={{
                width: '28px',
                height: '28px',
                filter:
                  'invert(100%) sepia(100%) saturate(0%) hue-rotate(252deg) brightness(102%) contrast(102%)',
              }}
            />
          ) : (
            <img
              src={process.env.PUBLIC_URL + '/imgs/icons/backpro.png'}
              alt="backpro"
              style={{
                width: '28px',
                height: '28px',
              }}
            />
          )
        ) : (
          <img
            src={process.env.PUBLIC_URL + '/imgs/icons/backpro.png'}
            alt="backpro"
            style={{
              width: '28px',
              height: '28px',
              filter: `opacity(23%)`,
            }}
          />
        )}
      </StyledButton>
    </div>
  );
};

export default BackProBtn;
