import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { yellow } from '@mui/material/colors';
import {
  addLabelIndex,
  addLabelName,
  selectCurrLabelIndex,
} from '../../../../_reducers/labelReducer';
import {
  updateLabelampMaxIdx,
  updateBrushcolorIdx,
} from '../../../../_reducers/segmentReducer';

import withStyles from '@mui/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const StyledButton = withStyles({
  root: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: '#58a5f0',
    borderRadius: 3,
    border: 0,
    color: 'black',
    '&:hover': {
      backgroundColor: '#004c8c',
      color: 'white',
    },
    height: '28px',
    minWidth: '28px',
    padding: '0px',
    margin: '8px 4px',
    boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const useStyles = makeStyles(() => ({
  root: {},
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: 4,
    position: 'relative',
  },
  buttonProgress: {
    display: 'flex',
    alignItems: 'center',
    color: yellow[700],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -18,
  },
  icon: {
    width: '28px',
    height: '28px',
    padding: '2px',
    '&:hover': {
      filter:
        'invert(100%) sepia(100%) saturate(0%) hue-rotate(252deg) brightness(102%) contrast(102%)',
    },
  },
}));

const SarcopeniaBtn = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const dicomCnt = useSelector((state) => state.dicom.imgIds.length);
  const label = useSelector((state) => state.label);
  const labelmapMax = useSelector((state) => state.seg.labelmapMaxIndex);
  const currentDicomName = useSelector((state) => state.dicom.fileNames);
  const reduxDicom = useSelector((state) => state.dicom);
  const { setters, getters } = cornerstoneTools.getModule('segmentation');

  //material ui snack bar
  const { enqueueSnackbar } = useSnackbar();

  const [predictBtnDisable, SetPredictBtnDisable] = useState(true);

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  //label add btn event
  function Predict() {
    let element = document.querySelector('.viewport-element');
    let voi = cornerstone.getViewport(element).voi;
    let formData = new FormData();
    formData.append('voi', JSON.stringify(voi));
    formData.append('objId', searchParams.get('pjId'));
    formData.append('dicomName', currentDicomName);
    let sopUids = [];
    for (let i = 0; i < reduxDicom.imgIds.length; i++) {
      sopUids.push(reduxDicom.imgIds[i].split('&')[3].split('=')[1]);
    }
    formData.append('sopUids', sopUids);

    //sarcopenia progress circle
    if (!loading) {
      setLoading(true);
      // timer.current = window.setTimeout(() => {
      //   setLoading(false);
      // }, 2000 * currentDicomName.length);
    }

    axios
      .post(`${process.env.REACT_APP_FLASK_URL}/Sarcopenia`, formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => {
        //이미지 flask 서버에 전송 후 result 가져옴
        //console.log('finish');
        //console.log(response);
        //console.log(response.data);
        let element = document.querySelector('.viewport-element');
        let result_cnt = response.data.result.length;
        const { labelmap3D } = getters.labelmap2D(element);

        labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap = [1, 2, 13];
        let temp = labelmap3D.labelmaps2D[0];

        //sarcopenia label 추가를 위하여 stone predict 개수만큼  cornerstonetool의 labelmap2d에 빈 labelmap 추가
        for (let k = 0; k < result_cnt; k++) {
          setters.activeLabelmapIndex(element, 3 * k + labelmapMax + 1);
          setters.activeLabelmapIndex(element, 3 * k + labelmapMax + 2);
          setters.activeLabelmapIndex(element, 3 * k + labelmapMax + 3);
        }

        // labelmap3D.labelmaps2D = [temp];
        // labelmap3D.labelmaps2D[0].segmentsOnLabelmap = [0, 1, 2];
        for (let j = 0; j < result_cnt; j++) {
          // console.log(j);
          if (response.data.result_chk[j]) {
            // label add
            dispatch(addLabelIndex(3 * j + labelmapMax + 1, j));
            dispatch(addLabelName('muscle', j));

            dispatch(addLabelIndex(3 * j + labelmapMax + 2, j));
            dispatch(addLabelName('subcutaneous', j));

            dispatch(addLabelIndex(3 * j + labelmapMax + 3, j));
            dispatch(addLabelName('visceral', j));
            //predict 하여 추가한 label 선택
            dispatch(selectCurrLabelIndex(label[j].labelindex.length - 1, j));

            // response로 받아온 sarcopenia predict 이미지 배열로 변환
            let binary_string = window.atob(response.data.result[j][0]);
            let len = binary_string.length;
            let bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binary_string.charCodeAt(i);
            }
            let seg = new Uint16Array(bytes.buffer);

            let binary_string2 = window.atob(response.data.result[j][1]);
            let len2 = binary_string2.length;
            let bytes2 = new Uint8Array(len2);
            for (let i = 0; i < len2; i++) {
              bytes2[i] = binary_string2.charCodeAt(i);
            }
            let seg2 = new Uint16Array(bytes2.buffer);

            let binary_string3 = window.atob(response.data.result[j][2]);
            let len3 = binary_string3.length;
            let bytes3 = new Uint8Array(len3);
            for (let i = 0; i < len3; i++) {
              bytes3[i] = binary_string3.charCodeAt(i);
            }
            let seg3 = new Uint16Array(bytes3.buffer);

            // cornerstone labelmap에 위에서 처리한 이미지 배열
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 1
            ].labelmaps2D[j] = temp;
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 1
            ].labelmaps2D[j] = {
              sarcopenia: 'muscle',
            };
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 1
            ].labelmaps2D[j].pixelData = seg;
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 1
            ].labelmaps2D[j].segmentsOnLabelmap = [0, 1];

            //seg2
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 2
            ].labelmaps2D[j] = temp;
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 2
            ].labelmaps2D[j] = {
              sarcopenia: 'subcutaneous',
            };
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 2
            ].labelmaps2D[j].pixelData = seg2;
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 2
            ].labelmaps2D[j].segmentsOnLabelmap = [0, 2];

            //seg3
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 3
            ].labelmaps2D[j] = temp;
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 3
            ].labelmaps2D[j] = {
              sarcopenia: 'visceral',
            };
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 3
            ].labelmaps2D[j].pixelData = seg3;
            getters.labelmaps3D(element).labelmaps3D[
              3 * j + labelmapMax + 3
            ].labelmaps2D[j].segmentsOnLabelmap = [0, 13];

            cornerstone.updateImage(element);
            //updateLabelampMaxIdx
            dispatch(updateLabelampMaxIdx(3 * j + labelmapMax + 3));
            dispatch(updateBrushcolorIdx(13));
          }
          //prediction 후 현재 open된 이미지의 제일 마지막 리스트 선택 후 active lablemap
          let labelLen = label[currentDicomIdx].labelindex.length;
          setters.activeLabelmapIndex(
            element,
            label[currentDicomIdx].labelindex[labelLen - 1],
          );
        }
        handleClickVariant('SarcopeniaPredict Complete', 'success');
        setLoading(false);
      })
      //error
      .catch((error) => {
        //console.log("error");
        console.log(error);
        //console.log(error.response);
      });
  }

  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };

  useEffect(() => {
    if (dicomCnt > 0) {
      SetPredictBtnDisable(false);
    } else {
      SetPredictBtnDisable(true);
    }
  }, [dicomCnt, SetPredictBtnDisable]);

  return (
    <>
      <StyledButton disabled={predictBtnDisable || loading} onClick={Predict}>
        <img
          className={classes.icon}
          src={process.env.PUBLIC_URL + '/imgs/icons/sarcopenia.png'}
          alt="sarcopenia"
        />
        {loading && (
          <CircularProgress
            className={classes.buttonProgress}
            size={36}
            thickness={6}
          />
        )}
      </StyledButton>
    </>
  );
};

export default SarcopeniaBtn;
