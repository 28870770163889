import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import './LabelingTool.css';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';
import BtnGroup from '../component/BtnGroup/BtnGroup';
import RightSide from '../component/RightSide/RightSide';
import DicomViewer from '../component/DicomViewer/DicomViewer';
import { useSelector, useDispatch } from 'react-redux';
import { selectDicomFiles, resetDicom } from '../../../_reducers/dicomReducer';
import { resetLabel, addLabel } from '../../../_reducers//labelReducer';
import {
  updateStep,
  updateModalOpen,
  updateHeaderClick,
} from '../../../_reducers/stepperReducer';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import DeleteIcon from '@mui/icons-material/Delete';

const { setters } = cornerstoneTools.getModule('segmentation');

const LabelingTool = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const series = useSelector((state) => state.series);
  const modalOpen = useSelector((state) => state.stepper.open);
  const headerClick = useSelector((state) => state.stepper.headerclick);
  const seriesIdx = useSelector((state) => state.stepper.seriesIdx);
  const navigate = useNavigate();
  const btnState = useSelector(
    (state) => state.button,
    () => {},
  );
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  //dicom load 후
  useEffect(() => {
    // let seriesIdx = params.idx;
    // const q = queryString.parse(location.search);
    // let Ds = Number(q.Ds);
    // let De = Number(q.De) + 1;
    // const seriesIds = series[seriesIdx].seriesImgIds.slice(Ds, De);
    let list = searchParams.get('list').split(',');
    let Ds = parseInt(searchParams.get('Ds'));
    let De = parseInt(searchParams.get('De'));
    let mergeSeries = [];
    let fileNames = [];

    //선택 series 개수 1개일때
    if (list.length === 1) {
      //load할 series image 개수가 1개일때 예외처리
      if (series[list[0]].seriesImgIds.length === 1) {
        mergeSeries = series[list[0]].seriesImgIds;
        fileNames = series[list[0]].seriesDcmName;
      } else {
        if (isNaN(Ds) | isNaN(De)) {
          mergeSeries = series[list[0]].seriesImgIds;
          fileNames = series[list[0]].seriesDcmName;
        } else {
          mergeSeries = series[list[0]].seriesImgIds.slice(Ds, De + 1);
          fileNames = series[list[0]].seriesDcmName.slice(Ds, De + 1);
        }
      }
    }
    // 선택 series 개수 2개이상
    else if (list.length >= 2) {
      for (let i = 0; i < list.length; i++) {
        mergeSeries = [...mergeSeries, ...series[list[i]].seriesImgIds];
        fileNames = [...fileNames, ...series[list[i]].seriesDcmName];
      }
    }

    // 전달받은 seriesImg 가 존재할때 viewer 상태값 변경후 dicom viewing 처리
    if (mergeSeries.length !== 0) {
      dispatch(selectDicomFiles(mergeSeries, fileNames));
      dispatch(addLabel(mergeSeries.length));
    } else {
      dispatch(addLabel(0));
      dispatch(selectDicomFiles([]));
    }

    //뒤로가기버튼 조건처리
    // window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [dispatch, series, searchParams]);

  useEffect(() => {
    // variant could be success, error, warning, info, or default
    if (btnState[0]) {
      handleClickVariant('Windowing activate', 'success');
    } else if (btnState[1]) {
      handleClickVariant('Pan activate', 'success');
    } else if (btnState[2]) {
      handleClickVariant('Zoom activate', 'success');
    } else if (btnState[3]) {
      handleClickVariant('StackScroll activate', 'success');
    }
    // eslint-disable-next-line
  }, [btnState]);

  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    setOpen(true);
    window.history.pushState(null, null, window.location.pathname);
  };
  useEffect(() => {
    if (modalOpen) {
      setOpen(true);
    }
  }, [modalOpen]);

  const handleClose = () => {
    setOpen(false);
    dispatch(updateModalOpen(false));
  };

  const goSelectDcm = () => {
    let list = searchParams.get('list').split(',');

    let element = document.querySelector('.viewport-element');
    deleteLabelmap(element);
    //cornerstone.reset(element);
    cornerstone.updateImage(element);
    dispatch(resetDicom());
    dispatch(resetLabel());
    //history.goBack();
    //header click
    setOpen(false);
    dispatch(updateModalOpen(false));
    if (headerClick) {
      dispatch(updateStep(0));
      dispatch(updateHeaderClick(false));
      navigate(
        `/labeling/main?stUid=${searchParams.get(
          'stUid',
        )}&pjcate=${searchParams.get('pjcate')}&pjId=${searchParams.get(
          'pjId',
        )}&stty=${searchParams.get('stty')}&process=${searchParams.get(
          'process',
        )}`,
      );
    } else {
      if (list.length === 1) {
        if (
          JSON.stringify(series[seriesIdx].seriesOrientMode) ===
          JSON.stringify(['1', '0', '0', '0', '1', '0'])
        ) {
          dispatch(updateStep(1));
          navigate(
            `/labeling/selectDcm?stUid=${searchParams.get(
              'stUid',
            )}&list=${searchParams.get('list')}&Ds=${searchParams.get(
              'Ds',
            )}&De=${searchParams.get('De')}&pjcate=${searchParams.get(
              'pjcate',
            )}&pjId=${searchParams.get('pjId')}&stty=${searchParams.get(
              'stty',
            )}&process=${searchParams.get('process')}`,
            { state: { pjId: location.state.pjId } },
          );
        } else {
          dispatch(updateStep(0));
          navigate(
            `/labeling/main?stUid=${searchParams.get(
              'stUid',
            )}&pjcate=${searchParams.get('pjcate')}&pjId=${searchParams.get(
              'pjId',
            )}&stty=${searchParams.get('stty')}&process=${searchParams.get(
              'process',
            )}`,
          );
        }
      } else if (list.length >= 2) {
        dispatch(updateStep(0));
        navigate(
          `/labeling/main?stUid=${searchParams.get(
            'stUid',
          )}&pjcate=${searchParams.get('pjcate')}&pjId=${searchParams.get(
            'pjId',
          )}&stty=${searchParams.get('stty')}&process=${searchParams.get(
            'process',
          )}`,
          {
            state: { pjId: location.state.pjId },
          },
        );
      }
    }
  };

  function deleteLabelmap(element) {
    // LabelLen 만큼 반복하여 labelmap 삭제처리
    //1, 2, 8, 9, 13, 16, 25,
    let labelLen = 2000;
    for (let i = 0; i < labelLen; i++) {
      setters.deleteSegment(element, 1, i);
      setters.deleteSegment(element, 2, i);
      setters.deleteSegment(element, 8, i);
      setters.deleteSegment(element, 9, i);
      setters.deleteSegment(element, 13, i);
      setters.deleteSegment(element, 16, i);
      setters.deleteSegment(element, 25, i);
    }
  }

  return (
    <div
      className="LabelingTool"
      style={{
        height: '100%',
        backgroundColor: '#8e8e8e',
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12}>
              <BtnGroup />
            </Grid>
            <Grid item xs={12}>
              <DicomViewer />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid>
            <RightSide />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you Sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Going back to the previous step will discard all changes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Cancel
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            onClick={goSelectDcm}
            variant="contained"
            color="secondary"
            autoFocus
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// export default App;
export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      maxSnack={4}
      autoHideDuration={1750}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <LabelingTool />
    </SnackbarProvider>
  );
}
