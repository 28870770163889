export const UPDATE_STEPSTATE = 'HEADER/UPDATESTATE';
export const UPDATE_DSDE = 'HEADER/UPDATEDSDE';
export const UPDATE_MODALOPEN = 'HEADER/UPDATE_MODALOPEN';
export const UPDATE_HEADERCLICK = 'HEADER/UPDATE_HEADERCLICK';
export const UPDATE_SERIESIDX = 'HEADER/UPDATE_SERIESIDX';

// action creator: 액션을 동적으로 생성하는 펑션
export const updateStep = (step) => ({
  type: UPDATE_STEPSTATE,
  payload: step,
});

export const updateDsDe = (Ds, De) => ({
  type: UPDATE_DSDE,
  payload: { Ds, De },
});
export const updateModalOpen = (open) => ({
  type: UPDATE_MODALOPEN,
  payload: open,
});

export const updateHeaderClick = (headerclick) => ({
  type: UPDATE_HEADERCLICK,
  payload: headerclick,
});

export const updateSeriesIdx = (idx) => ({
  type: UPDATE_SERIESIDX,
  payload: idx,
});

const initialState = {
  stepstate: 0,
  Ds: 0,
  De: 0,
  open: false,
  headerclick: '',
  seriesIdx: '',
};

export const stepperReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STEPSTATE:
      return { ...state, stepstate: action.payload };
    case UPDATE_DSDE:
      return { ...state, Ds: action.payload.Ds, De: action.payload.De };
    case UPDATE_MODALOPEN:
      return { ...state, open: action.payload };
    case UPDATE_HEADERCLICK:
      return { ...state, headerclick: action.payload };
    case UPDATE_SERIESIDX:
      return { ...state, seriesIdx: action.payload };
    default:
      return state;
  }
};
