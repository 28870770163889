import React, { Suspense, lazy } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
// import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Auth from './hoc/auth';
// pages for this product
// import LandingPage from './views/LandingPage/LandingPage';
import { SnackbarProvider } from 'notistack';
import Slide from '@mui/material/Slide';

// import LoginPage from './views/LoginPage/LoginPage';
// import RegisterPage from './views/RegisterPage/RegisterPage';
// import ProjectPage from './views/ProjectPage/ProjectPage';
// import CAIPage from './views/CAIPage/CAIPage';
// import CAIAddPatient from './views/CAIPage/CAIAddPatient';
// import UserPage from './views/UserPage/UserPage';
// import ManagerPage from './views/ManagerPage/ManagerPage';
import LabelingPage from './views/LabelingPage/LabelingPage';
// import StudyListPage from './views/StudyListPage/StudyListPage';
// import NavBar from './views/NavBar/NavBar';
// import SettingPage from './views/SettingPage/SettingPage';
// import TscorePage from './views/StudyListPage/TscorePage';
// import LabPage from './views/LabPage/LabPage';
// import Foot from './views/Footer/Footer';
// import DashboardPage from './views/DashboardPage/DashboardPage';

const LoginPage = lazy(() => import('./views/LoginPage/LoginPage'));
const RegisterPage = lazy(() => import('./views/RegisterPage/RegisterPage'));
const ProjectPage = lazy(() => import('./views/ProjectPage/ProjectPage'));
const CAIPage = lazy(() => import('./views/CAIPage/CAIPage'));
const CAIAddPatient = lazy(() => import('./views/CAIPage/CAIAddPatient'));
const UserPage = lazy(() => import('./views/UserPage/UserPage'));
const ManagerPage = lazy(() => import('./views/ManagerPage/ManagerPage'));
// const LabelingPage = lazy(() => import('./views/LabelingPage/LabelingPage'));
const StudyListPage = lazy(() => import('./views/StudyListPage/StudyListPage'));
const NavBar = lazy(() => import('./views/NavBar/NavBar'));
const SettingPage = lazy(() => import('./views/SettingPage/SettingPage'));
const TscorePage = lazy(() => import('./views/StudyListPage/TscorePage'));
const LabPage = lazy(() => import('./views/LabPage/LabPage'));
const DashboardPage = lazy(() => import('./views/DashboardPage/DashboardPage'));
const SarcopeniaPage = lazy(() =>
  import('./views/SarcopeniaDataPage/SarcopeniaPage'),
);
const SarcopeniaDataAddsubject = lazy(() =>
  import('./views/SarcopeniaDataPage/SarcopeniaDataAddsubject'),
);
const SarcopeniaDataReport = lazy(() =>
  import('./views/SarcopeniaDataPage/SarcopeniaDataReport'),
);

const theme = createTheme();

export default function App() {
  return (
    <BrowserRouter>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Slide}
      >
        <ThemeProvider theme={theme}>
          <Suspense fallback={<div>Loading...</div>}>
            <CssBaseline />
            <NavBar />
            <main>
              <Routes>
                <Route path="/" element={Auth(LoginPage, null)} />
                <Route path="/login" element={Auth(LoginPage, null)} />
                <Route path="/register" element={Auth(RegisterPage, false)} />
                <Route
                  path="/project"
                  element={
                    <Container maxWidth="xl">
                      {Auth(ProjectPage, true)}
                    </Container>
                  }
                />
                <Route
                  path="/CAI"
                  element={
                    <Container maxWidth="xl">{Auth(CAIPage, true)}</Container>
                  }
                />
                <Route
                  path="/addCAI"
                  element={
                    <Container maxWidth="xl">
                      {Auth(CAIAddPatient, true)}
                    </Container>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <Container maxWidth="xl">{Auth(UserPage, true)}</Container>
                  }
                />
                <Route
                  path="/manage"
                  element={
                    <Container maxWidth="xl">
                      {Auth(ManagerPage, true, true)}
                    </Container>
                  }
                />
                <Route path="/labeling/*" element={Auth(LabelingPage, true)} />
                <Route
                  path="/studyList"
                  element={
                    <Container maxWidth="xl">
                      {Auth(StudyListPage, true)}
                    </Container>
                  }
                />
                <Route
                  path="/result"
                  element={
                    <Container maxWidth="xl">
                      {Auth(TscorePage, true)}
                    </Container>
                  }
                />
                <Route
                  path="/setting"
                  element={
                    <Container maxWidth="xl">
                      {Auth(SettingPage, true)}
                    </Container>
                  }
                />
                <Route
                  path="/lab"
                  element={
                    <Container maxWidth="xl">{Auth(LabPage, true)}</Container>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <Container maxWidth="xl">
                      {Auth(DashboardPage, true)}
                    </Container>
                  }
                />
                <Route
                  path="/sarcopenia"
                  element={
                    <Container maxWidth="xl">
                      {Auth(SarcopeniaPage, true)}
                    </Container>
                  }
                />
                <Route
                  path="/addSarcopeniaData"
                  element={
                    <Container maxWidth="xl">
                      {Auth(SarcopeniaDataAddsubject, true)}
                    </Container>
                  }
                />
                <Route
                  path="/sarcopeniaDataReport"
                  element={
                    <Container maxWidth="md">
                      {Auth(SarcopeniaDataReport, true)}
                    </Container>
                  }
                />
              </Routes>
            </main>
            {/* Footer */}
            {/* <Box
            sx={{
              bgcolor: 'background.paper',
              p: '4',
              width: '100%',
              height: '40px',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
            }}
            component="footer"
          >
            <Foot />
          </Box> */}
            {/* End footer */}
          </Suspense>
        </ThemeProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}
