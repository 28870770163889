// ducks 패턴: 액션타입, 액션크리에이터, 리듀서가 모두 동일한 화일에 존재

// action type: 스트링, 모듈명/액션타입으로 표시
export const SET_TOGGLEBUTTON = 'BtnGroup/SET_TOGGLEBUTTON';

// action creator: 액션을 동적으로 생성하는 펑션
export const setToggleButton = (btnname, bool) => ({
  type: SET_TOGGLEBUTTON,
  payload: { btnname, bool },
});

// windowing, pan, zoom, stackScroll, backPro
const initialState = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];
export const buttonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOGGLEBUTTON:
      switch (action.payload.btnname) {
        case 'windowing':
          state = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
          state[0] = action.payload.bool;

          break;
        case 'pan':
          state = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
          state[1] = action.payload.bool;
          break;
        case 'zoom':
          state = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
          state[2] = action.payload.bool;
          break;
        case 'stackscroll':
          state = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
          state[3] = action.payload.bool;
          break;
        case 'backprojection':
          state = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
          state[4] = action.payload.bool;
          break;
        case 'grabcut':
          state = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
          state[5] = action.payload.bool;
          break;
        case 'draw':
          state = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
          state[6] = action.payload.bool;
          break;
        case 'erase':
          state = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
          state[7] = action.payload.bool;
          break;
        case 'slic':
          state = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
          state[8] = action.payload.bool;
          break;
        case 'alldisable':
          state = [
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          ];
          break;
        case 'allenable':
          state = [true, true, true, true, true, true, true, true, true];
          break;
        default:
          break;
      }
      return state;

    default:
      return state;
  }
};
