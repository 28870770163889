import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setImageIdx } from '../../../../_reducers/dicomReducer';
import {
  updateBrushSize,
  updateSegmentsHide,
} from '../../../../_reducers/segmentReducer';
import {
  selectCurrLabelIndex,
  toggleLabelVisible,
  toggleLabelVisibleAll,
  addLabelIndex,
  addLabelName,
  addSlicPos,
  clearLabel,
} from '../../../../_reducers/labelReducer';
import { updateBrushcolorIdx } from '../../../../_reducers/segmentReducer';

import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import '../DicomViewer/ContextMenu.css';

import { Button, Grid, TextField, Divider, Typography } from '@mui/material';
import axios from 'axios';

import cornerstone from 'cornerstone-core';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';
import CornerstoneViewport from 'react-cornerstone-viewport';
import { useSnackbar } from 'notistack';
import Dexie from 'dexie';

const db = new Dexie('VFSS');
db.version(1).stores({
  labels: '[dicomName+labelName]', // Primary key and indexed props
});
db.version(2).stores({
  predlabels: '[dicomName+labelName]', // Primary key and indexed props
});
// Cornerstone Tools
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
// cornerstoneTools.init();

// IMAGE LOADER
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneWebImageLoader.configure({
  beforeSend: function (xhr) {
    // Add custom headers here (e.g. auth tokens)
    //xhr.setRequestHeader('x-auth-token', 'my auth token');
  },
});

const { getters, setters, configuration, state } =
  cornerstoneTools.getModule('segmentation');

const colorList = [1, 2, 8, 9, 13, 16, 25];
const predLabelColorList = [65521, 63286, 63673, 63657, 61789, 61784, 63664];
let segHideOnArr = [];
let segHideOffArr = [];
let segPredHideOnArr = [];
let segPredHideOffArr = [];

for (let i = 0; i < colorList.length; i++) {
  segHideOnArr[colorList[i]] = true;
  segHideOffArr[colorList[i]] = false;
}
for (let i = 0; i < predLabelColorList.length; i++) {
  segPredHideOnArr[predLabelColorList[i]] = true;
  segPredHideOffArr[predLabelColorList[i]] = false;
}
const VFSSDicomViewer = (props) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const dicomfiles = useSelector((state) => state.dicom.imgIds);
  const imageIdx = useSelector((state) => state.dicom.currentImageIdIndex);
  const buttonstate = useSelector((state) => state.button);
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const lb = useSelector((state) => state.label);
  const label = useSelector((state) => state.label[currentDicomIdx]);
  const userId = useSelector((state) => state.user.userData._id);
  const currentDicomName = useSelector((state) => state.dicom.fileNames);
  const selectedSeriesIdx = useSelector((state) => state.vfss.seriesIdx);
  const reduxDicom = useSelector((state) => state.dicom);
  const btnState = useSelector(
    (state) => state.button,
    () => {},
  );
  const segmentsHideStatus = useSelector((state) => state.seg.segmentsHide);
  const cinePlay = useSelector((state) => state.seg.cinePlay);
  const cineFPS = useSelector((state) => state.seg.cineFPS);

  const labelVisible = useSelector((state) => state.vfss.labelVisible);
  const predlabelVisible = useSelector((state) => state.vfss.predlabelVisible);

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };
  const [ww, setWw] = useState(0);
  const [wc, setWc] = useState(0);

  const [dicomTags, setDicomTags] = useState();
  const [indexedDBVersion, setIndexedDBVersion] = useState(1);

  // let stack = {
  //   currentImageIdIndex: 0,
  //   imageIds: dicomfiles,
  // };

  useEffect(() => {
    setDicomTags(props.dicomTags);
  }, [props.dicomTags]);

  const CustomOverlay = (props) => {
    const tagColor = '#9ccef9';
    return (
      <div
        style={{
          position: 'absolute',
          top: '10px',
          bottom: '10px',
          left: '10px',
          width: '100%',
          height: '100%',
          color: tagColor,
        }}
      >
        <div
          id="topleft"
          style={{ position: 'absolute', top: '0px', left: '0px' }}
        >
          <span style={{ color: tagColor }}>
            {dicomTags['00100010'][0] && dicomTags['00100010'][0]['Alphabetic']}
          </span>
          <br />
          <span style={{ color: tagColor }}>{dicomTags['00100020'][0]}</span>
          <br />
          <span style={{ color: tagColor }}>{dicomTags['00081030'][0]}</span>
        </div>
        <div
          id="topright"
          style={{ position: 'absolute', top: '0px', right: '30px' }}
        >
          <span style={{ color: tagColor }}>{dicomTags['00080020'][0]}</span>
        </div>

        <div
          id="bottomleft"
          style={{ position: 'absolute', bottom: '20px', left: '0px' }}
        >
          <br />
          <b>{'Ser: '}</b>
          <span style={{ color: tagColor }}>
            {dicomTags['00200011'][selectedSeriesIdx]}
          </span>
          <br />
          <b>{'Img : '}</b>
          <span
            style={{ color: tagColor }}
          >{`${props['imageIndex']}/${props['stackSize']}`}</span>
          <br />
          <span
            style={{ color: tagColor }}
          >{`${dicomTags['00280010'][selectedSeriesIdx]} x ${dicomTags['00280011'][selectedSeriesIdx]}`}</span>
        </div>
        <div
          id="bottomright"
          style={{ position: 'absolute', bottom: '20px', right: '30px' }}
        >
          <b>{'W: '}</b>
          <span style={{ color: tagColor }}>{`${Math.floor(
            props['windowCenter'],
          )} `}</span>
          <b>{'L: '}</b>
          <span style={{ color: tagColor }}>{`${Math.floor(
            props['windowWidth'],
          )}`}</span>
        </div>
      </div>
    );
  };

  //dicom파일 선택 후 viewer 기본값 세팅
  // useEffect(() => {
  //   if (dicomfiles.length !== 0) {
  //     // dicomfile load 후 첫 image viewing처리
  //     let element = document.querySelector('.viewport-element');
  //     cornerstone.loadImage(dicomfiles[0]).then(function (image) {
  //       cornerstone.displayImage(element, image);
  //     });
  //   }
  // }, [dicomfiles]);

  async function getIndexedDBLabels(dicomName, num, element) {
    let keyList = [
      'Oral',
      'Pharyngeal',
      'Esophageal',
      'Penetration',
      'Aspiration',
    ];

    for (let i = 0; i < keyList.length; i++) {
      db.labels
        .get({
          dicomName: dicomName,
          labelName: keyList[i],
        })
        .then((docs) => {
          if (docs) {
            // label index 1~5 : predict label에서 index 사용
            // index 6~ : 일반 label 에서 index 사용
            let checkIdx = 6 + i;
            const { labelmap3D } = getters.labelmap2D(element);

            // labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap = [
            //   1, 2, 8, 9, 13, 16, 25,
            // ];
            let temp = labelmap3D.labelmaps2D[0];
            // setters.activeLabelmapIndex(element, i);
            let Idx = currentDicomName.findIndex(
              (name) => name === docs.dicomName,
            );
            // console.log('label -> checkIdx: ' + checkIdx + ' Idx: ' + Idx, i);
            // idx 존재 할때만 처리
            if (Idx !== -1) {
              if (currentDicomName[num] === docs.dicomName) {
                // label add
                setters.activeLabelmapIndex(element, checkIdx);

                dispatch(addLabelIndex(checkIdx, Idx));
                dispatch(addLabelName(docs.labelName, Idx));
                dispatch(addSlicPos(Idx));

                let binary_string = window.atob(docs.labelImg);
                let len = binary_string.length;
                let bytes = new Uint8Array(len);
                for (let j = 0; j < len; j++) {
                  bytes[j] = binary_string.charCodeAt(j);
                }
                let seg = new Uint16Array(bytes.buffer);
                // predict boundingbox 좌표로 array buffer 생성
                // let pos = [0.354651, 0.209812, 0.0496829, 0.039666];
                // let newSeg = new Uint16Array(drawBboxSeg(pos, 0, 946, 958));
                // cornerstone labelmap에 위에서 처리한 이미지 배열
                // getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D = [];
                getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[
                  Idx
                ] = temp;
                getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[
                  Idx
                ] = {
                  labelname: docs.labelName,
                  dicomname: docs.dicomName,
                };
                getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[
                  Idx
                ].pixelData = seg;
                // getters.labelmaps3D(element).labelmaps3D[num + 1].segmentsHidden =
                //   segmentsHideStatus ? segHideOnArr : segHideOffArr;
                //label hide 초기화
                getters.labelmaps3D(element).labelmaps3D[
                  checkIdx
                ].segmentsHidden = labelVisible ? segHideOffArr : segHideOnArr;
                //현재 label 활성화 color 지정
                let color = Array.from(new Set(seg));
                getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[
                  Idx
                ].segmentsOnLabelmap = color;

                // checkIdx = checkIdx + 1;
              }
              cornerstone.updateImage(element);
              //label hide status
              // if (segmentsHideStatus) dispatch(toggleLabelVisibleAll(i));
              // update select labelindex
              dispatch(selectCurrLabelIndex(0, num));
            }
          }
        });
    }
  }

  async function getIndexedDBPredictLabels(dicomName, num, element) {
    let keyList = [
      'PredOral',
      'PredPharyngeal',
      'PredEsophageal',
      'PredPenetration',
      'PredAspiration',
    ];

    for (let i = 0; i < keyList.length; i++) {
      db.predlabels
        .get({
          dicomName: dicomName,
          labelName: keyList[i],
        })
        .then((docs) => {
          if (docs !== undefined) {
            // console.log('predict Label Load completed');
            // let checkIdx = (Idx + 1) * 10 + 1;
            // const { labelmap3D } = getters.labelmap2D(element);
            // labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap = [
            //   1, 2, 8, 9, 13, 16, 25,
            // ];
            // let temp = labelmap3D.labelmaps2D[0];
            // setters.activeLabelmapIndex(element, i);
            let Idx = currentDicomName.findIndex(
              (name) => name === docs.dicomName,
            );

            // idx 존재 할때만 처리
            if (Idx !== -1) {
              if (currentDicomName[num] === docs.dicomName) {
                let predLabelIdx = i + 1;
                // label add
                setters.activeLabelmapIndex(element, predLabelIdx);

                dispatch(addLabelIndex(predLabelIdx, Idx));
                dispatch(addLabelName(docs.labelName, Idx));
                dispatch(addSlicPos(Idx));
                let binary_string = window.atob(docs.labelImg);
                let len = binary_string.length;
                let bytes = new Uint8Array(len);
                for (let j = 0; j < len; j++) {
                  bytes[j] = binary_string.charCodeAt(j);
                }
                let seg = new Uint16Array(bytes.buffer);
                // predict boundingbox 좌표로 array buffer 생성
                // let pos = [0.354651, 0.209812, 0.0496829, 0.039666];
                // let newSeg = new Uint16Array(drawBboxSeg(pos, 0, 946, 958));
                // cornerstone labelmap에 위에서 처리한 이미지 배열
                // getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D =
                //   [];
                // getters.labelmaps3D(element).labelmaps3D[num + 1].labelmaps2D[Idx] =
                //   temp;
                getters.labelmaps3D(element).labelmaps3D[
                  predLabelIdx
                ].labelmaps2D[Idx] = {
                  labelname: docs.labelName,
                };
                getters.labelmaps3D(element).labelmaps3D[
                  predLabelIdx
                ].labelmaps2D[Idx].pixelData = seg;
                //label hide 초기화
                getters.labelmaps3D(element).labelmaps3D[
                  predLabelIdx
                ].segmentsHidden = predlabelVisible
                  ? segPredHideOffArr
                  : segPredHideOnArr;
                //현재 label 활성화 color 지정
                let color = Array.from(new Set(seg));
                getters.labelmaps3D(element).labelmaps3D[
                  predLabelIdx
                ].labelmaps2D[Idx].segmentsOnLabelmap = color;
                cornerstone.updateImage(element);
              }
              //label hide status
              // if (segmentsHideStatus) dispatch(toggleLabelVisibleAll(num));
              // update select labelindex
              dispatch(selectCurrLabelIndex(0, num));
            }
          }
        });
    }
  }

  // useEffect(() => {
  //   if (dicomfiles.length !== 0) {
  //     let element = document.querySelector('.viewport-element');

  //     let seriesLen = currentDicomName.length;
  //     for (let num = 0; num < seriesLen; num++) {
  //       getIndexedDBLabels(currentDicomName[num], num, element);
  //     }
  //   }
  // }, [dicomfiles]);

  // function drawVFSSPredictLabel(predictResults, element) {
  //   let keyList = [
  //     'OralPredict',
  //     'PharyngealPredict',
  //     'EsophagealPredict',
  //     'PenetrationPredict',
  //     'AspirationPredict',
  //   ];

  //   const { labelmap3D } = getters.labelmap2D(element);

  //   let labelmapColorList = [1, 25, 9, 2, 16];
  //   let temp = labelmap3D.labelmaps2D[0];

  //   // let len = predictResults.length;

  //   // label 예측 클래스 확인하여 labelmap 색상 지정
  //   setters.activeLabelmapIndex(
  //     element,
  //     labelmapColorList[predictResults.predictClass],
  //   );
  //   let Idx = predictResults.frame;
  //   let checkIdx = lb[Idx].labelindex.length + 1;
  //   console.log('predictlabel Idx : ' + checkIdx);
  //   // label add
  //   setters.activeLabelmapIndex(element, checkIdx);

  //   dispatch(addLabelIndex(checkIdx, Idx));
  //   dispatch(addLabelName(keyList[predictResults.predictClass], Idx));
  //   dispatch(addSlicPos(Idx));
  //   // predict boundingbox 좌표로 array buffer 생성
  //   // let pos = [0.374207, 0.250522, 0.0930233, 0.0688935];

  //   let newSeg = new Uint16Array(
  //     drawBboxSeg(
  //       predictResults.pos,
  //       labelmapColorList[predictResults.predictClass],
  //       946,
  //       958,
  //     ),
  //   );

  //   // cornerstone labelmap에 위에서 처리한 이미지 배열
  //   // getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D = [];
  //   getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[Idx] = temp;
  //   getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[Idx] = {
  //     labelname: keyList[predictResults.predictClass],
  //   };
  //   getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[
  //     Idx
  //   ].pixelData = newSeg;
  //   getters.labelmaps3D(element).labelmaps3D[checkIdx].segmentsHidden =
  //     segmentsHideStatus ? segHideOnArr : segHideOffArr;
  //   //label hide 초기화
  //   // getters.labelmaps3D(element).labelmaps3D[
  //   //   checkIdx
  //   // ].segmentsHidden = [];
  //   //현재 label 활성화 color 지정
  //   let color = Array.from(new Set(newSeg));
  //   getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[
  //     Idx
  //   ].segmentsOnLabelmap = color;
  //   cornerstone.updateImage(element);
  //   checkIdx = checkIdx + 1;

  //   //label hide status
  //   // if (segmentsHideStatus) dispatch(toggleLabelVisibleAll(num));
  //   // update select labelindex
  //   dispatch(selectCurrLabelIndex(checkIdx - 2, Idx));
  // }

  // function drawBboxSeg(bbox, color, w, h) {
  //   // let colorList = [1, 16, 13, 2, 25];
  //   // let color = colorList[colorIdx];
  //   let w_half_len = (bbox[2] * w) / 2;
  //   let h_half_len = (bbox[3] * h) / 2;
  //   let xmin = parseInt(bbox[0] * w - w_half_len);
  //   let ymin = parseInt(bbox[1] * h - h_half_len);
  //   let xmax = parseInt(bbox[0] * w + w_half_len);
  //   let ymax = parseInt(bbox[1] * h + h_half_len);

  //   let setTemp = Array.from({ length: w * h }, () => 0);

  //   //voc - xmin, ymin, xmax, ymax
  //   for (let y = ymin; y <= ymax; y++) {
  //     for (let x = xmin; x <= xmax; x++) {
  //       if (x <= xmin + 2 || x >= xmax - 2) {
  //         setTemp[w * y + x + 1] = color;
  //       } else if (!(y >= ymin + 3 && y <= ymax - 3)) {
  //         setTemp[w * y + x + 1] = color;
  //       }
  //     }
  //   }
  //   return setTemp;
  // }

  useEffect(() => {
    let element = document.querySelector('.viewport-element');
    //Dicom Viewer Tool add
    cornerstoneTools.addToolForElement(element, cornerstoneTools.WwwcTool);
    cornerstoneTools.addToolForElement(element, cornerstoneTools.PanTool);
    cornerstoneTools.addToolForElement(element, cornerstoneTools.ZoomTool);
    cornerstoneTools.addToolForElement(
      element,
      cornerstoneTools.StackScrollTool,
    );
    cornerstoneTools.addStackStateManager(element, ['stack']);
    cornerstoneTools.addToolState(element, 'stack', {
      currentImageIdIndex: 0,
      imageIds: dicomfiles,
    });
    cornerstoneTools.addToolForElement(
      element,
      cornerstoneTools.ZoomMouseWheelTool,
    );
    cornerstoneTools.addToolForElement(
      element,
      cornerstoneTools.RectangleScissorsTool,
    );
    cornerstoneTools.addToolForElement(
      element,
      cornerstoneTools.CorrectionScissorsTool,
    );
    cornerstoneTools.addToolForElement(element, cornerstoneTools.BrushTool);
    //dicom rendering 시 event -> leftSide/DicomSelector index change
    element.addEventListener('cornerstonenewimage', (e) => {
      let imgId = e.detail.image.imageId;
      const imageIds = cornerstoneTools.getToolState(element, 'stack').data[0]
        .imageIds;
      let findIdx = imageIds.findIndex((dicom) => dicom === imgId);

      if (findIdx > 0) {
        dispatch(setImageIdx(findIdx));
      } else {
        dispatch(setImageIdx(0));
      }
      //draw 영역 fill영역만 활성화
      cornerstoneTools.getModule(
        'segmentation',
      ).configuration.renderOutline = false;
      cornerstoneTools.getModule('segmentation').configuration.fillAlpha = 0.55;
      cornerstoneTools.getModule(
        'segmentation',
      ).configuration.fillAlphaInactive = 0.3;
      setters.radius(3);
      // voi.windowCenter = 20;
      // voi.windowWidth = 500;
    });

    // keydown : `[`, `]` 키보드 버튼으로 Radius 크기 조절
    const KeyDown = (e) => {
      let element = document.querySelector('.viewport-element');
      //input영역 선택 중에 shortkey를 누르지 않을때
      if (e.target.constructor !== HTMLInputElement) {
        if (e.keyCode === 219) {
          if (configuration.radius < 0) {
            setters.radius(1);
            dispatch(updateBrushSize(1));
          } else {
            setters.radius(configuration.radius - 1);
            dispatch(updateBrushSize(configuration.radius));
          }
        } else if (e.keyCode === 221) {
          if (configuration.radius > 50) {
            setters.radius(50);
            dispatch(updateBrushSize(50));
          } else {
            setters.radius(configuration.radius + 1);
            dispatch(updateBrushSize(configuration.radius));
          }
        }
        cornerstone.updateImage(element);
      }
    };

    // KeyDown 이벤트 함수 등록 : Radius 조절
    window.addEventListener('keydown', KeyDown);
    return () => window.removeEventListener('keydown', KeyDown);
  }, [dispatch, dicomfiles]);

  //Windowing value 조절 함수
  function handleWindowing(e, data) {
    let element = document.querySelector('.viewport-element');
    let voi = cornerstone.getViewport(element).voi;
    voi.windowCenter = data.wc;
    voi.windowWidth = data.ww;
    setWw(data.ww);
    setWc(data.wc);
    cornerstone.updateImage(element);
  }

  useEffect(() => {
    // labelmap hide toggle : '1', '2', '3', '4' 키보드 버튼으로 1,2,3,4 라벨 hide toggle
    // window.prevKeyCode = null;
    const KeyDownHideToggle = (e) => {
      let element = document.querySelector('.viewport-element');
      let currActiveLabelIndex = getters.activeLabelmapIndex(element);
      if (e.target.constructor !== HTMLInputElement) {
        //input영역 선택 중에 shortkey를 누르지 않을때
        //key 1~9 까지 10개
        // 첫번째 labellist 누를때 labellist 선택
        // 두번재 labellist 누를때 labellist hide
        if ((e.keyCode >= 49) & (e.keyCode <= 57) || e.keyCode === 192) {
          switch (e.keyCode) {
            case 49:
              dispatch(selectCurrLabelIndex(0, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[0]);
              break;
            case 50:
              dispatch(selectCurrLabelIndex(1, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[1]);
              break;
            case 51:
              dispatch(selectCurrLabelIndex(2, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[2]);
              break;
            case 52:
              dispatch(selectCurrLabelIndex(3, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[3]);
              break;
            case 53:
              dispatch(selectCurrLabelIndex(4, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[4]);
              break;
            case 54:
              dispatch(selectCurrLabelIndex(5, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[5]);
              break;
            case 55:
              dispatch(selectCurrLabelIndex(6, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[6]);
              break;
            case 56:
              dispatch(selectCurrLabelIndex(7, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[7]);
              break;
            case 57:
              dispatch(selectCurrLabelIndex(8, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[8]);
              break;
            default:
              break;
          }
          if (window.prevKeyCode === e.keyCode || e.keyCode === 192) {
            const { labelmap3D } = getters.labelmap2D(element);
            // key " ` " 누를때 현재 Label List 모두 hide toggle

            if (e.keyCode === 192) {
              for (let j = 1; j < label.labelindex.length + 5; j++) {
                // console.log(j, getters.labelmaps3D(element).labelmaps3D[j]);
                if (
                  getters.labelmaps3D(element).labelmaps3D[j] !== undefined &&
                  getters.labelmaps3D(element).labelmaps3D[j].labelmaps2D[
                    currentDicomIdx
                  ] !== undefined
                ) {
                  getters.labelmaps3D(element).labelmaps3D[j].segmentsHidden =
                    segmentsHideStatus ? segHideOffArr : segHideOnArr;
                  dispatch(updateSegmentsHide(!segmentsHideStatus));
                  // for (let k = 0; k < colorList.length; k++) {
                  //   setters.toggleSegmentVisibility(element, colorList[k], j);
                  // }
                }
              }
              dispatch(toggleLabelVisibleAll(currentDicomIdx));
            } else {
              let segmentsOnLabelmap =
                labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap;
              // 1개의 labelmap에 여러 색상 segments hide 처리
              for (let i = 0; i < segmentsOnLabelmap.length; i++) {
                setters.toggleSegmentVisibility(element, segmentsOnLabelmap[i]);
              }
              dispatch(toggleLabelVisible(currentDicomIdx));
            }

            cornerstone.updateImage(element);
          }
        }
        window.prevKeyCode = e.keyCode;
      }
      //segmentsOnLabelmap 탑색하여 현재 labelmap 색상 활성화 처리
      const { labelmap3D } = getters.labelmap2D(element);
      let segmentsOnLabelmap =
        labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap;
      if (segmentsOnLabelmap.length > 0) {
        let max = segmentsOnLabelmap.reduce(function (previous, current) {
          return previous > current ? previous : current;
        });
        setters.activeSegmentIndex(element, max);
        dispatch(updateBrushcolorIdx(max));
      }
      setters.activeLabelmapIndex(currActiveLabelIndex);
    };

    window.addEventListener('keydown', KeyDownHideToggle);
    return () => window.removeEventListener('keydown', KeyDownHideToggle);
  }, [currentDicomIdx, dispatch, lb]);

  // label hide 초기화
  // useEffect(() => {
  //   if (label.labelindex.length > 0) {
  //     let element = document.querySelector('.viewport-element');

  //     for (let j = 1; j < label.labelindex.length + 1; j++) {
  //       // console.log(getters.labelmaps3D(element).labelmaps3D[j]);

  //       if (
  //         getters.labelmaps3D(element).labelmaps3D[j] !== undefined &&
  //         getters.labelmaps3D(element).labelmaps3D[j].labelmaps2D[
  //           currentDicomIdx
  //         ] !== undefined
  //       ) {
  //         getters.labelmaps3D(element).labelmaps3D[j].segmentsHidden = [];
  //       }
  //     }
  //   }
  // }, [label]);

  //series 변경 시 해당 series label 전체 Load -> indexedDB에 저장
  // useEffect(() => {
  //   if (selectedSeriesIdx !== '') {
  //     //db 조회하여 현재 Dicom의 Label Load
  //     let formData = new FormData();
  //     formData.append('userId', userId);
  //     formData.append('objId', searchParams.get('pjId'));
  //     formData.append('dicomName', currentDicomName[currentDicomIdx]);
  //     formData.append(
  //       'sopUid',
  //       reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
  //     );

  //     //brush colotLut 추출 -> label 정보 db 조회후 변환을 위함.
  //     let colorLut = [];
  //     colorLut.push(state.colorLutTables[0][1]);
  //     colorLut.push(state.colorLutTables[0][9]);
  //     colorLut.push(state.colorLutTables[0][25]);
  //     colorLut.push(state.colorLutTables[0][2]);
  //     colorLut.push(state.colorLutTables[0][13]);
  //     colorLut.push(state.colorLutTables[0][8]);
  //     colorLut.push(state.colorLutTables[0][16]);

  //     formData.append('colorLut', colorLut);
  //     // db.open().catch(function (err) {
  //     //   console.error('Failed to open db: ' + (err.stack || err));
  //     // });

  //     axios
  //       .post(`${process.env.REACT_APP_FLASK_URL}/LoadVFSSLabel`, formData, {
  //         'Content-Type': 'multipart/form-data',
  //       })
  //       .then((res) => {
  //         db.labels.clear();
  //         if (res.data !== 'none' && res.data !== null) {
  //           let len = res.data.dicomName.length;
  //           let promiseArr = [];
  //           for (let i = 0; i < len; i++) {
  //             // Add VFSS labels
  //             // db.labels.put({
  //             //   dicomName: res.data.dicomName[i],
  //             //   labelColor: res.data.labelColor[i],
  //             //   labelImg: res.data.labelImg[i],
  //             //   labelName: res.data.labelName[i],
  //             // });
  //             promiseArr.push(
  //               db.labels.put({
  //                 dicomName: res.data.dicomName[i],
  //                 labelColor: res.data.labelColor[i],
  //                 labelImg: res.data.labelImg[i],
  //                 labelName: res.data.labelName[i],
  //               }),
  //             );
  //           }
  //           Promise.all(promiseArr).then(() => {
  //             if (dicomfiles.length !== 0) {
  //               let element = document.querySelector('.viewport-element');

  //               getIndexedDBLabels(
  //                 currentDicomName[currentDicomIdx],
  //                 currentDicomIdx,
  //                 element,
  //               );
  //             }
  //             handleClickVariant('Label Load Completed', 'success');
  //           });
  //         }
  //       })

  //       .then(async () => {
  //         //projectid, studyuid, sopuid로 vfss predict 결과조회 -> 있는 경우 비동기 Load 처리
  //         let payload = {
  //           projectId: searchParams.get('pjId'),
  //           sopUid: reduxDicom.imgIds[currentDicomIdx]
  //             .split('&')[3]
  //             .split('=')[1],
  //           studyUid: searchParams.get('stUid'),
  //         };

  //         axios
  //           .post(
  //             `${process.env.REACT_APP_FLASK_URL}/VFSSPredictLabel`,
  //             payload,
  //             {
  //               'Content-Type': 'multipart/form-data',
  //             },
  //           )
  //           .then((res) => {
  //             db.predlabels.clear();
  //             if (res.data !== 'none' && res.data !== null) {
  //               let len = res.data.dicomName.length;
  //               let promiseArr = [];
  //               for (let i = 0; i < len; i++) {
  //                 // Add VFSS labels
  //                 promiseArr.push(
  //                   db.predlabels.put({
  //                     dicomName: res.data.dicomName[i],
  //                     labelColor: res.data.labelColor[i],
  //                     labelImg: res.data.labelImg[i],
  //                     labelName: res.data.labelName[i],
  //                   }),
  //                 );
  //               }
  //               Promise.all(promiseArr).then(() => {
  //                 if (dicomfiles.length !== 0) {
  //                   let element = document.querySelector('.viewport-element');
  //                   getIndexedDBPredictLabels(
  //                     currentDicomName[currentDicomIdx],
  //                     currentDicomIdx,
  //                     element,
  //                   );
  //                 }
  //                 handleClickVariant('Predictlabel Load Completed', 'success');
  //               });
  //             }
  //           });
  //       });

  //     // let element = document.querySelector('.viewport-element');
  //     // axios
  //     //   .post(
  //     //     `${process.env.REACT_APP_EXPRESS_URL}/api/vfss/predictlist`,
  //     //     payload,
  //     //     {
  //     //       'Content-Type': 'multipart/form-data',
  //     //     },
  //     //   )
  //     //   .then((res) => {
  //     //     let len = res.data.docs.length;
  //     //     for (let num = 0; num < len; num++) {
  //     //       drawVFSSPredictLabel(res.data.docs[num], element);
  //     //     }
  //     //   });
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedSeriesIdx]);

  useEffect(() => {
    if (label.labelname.length !== 0) {
      dispatch(clearLabel(currentDicomIdx));
    }

    //projectid, studyuid, sopuid로 vfss predict 결과조회 -> 있는 경우 비동기 Load 처리

    let payload = {
      projectId: searchParams.get('pjId'),
      sopUid: reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
      studyUid: searchParams.get('stUid'),
      frame: currentDicomIdx,
    };

    axios
      .post(`${process.env.REACT_APP_FLASK_URL}/VFSSPredictLabel`, payload, {
        'Content-Type': 'multipart/form-data',
      })
      .then((res) => {
        db.predlabels.clear();
        if (res.data !== 'none' && res.data !== null) {
          let len = res.data.dicomName.length;
          let promiseArr = [];
          for (let i = 0; i < len; i++) {
            // Add VFSS labels
            promiseArr.push(
              db.predlabels.put({
                dicomName: res.data.dicomName[i],
                labelColor: res.data.labelColor[i],
                labelImg: res.data.labelImg[i],
                labelName: res.data.labelName[i],
              }),
            );
          }
          Promise.all(promiseArr).then(() => {
            if (dicomfiles.length !== 0) {
              let element = document.querySelector('.viewport-element');
              getIndexedDBPredictLabels(
                currentDicomName[currentDicomIdx],
                currentDicomIdx,
                element,
              );
            }
            // handleClickVariant('Predictlabel Load Completed', 'success');
          });
        }
      })
      .then(async () => {
        if (searchParams.get('viewer') !== 'vfss') {
          //db 조회하여 현재 Dicom의 Label Load
          let formData = new FormData();
          formData.append('userId', userId);
          formData.append('objId', searchParams.get('pjId'));
          formData.append('dicomName', currentDicomName[currentDicomIdx]);
          formData.append(
            'sopUid',
            reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
          );

          //brush colotLut 추출 -> label 정보 db 조회후 변환을 위함.
          let colorLut = [];
          colorLut.push(state.colorLutTables[0][1]);
          colorLut.push(state.colorLutTables[0][9]);
          colorLut.push(state.colorLutTables[0][25]);
          colorLut.push(state.colorLutTables[0][2]);
          colorLut.push(state.colorLutTables[0][13]);
          colorLut.push(state.colorLutTables[0][8]);
          colorLut.push(state.colorLutTables[0][16]);

          formData.append('colorLut', colorLut);
          // db.open().catch(function (err) {
          //   console.error('Failed to open db: ' + (err.stack || err));
          // });
          axios
            .post(
              `${process.env.REACT_APP_FLASK_URL}/LoadVFSSLabel`,
              formData,
              {
                'Content-Type': 'multipart/form-data',
              },
            )
            .then((res) => {
              db.labels.clear();
              if (res.data !== 'none' && res.data !== null) {
                let len = res.data.dicomName.length;
                let promiseArr = [];
                for (let i = 0; i < len; i++) {
                  // Add VFSS labels
                  // db.labels.put({
                  //   dicomName: res.data.dicomName[i],
                  //   labelColor: res.data.labelColor[i],
                  //   labelImg: res.data.labelImg[i],
                  //   labelName: res.data.labelName[i],
                  // });
                  promiseArr.push(
                    db.labels.put({
                      dicomName: res.data.dicomName[i],
                      labelColor: res.data.labelColor[i],
                      labelImg: res.data.labelImg[i],
                      labelName: res.data.labelName[i],
                    }),
                  );
                }
                Promise.all(promiseArr).then(() => {
                  if (dicomfiles.length !== 0) {
                    let element = document.querySelector('.viewport-element');

                    getIndexedDBLabels(
                      currentDicomName[currentDicomIdx],
                      currentDicomIdx,
                      element,
                    );
                  }
                  // handleClickVariant('Label Load Completed', 'success');
                });
              }
            });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDicomIdx]);

  return (
    <div>
      <ContextMenuTrigger id="context-menu" holdToDisplay={-1}>
        <CornerstoneViewport
          imageIds={dicomfiles}
          imageIdIndex={imageIdx}
          viewportOverlayComponent={CustomOverlay}
          isPlaying={cinePlay}
          frameRate={cineFPS}
          style={{
            width: '100%',
            height: 'calc(100vh - 184px)',
            cursor: btnState[6] ? 'none' : '',
          }}
        />
      </ContextMenuTrigger>
      {!buttonstate[4] &&
        !buttonstate[5] &&
        !buttonstate[6] &&
        !buttonstate[8] && (
          <ContextMenu id="context-menu">
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              sx={{ width: '260px' }}
            >
              <Grid item sx={{ width: '140px' }}>
                <MenuItem onClick={handleWindowing} data={{ ww: 400, wc: 60 }}>
                  CT Abdomen
                </MenuItem>
                <MenuItem onClick={handleWindowing} data={{ ww: 600, wc: 300 }}>
                  CT Angio
                </MenuItem>
                <MenuItem
                  onClick={handleWindowing}
                  data={{ ww: 1500, wc: 300 }}
                >
                  CT Brain
                </MenuItem>
                <MenuItem onClick={handleWindowing} data={{ ww: 80, wc: 40 }}>
                  CT Chest
                </MenuItem>
                <MenuItem
                  onClick={handleWindowing}
                  data={{ ww: 1500, wc: -400 }}
                >
                  CT Lungs
                </MenuItem>
                <MenuItem
                  onClick={handleWindowing}
                  data={{ ww: 2500, wc: 300 }}
                >
                  CT Bone
                </MenuItem>
                <MenuItem onClick={handleWindowing} data={{ ww: 400, wc: 400 }}>
                  micro CT mouse
                </MenuItem>
              </Grid>
              <Divider orientation="vertical" sx={{ height: '210px' }} />
              <Grid item xs={4}>
                <Grid>
                  <Typography variant="subtitle1">Windowing</Typography>
                  <TextField
                    type="number"
                    size="small"
                    id="ww"
                    label="WW"
                    variant="outlined"
                    sx={{ width: '100%', padding: '12px 0px 0px 0px' }}
                    onChange={(e) => {
                      setWw(Number(e.target.value));
                    }}
                  />
                </Grid>
                <Grid>
                  <TextField
                    type="number"
                    size="small"
                    id="wc"
                    label="WC"
                    variant="outlined"
                    sx={{ width: '100%', padding: '8px 0px 0px 0px' }}
                    onChange={(e) => {
                      setWc(Number(e.target.value));
                    }}
                  />
                </Grid>
                <Grid sx={{ padding: '8px 0px 0px 0px' }}>
                  <Button
                    variant="contained"
                    onClick={(e) => handleWindowing(e, { ww: ww, wc: wc })}
                    sx={{ width: '100%' }}
                  >
                    apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </ContextMenu>
        )}
    </div>
  );
};

export default VFSSDicomViewer;
