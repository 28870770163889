import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updatePredictLabelStatus } from '../../../../_reducers/vfssReducer';
import { Grid, Divider, Box, Typography } from '@mui/material';
import axios from 'axios';

const VFSSPredictStatus = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const dicomfiles = useSelector((state) => state.dicom.imgIds);
  const selectedSeriesIdx = useSelector((state) => state.vfss.seriesIdx);
  const predictStatus = useSelector((state) => state.vfss.predictStatus);

  const VFSSPredictStatusSearch = () => {
    let urlSearchParamsObject = new URLSearchParams(dicomfiles[0]);
    let data = {
      pjId: searchParams.get('pjId'),
      studyUid: searchParams.get('stUid'),
      sopUid: urlSearchParamsObject.get('objectUID'),
    };

    // vfss predict result 요청
    axios
      .post(
        `${process.env.REACT_APP_EXPRESS_URL}/api/vfss/predictresult`,
        data,
        {
          'Content-Type': 'multipart/form-data',
        },
      )
      .then((res) => {
        let predictResult = res.data.predictResult;

        // predict label 결과 status 갱신
        dispatch(updatePredictLabelStatus(predictResult));
      });
  };

  // series, dicom index 변경 시 label class check
  // cine view 동작 시 예외처리 필요함.
  useEffect(() => {
    if (selectedSeriesIdx !== '') {
      VFSSPredictStatusSearch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeriesIdx]);

  return (
    <div style={{ backgroundColor: '#D7DBDD' }}>
      <Divider />
      <Box style={{ padding: 2, margin: 8 }}>
        <Typography variant="h6">VFSS Predict Result</Typography>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Box
              style={{
                margin: 2,
                backgroundColor: predictStatus.n ? '#00ff00' : 'white',
                borderRadius: '14px',
              }}
            >
              <Typography
                variant="subtitle1"
                textAlign="center"
                fontWeight="600"
              >
                Normal
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              style={{
                margin: 2,
                backgroundColor: predictStatus.p ? '#ff1507' : 'white',
                borderRadius: '14px',
              }}
            >
              <Typography
                variant="subtitle1"
                textAlign="center"
                fontWeight="600"
              >
                Penetration
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              style={{
                margin: 2,
                backgroundColor: predictStatus.a ? '#ff1507' : 'white',
                borderRadius: '14px',
              }}
            >
              <Typography
                variant="subtitle1"
                textAlign="center"
                fontWeight="600"
              >
                Aspiration
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </div>
  );
};

export default VFSSPredictStatus;
