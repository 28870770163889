import React, { useState, useEffect } from 'react';
import { Grid, Typography, FormControl, Select, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ColorLens } from '@mui/icons-material';

import { useSelector, useDispatch } from 'react-redux';
import { updateBrushcolorIdx } from '../../../../_reducers//segmentReducer';

import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    padding: '8px 8px 8px 8px',
    backgroundColor: ' rgba(255, 255, 255, 0.9)',
    height: '72px',
  },
  formControl: {
    minWidth: '90%',
  },
  selectEmpty: {
    marginTop: 1,
  },
}));

const DrawColor = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [colorDisable, setColorDisable] = useState(false);
  const [colorList, setColorList] = useState([]);
  const dicom = useSelector((state) => state.dicom.imgIds);
  const brushColorIndex = useSelector((state) => state.seg.brushColorIndex);
  let element;

  const { setters, state } = cornerstoneTools.getModule('segmentation');

  //segmentation (색상) List
  useEffect(() => {
    // let List = [];
    // for (let i = 1; i <= 50; i++) {
    //   List = [...List, { value: i }];
    // }
    let List = [
      { value: 1 },
      { value: 2 },
      { value: 13 },
      { value: 8 },
      { value: 9 },
      { value: 25 },
      { value: 16 },
    ];

    setColorList(List);
  }, []);

  //dicom선택 한 후
  useEffect(() => {
    if (dicom.length > 0) {
      setColorDisable(false);
    } else {
      setColorDisable(true);
    }
  }, [dicom]);

  //Brush color - change labelmap
  const handleChange = (event) => {
    //const labelmapIndex = document.getElementById('changeLabelmap').value;
    element = document.querySelector('.viewport-element');

    setters.activeSegmentIndex(element, event.target.value);
    dispatch(updateBrushcolorIdx(event.target.value));
    cornerstone.updateImage(element);
  };

  function selectColorName(index) {
    switch (index) {
      case 1:
        return 'Red';
      case 9:
        return 'Orange';
      case 25:
        return 'Yellow';
      case 2:
        return 'Green';
      case 13:
        return 'Blue';
      case 8:
        return 'Indigo';
      case 16:
        return 'violet';
      default:
        return 'Color';
    }
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <ColorLens />
        <Typography>Draw Color</Typography>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <FormControl
              className={classes.formControl}
              disabled={colorDisable}
            >
              <Select
                id="changeLabelmap"
                displayEmpty
                className={classes.selectEmpty}
                value={brushColorIndex && ''}
                onChange={handleChange}
                style={{
                  height: '32px',
                  backgroundColor: `rgba(${
                    state.colorLutTables[0][brushColorIndex][0]
                  },${state.colorLutTables[0][brushColorIndex][1]},${
                    state.colorLutTables[0][brushColorIndex][2]
                  },${1})`,
                }}
              >
                {colorList.map((list) => (
                  <MenuItem
                    key={list.value}
                    value={list.value}
                    style={{
                      backgroundColor: `rgba(${
                        state.colorLutTables[0][list.value][0]
                      },${state.colorLutTables[0][list.value][1]},${
                        state.colorLutTables[0][list.value][2]
                      },${1})`,
                    }}
                  >
                    {selectColorName(list.value)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DrawColor;
