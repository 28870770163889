import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider,
  Button,
  Box,
} from '@mui/material';

import { LABEL_SERVER } from '../../../../Config';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const VFSSProcess = () => {
  const [value, setValue] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleStatusUpdate = () => {
    let data = {
      pjId: searchParams.get('pjId'),
      stUid: searchParams.get('stUid'),
      status: value,
    };

    axios.post(`${LABEL_SERVER}/Labelingstatus`, data).then(() => {
      enqueueSnackbar('Process Status Updated', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    });
  };

  useEffect(() => {
    let data = {
      pjId: searchParams.get('pjId'),
      stUid: searchParams.get('stUid'),
    };
    axios
      .get(`${LABEL_SERVER}/Labelingstatus`, { params: data })
      .then((res) => {
        let status = res.data.status;
        if (status !== 'none') setValue(res.data.status);
      });
  }, []);

  return (
    <div>
      <Divider />
      <Box>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            height: '100%',
            backgroundColor: '#bfbfbf',
          }}
        >
          <Grid item container style={{ padding: 12 }}>
            <Grid item xs={8}>
              <Typography variant="h5">Labeling Process</Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                size="small"
                sx={{ textTransform: 'none' }}
                onClick={handleStatusUpdate}
              >
                Save
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="error"
                size="small"
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  setValue('');
                }}
              >
                Clear
              </Button>
            </Grid>
            <Grid item>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="In Progress"
                    control={<Radio />}
                    label="In Progress"
                  />
                  <FormControlLabel
                    value="Submitted"
                    control={<Radio />}
                    label="Submitted"
                  />
                  <FormControlLabel
                    value="Skipped"
                    control={<Radio />}
                    label="Skipped"
                  />
                  <FormControlLabel
                    value="Approve"
                    control={<Radio />}
                    label="Approve"
                  />
                  <FormControlLabel
                    value="Reject"
                    control={<Radio />}
                    label="Reject"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </div>
  );
};

export default VFSSProcess;
