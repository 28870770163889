import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HelpIcon from '@mui/icons-material/Help';
import HelpDialog from './HelpDialog';
import Steppers from './Steppers';

import {
  updateStep,
  updateModalOpen,
  updateHeaderClick,
} from '../../../../_reducers/stepperReducer';

const useStyles = makeStyles(() => ({
  appBar: {
    background: '#424242',
    height: '72px',
  },
  toolBar: {
    height: '80px',
    display: 'flex',
  },
  title: {
    display: 'flex',
    flexGrow: 1,
    color: '#C0C0C0',
    cursor: 'pointer',
    fontSize: '24px',
  },
}));

const Header = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [stepperStatus, setStepperStatus] = useState(false);

  const dispatch = useDispatch();
  const classes = useStyles();
  //dialog state
  const [helpOpen, setHelpOpen] = useState(false);
  const step = useSelector((state) => state.stepper.stepstate);

  //dialog open,close
  const handleHelpClose = () => {
    setHelpOpen(false);
  };

  //help dialog
  function Help() {
    setHelpOpen(true);
  }

  // home 으로 이동
  function goHome() {
    //VFSS Labelingtool 아닌경우에만 labelingtool로 처음페이지 이동가능
    if (location.pathname !== '/labeling/main/VFSS') {
      if (step === 2) {
        dispatch(updateModalOpen(true));
        dispatch(updateHeaderClick(true));
      } else {
        dispatch(updateStep(0));
        navigate(`/labeling/main?id=${searchParams.get('id')}`);
      }
    }
  }

  // stepper 비활성화 처리
  useEffect(() => {
    // console.log(location);
    if (location.pathname === '/labeling/main/VFSS') {
      setStepperStatus(false);
    } else {
      setStepperStatus(true);
    }
  }, [location]);

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          {searchParams.get('viewer') === 'vfss' && (
            <Typography className={classes.title} onClick={goHome}>
              VFSS Predict View
            </Typography>
          )}
          {searchParams.get('viewer') !== 'vfss' && (
            <Typography className={classes.title} onClick={goHome}>
              Labeling Tool for Medical Imaging
            </Typography>
          )}
          {stepperStatus && <Steppers />}
          <div>
            <Button color="inherit" onClick={Help}>
              <HelpIcon />
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <HelpDialog onClose={handleHelpClose} open={helpOpen} />
    </div>
  );
};

export default Header;
