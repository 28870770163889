// ducks 패턴: 액션타입, 액션크리에이터, 리듀서가 모두 동일한 화일에 존재

// action type: 스트링, 모듈명/액션타입으로 표시
export const SET_SERIES = 'MAIN/SET_SERIES';
export const ADD_SERIES = 'MAIN/ADD_SERIES';
export const ADD_SERIESDCMNAME = 'MAIN/ADD_SERIESIMG';
export const RESET_SERIES = 'MAIN/RESET_SERIES';
export const SORT_SERIES = 'MAIN/SORT_SERIES';
export const MODE_SERIES = 'MAIN/MODE_SERIES';

// action creator: 액션을 동적으로 생성하는 펑션
export const setSeries = (seriesIdx, series) => ({
  type: SET_SERIES,
  payload: { seriesIdx, series },
});

export const addSeries = (series) => ({
  type: ADD_SERIES,
  payload: series,
});

export const addSeriesDcmName = (
  seriesIdx,
  dcmName,
  seriesImgIds,
  seriesInstanceNum,
  seriesOrientation,
) => ({
  type: ADD_SERIESDCMNAME,
  payload: {
    seriesIdx,
    dcmName,
    seriesImgIds,
    seriesInstanceNum,
    seriesOrientation,
  },
});

export const resetSeries = () => ({
  type: RESET_SERIES,
  payload: {},
});

export const sortSeries = () => ({
  type: SORT_SERIES,
  payload: {},
});

export const modeSeries = () => ({
  type: MODE_SERIES,
  payload: {},
});

const initialState = [
  // {
  //   seriesNumber: null,
  //   seriesDescription: null,
  //   seriesImgIds: [],
  // },
];

export const seriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERIES:
      //   state[0].seriesNumber = action.payload.seriesNumber;
      //   state[0].seriesDescription = action.payload.seriesDescription;
      //   state[0].seriesImgIds.push(action.payload.seriesImgIds);
      let seriesIdx = action.payload.seriesIdx;
      state[seriesIdx].studyNumber = action.payload.series.studyNumber;
      state[seriesIdx].studyDescription =
        action.payload.series.studyDescription;
      state[seriesIdx].seriesNumber = action.payload.series.seriesNumber;
      state[seriesIdx].seriesDescription =
        action.payload.series.seriesDescription;
      state[seriesIdx].seriesBodyPart = action.payload.series.seriesBodyPart;
      state[seriesIdx].seriesImgIds = action.payload.series.seriesImgIds;
      state[seriesIdx].seriesDcmName = action.payload.series.seriesDcmName;
      state[seriesIdx].seriesInstanceNum =
        action.payload.series.seriesInstanceNum;
      state[seriesIdx].seriesOrientation =
        action.payload.series.seriesOrientation;
      state[seriesIdx].seriesOrientMode =
        action.payload.series.seriesOrientMode;
      return state;

    case ADD_SERIES:
      state = [
        ...state,
        {
          seriesNumber: null,
          seriesDescription: null,
          seriesBodyPart: null,
          seriesOrientation: [],
          seriesImgIds: [],
          seriesDcmName: [],
          seriesInstanceNum: [],
        },
      ];
      //state.push();
      return state;

    case ADD_SERIESDCMNAME:
      let Idx = action.payload.seriesIdx;
      state[Idx].seriesDcmName.push(action.payload.dcmName);
      state[Idx].seriesImgIds.push(action.payload.seriesImgIds);
      state[Idx].seriesInstanceNum.push(action.payload.seriesInstanceNum);
      state[Idx].seriesOrientation.push(action.payload.seriesOrientation);
      return state;
    case RESET_SERIES:
      state = [];
      return state;

    case SORT_SERIES:
      for (let i = 0; i < state.length; i++) {
        let tempArr = state[i].seriesInstanceNum.map((num) => Number(num));
        let tempArr2 = state[i].seriesDcmName;
        let tempArr3 = state[i].seriesImgIds;

        let resultArr = quickSort(tempArr, tempArr2, tempArr3);
        if (resultArr !== undefined) {
          state[i].seriesInstanceNum = resultArr[0];
          state[i].seriesDcmName = resultArr[1];
          state[i].seriesImgIds = resultArr[2];
        }
      }
      return state;
    case MODE_SERIES:
      for (let i = 0; i < state.length; i++) {
        let arr = state[i].seriesOrientation;
        //최빈값 알고리즘
        const newObject = arr.reduce((acc, cur) => {
          acc.hasOwnProperty(cur) ? (acc[cur] += 1) : (acc[cur] = 1);
          return acc;
        }, {});

        const modeKey = Object.keys(newObject).reduce((acc, cur) =>
          newObject[acc] > newObject[cur] ? acc : cur,
        );
        state[i].seriesOrientMode = modeKey.split(',');
      }
      return state;

    default:
      return state;
  }
};

function quickSort(array, array2, array3, left = 0, right = array.length - 1) {
  if (left >= right) {
    return;
  }
  const mid = Math.floor((left + right) / 2);
  const pivot = array[mid];
  const partition = divide(array, array2, array3, left, right, pivot);
  quickSort(array, array2, array3, left, partition - 1);
  quickSort(array, array2, array3, partition, right);

  function divide(array, array2, array3, left, right, pivot) {
    while (left <= right) {
      while (array[left] < pivot) {
        left++;
      }
      while (array[right] > pivot) {
        right--;
      }

      if (left <= right) {
        let temp = array[left];
        array[left] = array[right];
        array[right] = temp;

        let temp2 = array2[left];
        array2[left] = array2[right];
        array2[right] = temp2;

        let temp3 = array3[left];
        array3[left] = array3[right];
        array3[right] = temp3;

        left++;
        right--;
      }
    }
    return left;
  }
  return [array, array2, array3];
}

// var partition = function (array, array2, array3, left, right, pivotIndex) {
//   // 정렬하는 부분
//   var temp;
//   let temp2;
//   let temp3;

//   var pivot = array[pivotIndex];
//   while (left <= right) {
//     // 왼쪽, 오른쪽 수를 규칙과 비교해 다음 수로 넘어갑니다.
//     while (array[left] < pivot) left++;
//     while (array[right] > pivot) right--;
//     if (left <= right) {
//       // 왼쪽이 기준보다 크고, 오른쪽이 기준보다 작으면
//       temp = array[left];
//       temp2 = array2[left];
//       temp3 = array3[left];
//       array[left] = array[right];
//       array2[left] = array2[right];
//       array3[left] = array3[right];
//       array[right] = temp; // 서로 바꿔줍니다.
//       array2[right] = temp2;
//       array3[right] = temp3;
//       left++;
//       right--;
//     }
//   }
//   temp = array[left];
//   temp2 = array2[left];
//   temp3 = array3[left];
//   array[left] = array[pivotIndex];
//   array2[left] = array2[pivotIndex];
//   array3[left] = array3[pivotIndex];
//   array[pivotIndex] = temp; // 마지막으로 기준과 만난 수를 바꿔줍니다. 기준의 위치는 이제 i입니다.
//   array2[pivotIndex] = temp2;
//   array3[pivotIndex] = temp3;
//   return left;
// };

// var quickSort = function (array, array2, array3, left, right) {
//   // 재귀하는 부분
//   if (!left) left = 0;
//   if (!right) right = array.length - 1;
//   var pivotIndex = right; // 배열 가장 오른쪽의 수를 기준으로 뽑습니다.
//   pivotIndex = partition(array, array2, array3, left, right - 1, pivotIndex); // right - 1을 하는 이유는 기준(현재 right)을 제외하고 정렬하기 위함입니다.
//   if (left < pivotIndex - 1)
//     quickSort(array, array2, array3, left, pivotIndex - 1); // 기준 왼쪽 부분 재귀
//   if (pivotIndex + 1 < right)
//     quickSort(array, array2, array3, pivotIndex + 1, right); // 기준 오른쪽 부분 재귀

//   return [array, array2, array3];
// };
