import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './component/Header/Header';
import MainPage from './pages/MainPage';
import LabelingTool from './pages/LabelingTool';
import SelectDcm from './pages/SelectDcm';
import VFSSLabeling from './pages/VFSSLabeling';
import { Grid } from '@mui/material';

function LabelingPage() {
  return (
    <div
      style={{
        height: 'calc(100vh - 64px)',
        backgroundColor: '#8e8e8e',
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Header />
        </Grid>
        <div>
          <Routes>
            <Route path="/main/*" element={<MainPage />} />
            <Route path="/main/VFSS/*" element={<VFSSLabeling />} />
            <Route path="/selectdcm/*" element={<SelectDcm />} />
            <Route path="/labelingtool/*" element={<LabelingTool />} />
          </Routes>
        </div>
      </Grid>
    </div>
  );
}
export default LabelingPage;
