import React, { useState, useEffect } from 'react';
import { Grid, Typography, Slider, Input } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Brush } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { updateBrushSize } from '../../../../_reducers/segmentReducer';
import cornerstoneTools from 'cornerstone-tools';
const { setters } = cornerstoneTools.getModule('segmentation');

////////////////////
// custom style
////////////////////
const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    padding: '8px 8px 8px 8px',
    backgroundColor: ' rgba(255, 255, 255, 0.9)',
    height: '88px',
  },
  input: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: 8,
  },
}));

////////////////////
// radius mark
////////////////////
const radiusMark = [
  {
    value: 1,
    label: '1',
  },
  { value: 50, label: '50' },
];

const Radius = () => {
  const dispatch = useDispatch();
  const brushsize = useSelector((state) => state.seg.brushSize);
  const classes = useStyles();
  const [value, setValue] = useState(10);

  function brushRadius(newRadius) {
    //console.log(cornerstoneTools.store.state.tools[0]);
    setters.radius(newRadius);
  }

  //brush radius change
  const handleSliderChange = (e, newValue) => {
    setValue(newValue);
    dispatch(updateBrushSize(newValue));
    brushRadius(newValue);
  };

  //brush radius input change handle
  const handleInputChange = (e) => {
    setValue(Number(e.target.value));
    dispatch(updateBrushSize(e.target.value));
    return e.target.value === '' ? '' : brushRadius(Number(e.target.value));
  };

  //brush radius 최대/최소 범위 유효성 검증
  const handleBlur = () => {
    if (value < 0) {
      setValue(1);
      brushRadius(1);
    } else if (value > 50) {
      setValue(50);
      brushRadius(50);
    }
  };

  useEffect(() => {
    if (brushsize < 0) {
      setValue(1);
      brushRadius(1);
    } else if (value > 50) {
      setValue(50);
      brushRadius(50);
    } else {
      setValue(brushsize);
    }
    // eslint-disable-next-line
  }, [brushsize]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Brush />
        <Typography> Radius (1~50)</Typography>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={7}>
            <Slider
              value={typeof value === 'number' ? value : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              min={1}
              max={50}
              marks={radiusMark}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              className={classes.input}
              value={value}
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 1,
                max: 50,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Radius;
