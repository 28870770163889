//SERVER ROUTES
export const USER_SERVER = '/api/users';
export const CODE_SERVER = '/api/code';
export const PROJECT_SERVER = '/api/project';
export const CAI_SERVER = '/api/cai';
export const SarcopeniaData_SERVER = '/api/sarcopeniaData';
export const LABEL_SERVER = '/api/label';
export const REPORT_SERVER = '/api/report';
export const STUDY_LIST = '/api/studylist';
export const SETTING = '/api/setting';
export const LUMBAR = '/api/lumbar';
export const LAB = '/api/lab';
export const DASHBOARD = '/api/dashboard';
