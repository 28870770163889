import _ from 'lodash';

// object 비교 후 차이 key array return
export function getObjectDiff(obj1, obj2) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}

// empty object check
export function isEmptyObject(param) {
  return Object.keys(param).length === 0 && param.constructor === Object;
}

// dataURL to file
export const dataURLtoFile = (dataurl, fileName) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = window.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

// string text to html
export function replaceBrTag(str) {
  if (str === undefined || str == null) {
    return '';
  }

  str = str.replace(/\r\n/gi, '<br>');
  str = str.replace(/\\n/gi, '<br>');
  str = str.replace(/\n/gi, '<br>');
  return str;
}

export function ab2str(buf) {
  var arrayBuffer = new Uint8Array(buf);
  var s = String.fromCharCode.apply(null, arrayBuffer);

  return decodeURIComponent(s);
}
