// ducks 패턴: 액션타입, 액션크리에이터, 리듀서가 모두 동일한 화일에 존재

// action type: 스트링, 모듈명/액션타입으로 표시
export const CLEAR_LABEL = 'leftside/CLEAR_LABEL';
export const ADD_LABEL = 'leftside/ADD_LABEL';
export const ADD_LABELIDX = 'leftside/ADD_LABELIDX';
export const ADD_LABELNAME = 'leftside/ADD_LABELNAME';
export const ADD_LABELDETAIL = 'leftside/ADD_LABELDETAIL';
export const ADD_LABELVISIBLE = 'leftside/ADD_LABELVISIBLE';
export const UPDATE_LABELNAME = 'leftside/UPDATE_LABELNAME';
export const UPDATE_LABELDETAIL = 'leftside/UPDATE_LABELDETAIL';
export const DEL_LABELIDX = 'leftside/DEL_LABELIDX';
export const DEL_LABELNAME = 'leftside/DEL_LABELNAME';
export const DEL_LABELDETAIL = 'leftside/DEL_LABELDETAIL';
export const SELECT_CURRLABELIDX = 'leftside/SELECT_CURRLABELIDX';
export const INCREASE_CURRLABELIDX = 'leftside/INCREASE_CURRLABELIDX';
export const DECREASE_CURRLABELIDX = 'leftside/DECREASE_CURRLABELIDX';
export const TOGGLE_LABELVISIBLE = 'leftside/TOGGLE_LABELVISIBLE';
export const TOGGLE_LABELVISIBLEALL = 'leftside/TOGGLE_LABELVISIBLEALL';
export const RESET_LABEL = 'leftside/RESETLABEL';
export const UPDATE_SUBTRACTLABEL = 'leftside/UPDATE_SUBTRACTLABEL';
// SLIC
export const ADD_SLICPOS = 'leftside/ADD_SLICPOS';
export const UPDATE_SLICPOS = 'leftside/UPDATE_SLICPOS';
export const DEL_SLICPOS = 'leftside/DEL_SLICPOS';
export const UPDATE_SLICDELPOS = 'leftside/UPDATE_SLICDELPOS';

// lbLoad update
export const TOGGLE_LBLOAD = 'labelList/TOGGLE_LBLOAD';

// action creator: 액션을 동적으로 생성하는 펑션
export const clearLabel = (dicomindex) => ({
  type: CLEAR_LABEL,
  payload: dicomindex,
});

export const addLabel = (dicomCnt) => ({
  type: ADD_LABEL,
  payload: dicomCnt,
});

//add LabelIdx
export const addLabelIndex = (labelidx, dicomindex) => ({
  type: ADD_LABELIDX,
  payload: { labelidx, dicomindex },
});

// label name, detail ADD
export const addLabelName = (labelname, dicomindex) => ({
  type: ADD_LABELNAME,
  payload: { labelname, dicomindex },
});

export const addLabelDetail = (labeldetail, dicomindex) => ({
  type: ADD_LABELDETAIL,
  payload: { labeldetail, dicomindex },
});
export const addLabelVisible = (dicomindex) => ({
  type: ADD_LABELVISIBLE,
  payload: dicomindex,
});
// label name, detail update
export const updateLabelName = (labelname, dicomindex) => ({
  type: UPDATE_LABELNAME,
  payload: { labelname, dicomindex },
});

export const updateLabelDetail = (labeldetail, dicomindex) => ({
  type: UPDATE_LABELDETAIL,
  payload: { labeldetail, dicomindex },
});

// label idx, name, detail DEL
export const delLabelIndex = (labelidx, dicomindex) => ({
  type: DEL_LABELIDX,
  payload: { labelidx, dicomindex },
});

export const delLabelName = (labelidx, dicomindex) => ({
  type: DEL_LABELNAME,
  payload: { labelidx, dicomindex },
});

export const delLabelDetail = (labelidx, dicomindex) => ({
  type: DEL_LABELDETAIL,
  payload: { labelidx, dicomindex },
});

//select currentLabelIdx
export const selectCurrLabelIndex = (currentlabelindex, dicomindex) => ({
  type: SELECT_CURRLABELIDX,
  payload: { currentlabelindex, dicomindex },
});
//currentLabelIdx ++
export const increaseCurrLabelIndex = (dicomindex) => ({
  type: INCREASE_CURRLABELIDX,
  payload: dicomindex,
});
//currentLabelIndex --
export const decreaseCurrLabelIndex = (dicomindex) => ({
  type: DECREASE_CURRLABELIDX,
  payload: dicomindex,
});
//toggle label visible
export const toggleLabelVisible = (dicomindex) => ({
  type: TOGGLE_LABELVISIBLE,
  payload: dicomindex,
});
//toggle label visible all
export const toggleLabelVisibleAll = (dicomindex) => ({
  type: TOGGLE_LABELVISIBLEALL,
  payload: dicomindex,
});
//reset label
export const resetLabel = () => ({
  type: RESET_LABEL,
  payload: '',
});

export const updatesubtractlabel = (dicomindex, subtractlabel) => ({
  type: UPDATE_SUBTRACTLABEL,
  payload: { dicomindex, subtractlabel },
});

//SLIC
// add slic pos
export const addSlicPos = (dicomindex) => ({
  type: ADD_SLICPOS,
  payload: { dicomindex },
});
// update slic pos
export const updateSlicPos = (movePt, dicomindex) => ({
  type: UPDATE_SLICPOS,
  payload: { movePt, dicomindex },
});
// del slic pos
export const delSlicPos = (labelidx, dicomindex) => ({
  type: DEL_SLICPOS,
  payload: { labelidx, dicomindex },
});

//update slicdelpos
export const updateSlicDelPos = (movePt, dicomindex) => ({
  type: UPDATE_SLICDELPOS,
  payload: { movePt, dicomindex },
});

// toggle lbLoad state
export const toggleLbLoad = (dicomindex) => ({
  type: TOGGLE_LBLOAD,
  payload: { dicomindex },
});

const initialState = [
  {
    lbLoad: false,
    currentlabelindex: -1,
    subtractlabel: [],
    labelindex: [],
    labelname: [],
    visible: [],
    slic: [],
  },
];
export const labelReducer = (state = initialState, action) => {
  let dicomIdx = 0;
  switch (action.type) {
    case CLEAR_LABEL:
      state[action.payload] = {
        lbLoad: false,
        currentlabelindex: -1,
        subtractlabel: [],
        labelindex: [],
        labelname: [],
        visible: [],
        slic: [],
      };
      return { ...state };
    case ADD_LABEL:
      if (action.payload !== null) {
        for (let i = 1; i < action.payload; i++) {
          state.push({
            lbLoad: false,
            currentlabelindex: -1,
            subtractlabel: [],
            labelindex: [],
            labelname: [],
            visible: [],
            slic: [],
          });
        }
        return state;
      } else {
        state = [
          {
            lbLoad: false,
            currentlabelindex: -1,
            subtractlabel: [],
            labelindex: [],
            labelname: [],
            visible: [],
            slic: [],
          },
        ];
        return state;
      }
    case ADD_LABELIDX:
      dicomIdx = action.payload.dicomindex;
      state[dicomIdx].labelindex.push(action.payload.labelidx);
      state[dicomIdx].visible.push(true);
      return { ...state };
    case ADD_LABELNAME:
      dicomIdx = action.payload.dicomindex;
      state[dicomIdx].labelname.push(action.payload.labelname);
      return { ...state };
    case ADD_LABELDETAIL:
      dicomIdx = action.payload.dicomindex;
      state[dicomIdx].labeldetail.push(action.payload.labeldetail);
      return { ...state };
    case ADD_LABELVISIBLE:
      dicomIdx = action.payload;
      state[dicomIdx].visible.push(true);
      return { ...state };
    case UPDATE_LABELNAME:
      dicomIdx = action.payload.dicomindex;
      state[dicomIdx].labelname[state[dicomIdx].currentlabelindex] =
        action.payload.labelname;
      return { ...state };
    case UPDATE_LABELDETAIL:
      dicomIdx = action.payload.dicomindex;
      // state[dicomIdx].labeldetail[state[dicomIdx].currentlabelindex] =
      //   action.payload.labeldetail;
      return { ...state };
    case DEL_LABELIDX:
      dicomIdx = action.payload.dicomindex;
      if (state[dicomIdx].labelindex.length !== 0) {
        state[dicomIdx].labelindex.splice(action.payload.labelidx, 1);
        state[dicomIdx].visible.splice(action.payload.labelidx, 1);
      }
      return { ...state };
    case DEL_LABELNAME:
      dicomIdx = action.payload.dicomindex;
      if (state[dicomIdx].labelname.length !== 0)
        state[dicomIdx].labelname.splice(action.payload.labelidx, 1);
      return { ...state };
    case DEL_LABELDETAIL:
      dicomIdx = action.payload.dicomindex;
      // if (state[dicomIdx].labeldetail.length !== 0)
      //   state[dicomIdx].labeldetail.splice(action.payload.labelidx, 1);
      return { ...state };
    case SELECT_CURRLABELIDX:
      dicomIdx = action.payload.dicomindex;
      state[dicomIdx].currentlabelindex = action.payload.currentlabelindex;
      return { ...state };
    case INCREASE_CURRLABELIDX:
      dicomIdx = action.payload;
      state[dicomIdx].currentlabelindex = state[dicomIdx].currentlabelindex + 1;
      return { ...state };
    case DECREASE_CURRLABELIDX:
      dicomIdx = action.payload;
      state[dicomIdx].currentlabelindex = state[dicomIdx].currentlabelindex - 1;
      return { ...state };
    case TOGGLE_LABELVISIBLE:
      dicomIdx = action.payload;
      let currentlabelindex = state[dicomIdx].currentlabelindex;
      let visible = state[dicomIdx].visible[currentlabelindex];
      if (visible === true) {
        state[dicomIdx].visible[currentlabelindex] = false;
      } else {
        state[dicomIdx].visible[currentlabelindex] = true;
      }
      return { ...state };
    case TOGGLE_LABELVISIBLEALL:
      dicomIdx = action.payload;
      for (let i = 0; i < state[dicomIdx].labelindex.length; i++) {
        let visible = state[dicomIdx].visible[i];
        if (visible === true) {
          state[dicomIdx].visible[i] = false;
        } else {
          state[dicomIdx].visible[i] = true;
        }
      }
      return { ...state };

    case RESET_LABEL:
      state = [
        {
          lbLoad: false,
          currentlabelindex: -1,
          subtractlabel: [],
          labelindex: [],
          labelname: [],
          visible: [],
          slic: [],
        },
      ];
      return state;
    case UPDATE_SUBTRACTLABEL:
      dicomIdx = action.payload.dicomindex;
      state[dicomIdx].subtractlabel = action.payload.subtractlabel;
      return { ...state };
    //SLIC
    case ADD_SLICPOS:
      dicomIdx = action.payload.dicomindex;
      state[dicomIdx].slic.push({ slicpos: [], slicdelpos: [] });
      return state;
    case UPDATE_SLICPOS:
      dicomIdx = action.payload.dicomindex;
      let sliccurrentlabelindex = state[dicomIdx].currentlabelindex;
      state[dicomIdx].slic[sliccurrentlabelindex].slicpos =
        action.payload.movePt;
      return state;
    case DEL_SLICPOS:
      if (state[dicomIdx].labelindex.length !== 0) {
        state[dicomIdx].slic.splice(action.payload.labelidx, 1);
      }
      return state;
    case UPDATE_SLICDELPOS:
      dicomIdx = action.payload.dicomindex;
      let sliccurrlabelindex = state[dicomIdx].currentlabelindex;
      state[dicomIdx].slic[sliccurrlabelindex].slicdelpos =
        action.payload.movePt;
      return state;
    case TOGGLE_LBLOAD:
      dicomIdx = action.payload.dicomindex;
      state[dicomIdx].lbLoad = !state[dicomIdx].lbLoad;
      return { ...state };

    default:
      return state;
  }
};
