import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { setToggleButton } from '../../../../_reducers/buttonReducer';
import BackProBtn from './BackProBtn';
import GrabcutBtn from './GrabcutBtn';
import SlicBtn from './SlicBtn';
// import CineBtn from './CineBtn';
import VFSSBtn from './VFSSBtn';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip } from '@mui/material';
import ContrastIcon from '@mui/icons-material/Contrast'; //windowing
import TocIcon from '@mui/icons-material/Toc'; // stack
import PanToolIcon from '@mui/icons-material/PanTool'; // pan
import SearchIcon from '@mui/icons-material/Search'; //zoom
import BrushIcon from '@mui/icons-material/Brush'; //draw
const { setters } = cornerstoneTools.getModule('segmentation');

const StyledButton = withStyles({
  root: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: '#58a5f0',
    borderRadius: 3,
    border: 0,
    color: 'black',
    '&:hover': {
      backgroundColor: '#004c8c',
      color: 'white',
    },
    height: '28px',
    minWidth: '28px',
    padding: '0px',
    margin: '8px 4px',
    boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

//icon style
const useStyles = makeStyles({
  root: {},
  icon: {
    width: '28px',
    height: '28px',
  },
  panicon: {
    width: '26px',
    height: '26px',
  },
  eraseicon: {
    width: '26px',
    height: '26px',
  },
});

//dicom 관련 config
export const ToggleBtns = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const btnState = useSelector(
    (state) => state.button,
    () => {},
  );
  const brushsize = useSelector((state) => state.seg.brushSize);
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const label = useSelector((state) => state.label);
  const [drawbtndisable, setDrawBtnDisable] = useState(true);
  const location = useLocation();
  let element;

  ////////////////////
  // button function
  ////////////////////
  //level
  function Windowing() {
    element = document.querySelector('.viewport-element');
    if (btnState[0]) {
      //Windowing disable
      dispatch(setToggleButton('windowing', false));
      cornerstoneTools.setToolDisabledForElement(element, 'Wwwc');
    } else {
      //Windowing Active
      dispatch(setToggleButton('windowing', true));
      cornerstoneTools.setToolActiveForElement(element, 'Wwwc', {
        mouseButtonMask: 1,
      });
    }
  }

  //Pan
  function Pan() {
    element = document.querySelector('.viewport-element');
    if (btnState[1]) {
      //Pan disable
      dispatch(setToggleButton('pan', false));
      cornerstoneTools.setToolDisabledForElement(element, 'Pan');
    } else {
      //Pan Active
      dispatch(setToggleButton('pan', true));
      cornerstoneTools.setToolActiveForElement(element, 'Pan', {
        mouseButtonMask: 1,
      });
    }
  }

  //Zoom
  function Zoom() {
    element = document.querySelector('.viewport-element');
    if (btnState[2]) {
      //zoom disable
      dispatch(setToggleButton('zoom', false));
      cornerstoneTools.setToolDisabledForElement(element, 'Zoom');
    } else {
      //zoom Active
      dispatch(setToggleButton('zoom', true));
      cornerstoneTools.setToolActiveForElement(element, 'Zoom', {
        mouseButtonMask: 1,
      });
    }
  }

  //Stack Scroll
  function StackScroll() {
    element = document.querySelector('.viewport-element');
    if (btnState[3]) {
      //Stack Scroll disable
      dispatch(setToggleButton('stackscroll', false));
      cornerstoneTools.setToolDisabledForElement(element, 'StackScroll');
    } else {
      //Stack Scroll Active
      dispatch(setToggleButton('stackscroll', true));
      cornerstoneTools.setToolActiveForElement(element, 'StackScroll', {
        mouseButtonMask: 1,
      });
    }
  }

  //Draw
  function Draw() {
    element = document.querySelector('.viewport-element');

    if (btnState[6]) {
      //Brush disable
      dispatch(setToggleButton('draw', false));
      cornerstoneTools.setToolDisabledForElement(element, 'Brush');
      // zoom,pan 기능 비활성화
      cornerstoneTools.setToolPassiveForElement(element, 'ZoomMouseWheel');
      cornerstoneTools.setToolPassiveForElement(element, 'Pan');
      cornerstoneTools.setToolPassiveForElement(element, 'Wwwc');
    } else {
      cornerstoneTools.getModule(
        'segmentation',
      ).configuration.renderFill = true;

      //Brush Active
      dispatch(setToggleButton('draw', true));
      cornerstoneTools.setToolActiveForElement(element, 'Brush', {
        mouseButtonMask: 1,
      });

      cornerstoneTools.store.state.tools[
        cornerstoneTools.store.state.tools.length - 1
      ].configuration.alwaysEraseOnClick = false;
      //mouse wheel 동작 시 zoom in / out
      cornerstoneTools.setToolActiveForElement(element, 'ZoomMouseWheel', {
        mouseButtonMask: 5,
      });
      //mouse wheel button drag 시 image move
      cornerstoneTools.setToolActiveForElement(element, 'Pan', {
        mouseButtonMask: 4,
      });
      //windowing active
      cornerstoneTools.setToolActiveForElement(element, 'Wwwc', {
        mouseButtonMask: 2,
      });
      setters.radius(brushsize);
    }
    cornerstone.updateImage(element);
  }
  //Erase
  function Erase() {
    element = document.querySelector('.viewport-element');

    if (btnState[7]) {
      //Erase disable
      dispatch(setToggleButton('erase', false));

      cornerstoneTools.setToolDisabledForElement(element, 'Brush');
      cornerstoneTools.store.state.tools[
        cornerstoneTools.store.state.tools.length - 1
      ].configuration.alwaysEraseOnClick = false;
    } else {
      //Erase Active
      dispatch(setToggleButton('erase', true));

      cornerstoneTools.setToolActiveForElement(element, 'Brush', {
        mouseButtonMask: 1,
      });
      cornerstoneTools.store.state.tools[
        cornerstoneTools.store.state.tools.length - 1
      ].configuration.alwaysEraseOnClick = true;
      setters.radius(brushsize);
    }
  }

  function ToolActiveCheck() {
    if (btnState[0]) {
      //windowing : wwwc 제외한기능 disabled
      cornerstoneTools.setToolDisabledForElement(element, 'Pan');
      cornerstoneTools.setToolDisabledForElement(element, 'Zoom');
      cornerstoneTools.setToolDisabledForElement(element, 'StackScroll');
      cornerstoneTools.setToolDisabledForElement(element, 'ZoomMouseWheel');
    } else if (btnState[1]) {
      // pan : pan 제외한 기능 disabled
      cornerstoneTools.setToolDisabledForElement(element, 'Wwwc');
      cornerstoneTools.setToolDisabledForElement(element, 'Zoom');
      cornerstoneTools.setToolDisabledForElement(element, 'StackScroll');
      cornerstoneTools.setToolDisabledForElement(element, 'ZoomMouseWheel');
    } else if (btnState[2]) {
      // zoom : zoom 제외한 기능 disabled
      cornerstoneTools.setToolDisabledForElement(element, 'Wwwc');
      cornerstoneTools.setToolDisabledForElement(element, 'Pan');
      cornerstoneTools.setToolDisabledForElement(element, 'StackScroll');
      cornerstoneTools.setToolDisabledForElement(element, 'ZoomMouseWheel');
    } else if (btnState[3]) {
      //stackscroll : stackscroll 제외한 기능 disabled
      cornerstoneTools.setToolDisabledForElement(element, 'Wwwc');
      cornerstoneTools.setToolDisabledForElement(element, 'Pan');
      cornerstoneTools.setToolDisabledForElement(element, 'Zoom');
      cornerstoneTools.setToolDisabledForElement(element, 'ZoomMouseWheel');
    } else if (btnState[4]) {
      //backPro : backPro 활성화를 제외한 기능 disabled
      //cornerstoneTools.setToolDisabled('Wwwc');
      cornerstoneTools.setToolDisabledForElement(element, 'Zoom');
      cornerstoneTools.setToolDisabledForElement(element, 'StackScroll');
      cornerstoneTools.setToolDisabledForElement(element, 'CorrectionScissors');
      cornerstoneTools.store.state.tools[
        cornerstoneTools.store.state.tools.length - 1
      ].configuration.alwaysEraseOnClick = false;
    } else if (btnState[5]) {
      // grabcut
    } else if (btnState[6]) {
      //draw : brush 제외한 기능 disabled
      cornerstoneTools.setToolDisabledForElement(element, 'StackScroll');
      cornerstoneTools.setToolDisabledForElement(element, 'RectangleScissors');
      cornerstoneTools.setToolDisabledForElement(element, 'CorrectionScissors');
      cornerstoneTools.store.state.tools[
        cornerstoneTools.store.state.tools.length - 1
      ].configuration.alwaysEraseOnClick = false;
    } else if (btnState[7]) {
      //erase : erase 활성화를 제외한 기능 disabled
      cornerstoneTools.setToolDisabledForElement(element, 'Wwwc');
      cornerstoneTools.setToolDisabledForElement(element, 'Pan');
      cornerstoneTools.setToolDisabledForElement(element, 'Zoom');
      cornerstoneTools.setToolDisabledForElement(element, 'StackScroll');
      cornerstoneTools.setToolDisabledForElement(element, 'ZoomMouseWheel');
      cornerstoneTools.setToolDisabledForElement(element, 'RectangleScissors');
      cornerstoneTools.setToolDisabledForElement(element, 'CorrectionScissors');
    }
  }

  //다른 버튼 클릭시 버튼 활성화 Check 후 setting
  // key event 처리
  useEffect(() => {
    ToolActiveCheck();

    const KeyDown = (e) => {
      if (e.target.constructor !== HTMLInputElement) {
        if (label[currentDicomIdx].labelindex.length > 0) {
          // 키보드 'w'버튼
          if (e.keyCode === 87) {
            Draw();
          }
          // 키보드 'e'버튼
          else if (e.keyCode === 69) {
            Erase();
          }
        }
      }
    };
    window.addEventListener('keydown', KeyDown);
    return () => window.removeEventListener('keydown', KeyDown);
    // eslint-disable-next-line
  }, [btnState]);

  //LabelList 있을때에만 활성화
  useEffect(() => {
    if (label[currentDicomIdx])
      if (label[currentDicomIdx].labelindex.length > 0) {
        setDrawBtnDisable(false);
      } else {
        setDrawBtnDisable(true);
        let element = document.querySelector('.viewport-element');
        cornerstoneTools.setToolDisabledForElement(element, 'Brush');
        dispatch(setToggleButton('draw', false));
      }
  }, [currentDicomIdx, dispatch, label]);

  return (
    <div>
      <Grid
        container
        direction="row"
        display="flex"
        style={{
          backgroundColor: ' rgba(255, 255, 255, 0)',
        }}
      >
        <Tooltip title="Windowing" arrow>
          <span>
            <StyledButton
              onClick={Windowing}
              style={{
                backgroundColor: btnState[0] === true ? '#004c8c' : '#58a5f0',
                color: btnState[0] === true ? 'white' : 'black',
              }}
            >
              <ContrastIcon className={classes.icon} />
            </StyledButton>
          </span>
        </Tooltip>
        <Tooltip title="Pan" arrow>
          <span>
            <StyledButton
              onClick={Pan}
              style={{
                backgroundColor: btnState[1] === true ? '#004c8c' : '#58a5f0',
                color: btnState[1] === true ? 'white' : 'black',
              }}
            >
              <PanToolIcon className={classes.panicon} />
            </StyledButton>
          </span>
        </Tooltip>
        <Tooltip title="Zoom" arrow>
          <span>
            <StyledButton
              onClick={Zoom}
              style={{
                backgroundColor: btnState[2] === true ? '#004c8c' : '#58a5f0',
                color: btnState[2] === true ? 'white' : 'black',
              }}
            >
              <SearchIcon className={classes.icon} />
            </StyledButton>
          </span>
        </Tooltip>
        <Tooltip title="StackScroll" arrow>
          <span>
            <StyledButton
              onClick={StackScroll}
              style={{
                backgroundColor: btnState[3] === true ? '#004c8c' : '#58a5f0',
                color: btnState[3] === true ? 'white' : 'black',
              }}
            >
              <TocIcon className={classes.icon} />
            </StyledButton>
          </span>
        </Tooltip>
        <Tooltip title="SLIC" arrow>
          <span>
            <SlicBtn btndisable={drawbtndisable} />
          </span>
        </Tooltip>
        <Tooltip title="BackProjection" arrow>
          <span>
            <BackProBtn btndisable={drawbtndisable} />
          </span>
        </Tooltip>
        <Tooltip title="Grabcut" arrow>
          <span>
            <GrabcutBtn btndisable={drawbtndisable} />
          </span>
        </Tooltip>
        <Tooltip title="Brush" arrow>
          <span>
            <StyledButton
              onClick={Draw}
              disabled={drawbtndisable}
              style={{
                backgroundColor:
                  drawbtndisable === false
                    ? btnState[6] === true
                      ? '#004c8c'
                      : '#58a5f0'
                    : '',
                color:
                  drawbtndisable === false
                    ? btnState[6] === true
                      ? 'white'
                      : 'black'
                    : '',
              }}
            >
              <BrushIcon className={classes.icon} />
            </StyledButton>
          </span>
        </Tooltip>
        <Tooltip title="Erase" arrow>
          <span>
            <StyledButton
              onClick={Erase}
              disabled={drawbtndisable}
              style={{
                backgroundColor:
                  drawbtndisable === false
                    ? btnState[7] === true
                      ? '#004c8c'
                      : '#58a5f0'
                    : '',
                color:
                  drawbtndisable === false
                    ? btnState[7] === true
                      ? 'white'
                      : 'black'
                    : '',
              }}
            >
              {drawbtndisable === false ? (
                btnState[7] === true ? (
                  <img
                    className={classes.eraseicon}
                    src={process.env.PUBLIC_URL + '/imgs/icons/eraser.png'}
                    alt="eraser"
                    style={{
                      filter:
                        'invert(100%) sepia(100%) saturate(0%) hue-rotate(252deg) brightness(102%) contrast(102%)',
                    }}
                  />
                ) : (
                  <img
                    className={classes.eraseicon}
                    src={process.env.PUBLIC_URL + '/imgs/icons/eraser.png'}
                    alt="eraser"
                  />
                )
              ) : (
                <img
                  className={classes.eraseicon}
                  src={process.env.PUBLIC_URL + '/imgs/icons/eraser.png'}
                  alt="eraser"
                  style={{
                    filter: `opacity(23%)`,
                  }}
                />
              )}
            </StyledButton>
          </span>
        </Tooltip>
        {/* {location.pathname === '/labeling/main/VFSS' && (
          <Tooltip title="CineView" arrow>
            <span>
              <CineBtn btndisable={drawbtndisable} />
            </span>
          </Tooltip>
        )} */}
        {location.pathname === '/labeling/main/VFSS' && (
          <Tooltip title="VFSS Predict" arrow>
            <span>
              <VFSSBtn />
            </span>
          </Tooltip>
        )}
      </Grid>
    </div>
  );
};
export default ToggleBtns;
