import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import {
  addLabelIndex,
  addLabelName,
  selectCurrLabelIndex,
} from '../../../../_reducers/labelReducer';
import { updateLabelampMaxIdx } from '../../../../_reducers/segmentReducer';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { yellow } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const StyledButton = withStyles({
  root: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: '#58a5f0',
    borderRadius: 3,
    border: 0,
    color: 'black',
    '&:hover': {
      backgroundColor: '#004c8c',
      color: 'white',
    },
    height: '28px',
    minWidth: '28px',
    padding: '0px',
    margin: '8px 4px',
    boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

//icon style
const useStyles = makeStyles({
  root: {},
  buttonProgress: {
    display: 'flex',
    alignItems: 'center',
    color: yellow[700],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -18,
  },
  icon: {
    width: '28px',
    height: '28px',
    // padding: '2px',
    '&:hover': {
      filter:
        'invert(100%) sepia(100%) saturate(0%) hue-rotate(252deg) brightness(102%) contrast(102%)',
    },
  },
});

const StoneBtn = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const dicomCnt = useSelector((state) => state.dicom.imgIds.length);
  const label = useSelector((state) => state.label);
  const labelmapMax = useSelector((state) => state.seg.labelmapMaxIndex);
  const labelIndexs = useSelector(
    (state) => state.label[currentDicomIdx].labelindex,
  );
  const currentDicomName = useSelector((state) => state.dicom.fileNames);
  const { setters, getters } = cornerstoneTools.getModule('segmentation');
  const reduxDicom = useSelector((state) => state.dicom);
  //material ui snack bar
  const { enqueueSnackbar } = useSnackbar();

  const [predictBtnDisable, SetPredictBtnDisable] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  //label add btn event
  function Predict() {
    let element = document.querySelector('.viewport-element');
    let voi = cornerstone.getViewport(element).voi;
    let formData = new FormData();
    formData.append('voi', JSON.stringify(voi));
    formData.append('objId', searchParams.get('pjId'));
    formData.append('dicomName', currentDicomName);
    let sopUids = [];
    for (let i = 0; i < reduxDicom.imgIds.length; i++) {
      sopUids.push(reduxDicom.imgIds[i].split('&')[3].split('=')[1]);
    }
    formData.append('sopUids', sopUids);

    //urinary stone progress circle
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 1000 * currentDicomName.length);
    }

    axios
      .post(`${process.env.REACT_APP_FLASK_URL}/Stone`, formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => {
        //이미지 flask 서버에 전송 후 result 가져옴
        //console.log('finish');
        //console.log(response);
        //console.log(response.data);
        element = document.querySelector('.viewport-element');
        let result_cnt = response.data.result.length;
        const { labelmap3D } = getters.labelmap2D(element);

        labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap = [0, 1, 2];
        let temp = labelmap3D.labelmaps2D[0];

        //stone label 추가를 위하여 stone predict 개수만큼 빈 label 추가
        for (let k = 0; k < result_cnt; k++) {
          setters.activeLabelmapIndex(element, k + 1);
        }

        // labelmap3D.labelmaps2D = [temp];
        // labelmap3D.labelmaps2D[0].segmentsOnLabelmap = [0, 1, 2];
        for (let j = 0; j < result_cnt; j++) {
          // console.log(j);
          if (response.data.result_chk[j]) {
            // label add
            dispatch(addLabelIndex(j + labelmapMax + 1, j));
            dispatch(addLabelName('Stone result', j));
            dispatch(selectCurrLabelIndex(labelIndexs.length, j));
            // response로 받아온 stone 이미지 배열로 변환
            let binary_string = window.atob(response.data.result[j]);
            let len = binary_string.length;
            let bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binary_string.charCodeAt(i);
            }
            let seg = new Uint16Array(bytes.buffer);
            // console.log(
            //   getters.labelmaps3D(element).labelmaps3D[labelmapMax + 1],
            // );
            // cornerstone labelmap에 위에서 처리한 이미지 배열
            getters.labelmaps3D(element).labelmaps3D[
              j + labelmapMax + 1
            ].labelmaps2D[j] = temp;
            getters.labelmaps3D(element).labelmaps3D[
              j + labelmapMax + 1
            ].labelmaps2D[j] = {
              stoneindex: j,
            };
            getters.labelmaps3D(element).labelmaps3D[
              j + labelmapMax + 1
            ].labelmaps2D[j].pixelData = seg;
            getters.labelmaps3D(element).labelmaps3D[
              j + labelmapMax + 1
            ].labelmaps2D[j].segmentsOnLabelmap = [0, 1, 2];

            cornerstone.updateImage(element);
            dispatch(updateLabelampMaxIdx(j + labelmapMax + 1));

            //predict 하여 추가한 label 선택
            dispatch(selectCurrLabelIndex(label[j].labelindex.length - 1, j));
          }
        }
        handleClickVariant('StonePredict Complete', 'success');
        //console.log('predict complete');
      })
      //error
      .catch((error) => {
        //console.log("error");
        console.log(error);
        //console.log(error.response);
      });
  }

  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };

  useEffect(() => {
    if (dicomCnt > 0) {
      SetPredictBtnDisable(false);
    } else {
      SetPredictBtnDisable(true);
    }
  }, [dicomCnt]);

  return (
    <>
      <StyledButton disabled={predictBtnDisable || loading} onClick={Predict}>
        <img
          className={classes.icon}
          src={process.env.PUBLIC_URL + '/imgs/icons/urinary.png'}
          alt="urinary"
        />
        {loading && (
          <CircularProgress
            className={classes.buttonProgress}
            size={36}
            thickness={6}
          />
        )}
      </StyledButton>
    </>
  );
};

export default StoneBtn;
