import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Grid,
  MenuItem,
  Menu,
  Dialog,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import PreviewIcon from '@mui/icons-material/Preview';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { useSelector, useDispatch } from 'react-redux';
import StoneBtn from './StoneBtn';
import SarcopeniaBtn from './SarcopeniaBtn';
import LiverCirrhosisBtn from './LiverCirrhosisBtn';
import FibrosisBtn from './FibrosisBtn';
import KidneyBtn from './KidneyBtn';
import { updatesubtractlabel } from '../../../../_reducers/labelReducer';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { useSnackbar } from 'notistack';

const { getDiffBetweenPixelData } = cornerstoneTools.importInternal(
  'util/segmentationUtils',
);

const StyledButton = withStyles({
  root: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: '#58a5f0',
    borderRadius: 3,
    border: 0,
    color: 'black',
    '&:hover': {
      backgroundColor: '#004c8c',
      color: 'white',
    },
    height: '28px',
    minWidth: '28px',
    padding: '0px',
    margin: '8px 4px',
    boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

//icon style
const useStyles = makeStyles({
  root: {},
  icon: {
    width: '28px',
    height: '28px',
  },
});

const DialogStyles = (theme) => ({
  root: {
    background: '#90a4ae',
    margin: 0,
    padding: '16px',
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: 'black',
  },
});

const DialogTitle = withStyles(DialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: '16px',
    background: '#cfd8dc',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '8px',
    background: '#cfd8dc',
  },
}))(MuiDialogActions);

//dicom 관련 config
const { getters, setters, state } = cornerstoneTools.getModule('segmentation');

const OneClickBtn = () => {
  const dispatch = useDispatch();
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const currentDicomRow = useSelector((state) => state.dicom.imageRow);
  const currentDicomCol = useSelector((state) => state.dicom.imageCol);
  const label = useSelector((state) => state.label);
  const cinePlay = useSelector((state) => state.seg.cinePlay);

  let element;
  const classes = useStyles();
  //sub menu button
  const [saveanchorEl, setsaveAnchorEl] = useState(null);

  //button disable/enable state
  const [resetDisable, setResetDisable] = useState(true);
  const [unreDisable, setUnreDisable] = useState(true);
  const [maskreviewDisable, setMaskreviewDisable] = useState(true);
  //dialog state
  const [previewOpen, setPreviewOpen] = useState(false);

  //project type에 따른 button visible state
  const [visibleBtn, setVisibleBtn] = useState(true);

  const location = useLocation();

  //material ui snack bar
  const { enqueueSnackbar } = useSnackbar();

  //dialog open,close
  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handleClickSave = (event) => {
    setsaveAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setsaveAnchorEl(null);
  };

  ////////////////////
  // button function
  ////////////////////
  //Label Save
  function LabelSaveCurr() {
    element = document.querySelector('.viewport-element');
    //선택된 labellist 있을 때에만 동작
    if (label[currentDicomIdx].currentlabelindex > -1) {
      //current slic image convert to png
      const canvas = document.createElement('canvas');
      let width =
        currentDicomCol === 0 || currentDicomCol === undefined
          ? 512
          : currentDicomCol;
      let height =
        currentDicomRow === 0 || currentDicomRow === undefined
          ? 512
          : currentDicomRow;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      const imgData = ctx.createImageData(width, height);
      const len = imgData.data.length; // width * height * 4;
      const {
        labelmap2D, // The `Labelmap2D` for this imageId.
      } = getters.labelmap2D(element);
      let saveLabelmap = labelmap2D.pixelData;
      const colorLut = state.colorLutTables[0];

      for (let i = 0, k = 0; i < len; i += 4, k++) {
        //data[i] ->r
        //data[i+1] ->g
        //data[i+2] ->b
        //data[i+3] ->a
        let color = saveLabelmap[k];
        if (color > 0) {
          imgData.data[i + 0] = colorLut[color][0];
          imgData.data[i + 1] = colorLut[color][1];
          imgData.data[i + 2] = colorLut[color][2];
        } else {
          imgData.data[i + 0] = imgData.data[i + 1] = imgData.data[i + 2] = 0;
        }

        imgData.data[i + 3] = 255;
      }
      ctx.putImageData(imgData, 0, 0);

      //Get data from canvas
      let img_b64_url = canvas.toDataURL('image/png');

      //<a> 생성후 다운로드 처리
      let a = document.createElement('a');
      a.download =
        label[currentDicomIdx][label[currentDicomIdx].currentlabelindex] +
        '.png';
      a.href = img_b64_url;
      document.body.appendChild(a);
      a.click();
      a.remove();
      canvas.remove();

      //   //sever에 download한 label save
      //   let formData = new FormData();
      //   formData.append('filename', file[currentDicomIdx].name);
      //   formData.append('labelname', labelName[currentLabelIdx]);

      //   // Split the base64 string in data and contentType
      //   let block = img_b64_url.split(';');
      //   // Get the content type of the image
      //   let contentType = block[0].split(':')[1]; // In this case "image/gif"
      //   // get the real base64 content of the file
      //   let realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

      //   // Convert it to a blob to upload
      //   let blob = b64toBlob(realData, contentType);
      //   formData.append('img', blob);
      //   axios
      //     .post(`http://localhost:5000/SaveLabel`, formData, {
      //       'Content-Type': 'multipart/form-data',
      //     })
      //     .then((response) => {
      //       //이미지 flask 서버에 전송 후 result 가져옴
      //       // console.log('finish');
      //       //console.log(response);
      //       //console.log(response.data);
      //       if (response.data.result !== 'none') {
      //       } else {
      //         alert('Result : none');
      //       }
      //     })
      //     //error
      //     .catch((error) => {
      //       console.log(error);
      //       //console.log(error.response);
      //     });
    }
    handleClose();
  }

  //base64 인코딩되어진 이미지를 -> blob 로 변환
  // function b64toBlob(b64Data, contentType, sliceSize) {
  //   if (b64Data == '' || b64Data == undefined) return null;
  //   contentType = contentType || '';
  //   sliceSize = sliceSize || 512;
  //   let byteCharacters = atob(b64Data);
  //   let byteArrays = [];

  //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     let slice = byteCharacters.slice(offset, offset + sliceSize);
  //     let byteNumbers = new Array(slice.length);
  //     for (let i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }
  //     let byteArray = new Uint8Array(byteNumbers);
  //     byteArrays.push(byteArray);
  //   }
  //   let blob = new Blob(byteArrays, { type: contentType });
  //   return blob;
  // }

  //Mask Preview
  function Preview() {
    setPreviewOpen(true);
  }
  //mask preview 안에 canvas rendering 후 drawing
  function PreviewOnload() {
    element = document.querySelector('.viewport-element');
    const previewCanvas = document.getElementById('preview-label');
    const ctx = previewCanvas.getContext('2d');
    let width =
      currentDicomCol === 0 || currentDicomCol === undefined
        ? 512
        : currentDicomCol;
    let height =
      currentDicomRow === 0 || currentDicomRow === undefined
        ? 512
        : currentDicomRow;
    const imgData = ctx.createImageData(width, height);
    const len = imgData.data.length; // width * height * 4;

    const {
      labelmap2D, // The `Labelmap2D` for this imageId.
    } = getters.labelmap2D(element);
    let saveLabelmap = labelmap2D.pixelData;

    const colorLut = state.colorLutTables[0];

    for (let i = 0, k = 0; i < len; i += 4, k++) {
      //data[i] ->r
      //data[i+1] ->g
      //data[i+2] ->b
      //data[i+3] ->a
      let color = saveLabelmap[k];
      if (color > 0) {
        imgData.data[i + 0] = colorLut[color][0];
        imgData.data[i + 1] = colorLut[color][1];
        imgData.data[i + 2] = colorLut[color][2];
      } else {
        imgData.data[i + 0] = imgData.data[i + 1] = imgData.data[i + 2] = 0;
      }

      imgData.data[i + 3] = 255;
    }
    ctx.putImageData(imgData, 0, 0);
  }

  //Reset
  function Reset() {
    element = document.querySelector('.viewport-element');

    element.style.width = '100%';
    element.style.height = '772px';
    cornerstone.resize(element, true);

    cornerstoneTools.setToolDisabledForElement(element, 'Wwwc');
    cornerstoneTools.setToolDisabledForElement(element, 'Pan');
    cornerstoneTools.setToolDisabledForElement(element, 'Zoom');
    cornerstoneTools.setToolDisabledForElement(element, 'StackScroll');
    cornerstoneTools.setToolDisabledForElement(element, 'ZoomMouseWheel');

    //setters.deleteSegment 로는 active되어진 segments 만 삭제가 가능
    // -> 여러 색상으로 labelmap이 draw 되었을 경우 모두 삭제 처리 해주어야 함.
    //labelmapArr -> 배열 i번째 인자부터 0번째 인자까지 순차탐색하여 setters.deleteSegment 실행
    // const { labelmap2D } = getters.labelmap2D(element);
    // let seg;
    // let segmentCnt = labelmap2D.segmentsOnLabelmap.length;
    // const labelmapArr = labelmap2D.segmentsOnLabelmap;
    // for (let i = 1; i < segmentCnt; i++) {
    //   seg = labelmapArr[i];
    //   setters.deleteSegment(element, seg, getters.activeLabelmapIndex(element));
    // }
  }

  //undo
  function Undo() {
    element = document.querySelector('.viewport-element');
    setters.undo(element);
  }
  //Redo
  function Redo() {
    element = document.querySelector('.viewport-element');
    setters.redo(element);
  }

  function SegSubtract() {
    let subtractLabel = label[currentDicomIdx].subtractlabel;
    //label list가 2개이상일때만 동작
    if (label[currentDicomIdx].labelindex.length >= 2) {
      //// 현재 label이 checkbox 되있을경우 subtract불가 (alert띄움) ->
      if (
        subtractLabel.indexOf(label[currentDicomIdx].currentlabelindex) !== -1
      ) {
        alert('현재 선택된 Label의 CheckBox를 해제 후 Subtract이 가능합니다.');
        return;
      }

      let element = document.querySelector('.viewport-element');
      const { getters } = cornerstoneTools.getModule('segmentation');
      const {
        labelmaps3D, // The `Labelmap2D` for this imageId.
      } = getters.labelmaps3D(element);
      //labelmap undefined 일때 예외처리
      if (label[currentDicomIdx].currentlabelindex !== -1) {
        //substract 이전 arr 저장
        let previousPixelData = [
          ...getters.labelmap2D(element).labelmap3D.labelmaps2D,
        ][currentDicomIdx].pixelData.slice();

        //현재 선택된 label
        let newPixelData = previousPixelData.slice();

        //checkbox로 선택된 label
        // subtractLabel : labelList index 값
        let subtractLen = subtractLabel.length;
        let subtractLabelArr = [];

        for (let i = 0; i < subtractLen; i++) {
          if (
            labelmaps3D[label[currentDicomIdx].labelindex[subtractLabel[i]]]
              .labelmaps2D[currentDicomIdx]
          ) {
            subtractLabelArr.push(
              labelmaps3D[label[currentDicomIdx].labelindex[subtractLabel[i]]]
                .labelmaps2D[currentDicomIdx].pixelData,
            );
          }
        }

        if (subtractLabelArr.length > 0) {
          //현재 label - checkboxLabel
          let len = 0;
          if (
            (currentDicomRow === 0 || currentDicomRow === undefined) &&
            (currentDicomCol === 0 || currentDicomCol === undefined)
          ) {
            len = 262144;
          } else {
            len = currentDicomRow * currentDicomCol;
          }

          for (let n = 0; n < len; n++) {
            // 현재 label의 Segment 영역이 존재하면
            if (newPixelData[n] > 0) {
              //checkbox가 체크된 subtractLabel 탐색하여 비교 연산
              for (let j = 0; j < subtractLabelArr.length; j++) {
                if (subtractLabelArr[j][n] > 0) {
                  newPixelData[n] = 0;
                }
              }
            }
          }

          [...getters.labelmap2D(element).labelmap3D.labelmaps2D][
            currentDicomIdx
          ].pixelData = newPixelData;

          //undo, redo를 이용하기 위하여 pixeldata 비교하여 pushState
          let operation = {
            imageIdIndex: currentDicomIdx,
            diff: getDiffBetweenPixelData(previousPixelData, newPixelData),
          };
          setters.pushState(element, [operation]);

          cornerstone.updateImage(element);
          // subtract label checkarr => clear
          dispatch(updatesubtractlabel(currentDicomIdx, []));

          handleClickVariant('Subtract Complete', 'success');
        }
      }
    }
  }

  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };

  useEffect(() => {
    //label 없는경우 undo, redo button disabled
    if (label[currentDicomIdx])
      if (label[currentDicomIdx].currentlabelindex === -1) {
        setResetDisable(true);
        setUnreDisable(true);
        setMaskreviewDisable(true);
      } else {
        setResetDisable(false);
        setUnreDisable(false);
        setMaskreviewDisable(false);
      }
  }, [currentDicomIdx, label]);

  // key event 처리
  useEffect(() => {
    const KeyDown = (e) => {
      if (e.target.constructor !== HTMLInputElement) {
        if (label[currentDicomIdx].labelindex.length > 0) {
          // 키보드 'p'버튼
          if (e.keyCode === 80) {
            Preview();
          }
          // undo shortcutskey : ctrl + z
          // redo shorcutskey : ctrl + shift + z
          else if (e.ctrlKey) {
            if (e.keyCode === 90) {
              if (e.shiftKey) {
                if (e.ctrlKey) {
                  Redo();
                }
              } else if (!e.shiftKey) {
                if (e.ctrlKey) {
                  Undo();
                }
              }
            }
          }
        }
      }
    };
    window.addEventListener('keydown', KeyDown);
    return () => window.removeEventListener('keydown', KeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDicomIdx, label]);

  // labelingtool 종류에 따른 button 비활성화 처리
  useEffect(() => {
    // console.log(location);
    if (location.pathname === '/labeling/main/VFSS') {
      setVisibleBtn(false);
    } else {
      setVisibleBtn(true);
    }
  }, [location]);

  return (
    <div>
      <Grid
        container
        direction="row"
        display="flex"
        style={{
          backgroundColor: ' rgba(255, 255, 255,0)',
        }}
      >
        <Tooltip title="Save" arrow>
          <StyledButton
            aria-controls="save-menu"
            aria-haspopup="true"
            onClick={handleClickSave}
          >
            <SaveIcon className={classes.icon} />
          </StyledButton>
        </Tooltip>
        <Menu
          id="save-menu"
          anchorEl={saveanchorEl}
          keepMounted
          open={Boolean(saveanchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={LabelSaveCurr}>Current Label</MenuItem>
        </Menu>
        <Tooltip title="Preview" arrow>
          <span>
            <StyledButton
              onClick={Preview}
              disabled={maskreviewDisable || cinePlay}
            >
              <PreviewIcon className={classes.icon} />
            </StyledButton>
          </span>
        </Tooltip>
        <Dialog
          onClose={handlePreviewClose}
          aria-labelledby="customized-dialog-title"
          open={previewOpen}
          ref={() => {
            PreviewOnload();
          }}
          maxWidth="lg"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handlePreviewClose}
          >
            Label Preview
          </DialogTitle>
          <DialogContent dividers>
            <canvas
              id="preview-label"
              width={
                currentDicomRow === 0 || currentDicomRow === undefined
                  ? 512
                  : currentDicomRow
              }
              height={
                currentDicomCol === 0 || currentDicomCol === undefined
                  ? 512
                  : currentDicomCol
              }
              style={{ backgroundColor: 'black' }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={LabelSaveCurr}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
            <Button
              onClick={handlePreviewClose}
              variant="contained"
              color="error"
              startIcon={<CancelPresentationRoundedIcon />}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Tooltip title="Reset" arrow>
          <span>
            <StyledButton onClick={Reset} disabled={resetDisable || cinePlay}>
              <RestartAltIcon className={classes.icon} />
            </StyledButton>
          </span>
        </Tooltip>
        <Tooltip title="Undo" arrow>
          <span>
            <StyledButton onClick={Undo} disabled={unreDisable || cinePlay}>
              <UndoIcon className={classes.icon} />
            </StyledButton>
          </span>
        </Tooltip>
        <Tooltip title="Redo" arrow>
          <span>
            <StyledButton onClick={Redo} disabled={unreDisable || cinePlay}>
              <RedoIcon className={classes.icon} />
            </StyledButton>
          </span>
        </Tooltip>
        {visibleBtn && (
          <Tooltip title="LiverCirrhosis Predict" arrow>
            <span>
              <LiverCirrhosisBtn />
            </span>
          </Tooltip>
        )}
        {visibleBtn && (
          <Tooltip title="Fibrosis Predict" arrow>
            <span>
              <FibrosisBtn />
            </span>
          </Tooltip>
        )}
        {visibleBtn && (
          <Tooltip title="UrinaryStone Predict" arrow>
            <span>
              <StoneBtn />
            </span>
          </Tooltip>
        )}
        {visibleBtn && (
          <Tooltip title="KidneyTumor Predict" arrow>
            <span>
              <KidneyBtn />
            </span>
          </Tooltip>
        )}
        {visibleBtn && (
          <Tooltip title="Sarcopenia Predict" arrow>
            <span>
              <SarcopeniaBtn />
            </span>
          </Tooltip>
        )}
        <Tooltip title="Segmentation Subtract" arrow>
          <span>
            <StyledButton onClick={SegSubtract}>
              <BookmarkRemoveIcon className={classes.icon} />
            </StyledButton>
          </span>
        </Tooltip>
      </Grid>
    </div>
  );
};

export default OneClickBtn;
