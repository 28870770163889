import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  Grid,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';

import {
  updateModalOpen,
  updateStep,
  updateSeriesIdx,
} from '../../../../_reducers/stepperReducer';

import clsx from 'clsx';

const StyledButton = withStyles({
  root: {
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },
})(Button);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#1CE32D',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1CE32D',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 20,
    alignItems: 'center',
  },
  active: {
    color: '#1CE32D',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#1CE32D',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles(() => ({
  root: {
    height: '78px',
    width: '760px',
  },
  button: {
    height: '75px',
    width: '60px',
  },
  stepper: {
    margin: '0px 0px 0px 0px',
    padding: '10px 0px 0px 0px',
    height: '75px',
    backgroundColor: '#6d6d6d',
  },
}));

function getSteps() {
  return ['Select series to import', 'Choose level', 'Drawing & Labeling'];
}

const Steppers = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const series = useSelector((state) => state.series);
  const activeStep = useSelector((state) => state.stepper.stepstate);
  const Ds = useSelector((state) => state.stepper.Ds);
  const De = useSelector((state) => state.stepper.De);
  const [ckboxArr, setCkboxArr] = useState([]);

  const steps = getSteps();

  const handleNext = () => {
    let checkboxArr = [];

    if (activeStep === 0) {
      for (let i = 0; i < series.length; i++) {
        let checkboxElement = document.querySelector(
          '#thumnailCheckbox' + String(i),
        );
        if (checkboxElement.checked) {
          checkboxArr.push(i);
        }
      }
      if (checkboxArr.length === 0) {
        alert('series를 선택해 주세요.');
      } else if (checkboxArr.length === 1) {
        setCkboxArr(checkboxArr);
        dispatch(updateStep(activeStep + 1));
        //axial series는 SelectDcm page로 연결
        //나머지 series는 건너뜀.
        if (
          JSON.stringify(series[checkboxArr[0]].seriesOrientMode) ===
          JSON.stringify(['1', '0', '0', '0', '1', '0'])
        ) {
          dispatch(updateSeriesIdx(checkboxArr[0]));
          navigate(
            `/labeling/selectDcm?stUid=${searchParams.get(
              'stUid',
            )}&list=${encodeURIComponent(checkboxArr)}&pjId=${searchParams.get(
              'pjId',
            )}&pjcate=${searchParams.get('pjcate')}&stty=${searchParams.get(
              'stty',
            )}&process=${searchParams.get('process')}`,
          );
        } else {
          dispatch(updateSeriesIdx(checkboxArr[0]));
          dispatch(updateStep(2));
          navigate(
            `/labeling/labelingtool?stUid=${searchParams.get(
              'stUid',
            )}&list=${encodeURIComponent(checkboxArr)}&pjId=${searchParams.get(
              'pjId',
            )}&pjcate=${searchParams.get('pjcate')}&stty=${searchParams.get(
              'stty',
            )}&process=${searchParams.get('process')}`,
          );
        }
      } else if (checkboxArr.length >= 2) {
        setCkboxArr(checkboxArr);
        dispatch(updateStep(2));
        navigate(
          `/labeling/labelingtool?stUid=${searchParams.get(
            'stUid',
          )}&list=${encodeURIComponent(checkboxArr)}&pjId=${searchParams.get(
            'pjId',
          )}&pjcate=${searchParams.get('pjcate')}&stty=${searchParams.get(
            'stty',
          )}&process=${searchParams.get('process')}`,
          {
            state: { pjId: searchParams.get('id') },
          },
        );
      }
    } else if (activeStep === 1) {
      dispatch(updateStep(2));
      navigate(
        `/labeling/labelingtool?stUid=${searchParams.get(
          'stUid',
        )}&list=${encodeURIComponent(
          ckboxArr,
        )}&Ds=${Ds}&De=${De}&pjId=${searchParams.get(
          'pjId',
        )}&pjcate=${searchParams.get('pjcate')}&stty=${searchParams.get(
          'stty',
        )}&process=${searchParams.get('process')}`,
      );
    }
  };

  const handleBack = () => {
    if (activeStep === 2) {
      dispatch(updateModalOpen(true));
      // if (ckboxArr.length === 1) {
      //   navigate('/labeling/selectDcm', {
      //     state: {
      //       pjId: location.state.pjId,
      //       Ds: location.state.Ds,
      //       De: location.state.De,
      //       list: location.state.list,
      //     },
      //   });
      //   dispatch(updateStep(activeStep - 1));
      // } else if (ckboxArr.length >= 2) {
      //   navigate('/labeling/main', {
      //     state: { pjId: location.state.pjId },
      //   });
      //   dispatch(updateStep(0));
      // }
    } else if (activeStep === 1) {
      navigate(
        `/labeling/main?stUid=${searchParams.get(
          'stUid',
        )}&pjId=${searchParams.get('pjId')}&pjcate=${searchParams.get(
          'pjcate',
        )}&stty=${searchParams.get('stty')}&process=${searchParams.get(
          'process',
        )}`,
      );
      dispatch(updateStep(activeStep - 1));
    }

    //history.goBack();
  };

  useEffect(() => {
    if (activeStep === 0) {
      // navigate('/labeling/main');
    } else if (activeStep === 1) {
      if (ckboxArr.length === 1) {
        navigate(
          `/labeling/selectDcm?stUid=${searchParams.get(
            'stUid',
          )}&list=${encodeURIComponent(ckboxArr)}&pjId=${searchParams.get(
            'pjId',
          )}&pjcate=${searchParams.get('pjcate')}&stty=${searchParams.get(
            'stty',
          )}&process=${searchParams.get('process')}`,
        );
      }
    }
  }, [activeStep, ckboxArr, searchParams, navigate]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={1}>
          <StyledButton
            variant="contained"
            color="primary"
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            {activeStep === 0 ? '' : 'Back'}
          </StyledButton>
        </Grid>
        <Grid item xs={10}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
            className={classes.stepper}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={1}>
          <StyledButton
            variant="contained"
            color="primary"
            disabled={activeStep === steps.length - 1}
            onClick={handleNext}
            className={classes.button}
          >
            {activeStep === steps.length - 1 ? '' : 'Next'}
          </StyledButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default Steppers;
