import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetDicom } from '../../../_reducers/dicomReducer';
import { resetLabel } from '../../../_reducers/labelReducer';
import { updateStep, updateDsDe } from '../../../_reducers/stepperReducer';

import dicomParser from 'dicom-parser';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';

import {
  Grid,
  Slider,
  Tooltip,
  Card,
  CardContent,
  Divider,
  Typography,
  Button,
  Switch,
  Checkbox,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';

import SearchIcon from '@mui/icons-material/Search';
import BackspaceIcon from '@mui/icons-material/Backspace';

import cornerstoneSideImageLoader, {
  generateSideImages,
} from 'cornerstone-side-image-loader';

import { LABEL_SERVER, LUMBAR } from '../../../Config';
import axios from 'axios';
import saveAs from 'file-saver';
import Moment from 'moment';

const useStyles = makeStyles({
  root: { minHeight: '100%' },
  title: {
    fontSize: 24,
  },
  mrtopright: {
    position: 'absolute',
    top: 3,
    right: 3,
    color: 'white',
  },
  CardActionArea: {
    width: '50%',
    height: '80px',
    backgroundColor: '#8eacbb',
    color: 'black',
    '&:hover': {
      backgroundColor: '#34515e',
      color: 'white',
    },
    borderRadius: 0,
  },
});

const CustomeSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
    backgroundColor: 'transparent',
    float: 'right',
    position: 'absolute',
    zIndex: '3',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 360,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const ToggleButtonC = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#black',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT1 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#d50000',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT2 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#18ffff',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT3 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#eeff41',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT4 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#e040fb',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT5 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#00e676',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT6 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#d50000',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT7 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#18ffff',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT8 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#eeff41',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT9 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#e040fb',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT10 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#00e676',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT11 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#ffe0b2',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonT12 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#bbdefb',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);

const ToggleButtonL = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#eeff41',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);

const ToggleButtonL1 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'd50000',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);

const ToggleButtonL2 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#18ffff',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);

const ToggleButtonL3 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#eeff41',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);

const ToggleButtonL4 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#e040fb',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);

const ToggleButtonL5 = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#00e676',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);
const ToggleButtonS = withStyles({
  root: {
    fontWeight: 'bold',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: '#ffe0b2',
      backgroundColor: '#bdbdbd',
    },
  },
})(ToggleButton);

function init() {
  // Externals
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneSideImageLoader.external.cornerstone = cornerstone;

  //cornerstoneTools.init();
}

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const SelectDcm = (props) => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const series = useSelector((state) => state.series);
  const [dcmIndex, setDcmIndex] = useState([5, 37]);
  const [indexMax, setIndexMax] = useState(0);
  const [checked, setChecked] = useState(true);
  const [exclude, setExclude] = useState(false);
  const [lumbars, setLumbars] = useState(() => []);
  const [loading, setLoading] = useState(false);
  const [lumbarList, setLumbarList] = useState({
    C: [],
    T1: [],
    T2: [],
    T3: [],
    T4: [],
    T5: [],
    T6: [],
    T7: [],
    T8: [],
    T9: [],
    T10: [],
    T11: [],
    T12: [],
    L: [],
    T: [],
    L1: [],
    L2: [],
    L3: [],
    L4: [],
    L5: [],
    S: [],
  });
  const [studyDesc, setStudyDesc] = useState();
  const [seriesBodyPart, setseriesBodyPart] = useState();

  const userId = useSelector((state) => state.user.userData._id);

  const handleSwitchChange = (e) => {
    setChecked(e.target.checked);
    //Slider 초기화
    if (e.target.checked) {
      setDcmIndex([0, indexMax]);
    } else {
      setDcmIndex(indexMax);
    }

    const Canvas = document.querySelector('#lines');
    const Canvas2 = document.querySelector('#lines2');
    const Ctx = Canvas.getContext('2d');
    const Ctx2 = Canvas2.getContext('2d');
    Ctx.clearRect(0, 0, 360, 360);
    Ctx2.clearRect(0, 0, 360, 360);
  };

  const synchronizer = new cornerstoneTools.Synchronizer(
    'cornerstonenewimage',
    cornerstoneTools.updateImageSynchronizer,
  );

  const display = async (element, imageIds) => {
    cornerstone.enable(element);
    const image = await cornerstone.loadAndCacheImage(imageIds[0]);
    cornerstone.displayImage(element, image);

    synchronizer.add(element);

    cornerstoneTools.addStackStateManager(element, ['stack', 'Crosshairs']);
    cornerstoneTools.addToolState(element, 'stack', {
      imageIds: [...imageIds],
      currentImageIdIndex: 0,
    });
    cornerstoneTools.addToolForElement(
      element,
      cornerstoneTools['StackScrollMouseWheelTool'],
    );
    cornerstoneTools.addToolForElement(
      element,
      cornerstoneTools['CrosshairsTool'],
    );
    cornerstoneTools.setToolActiveForElement(
      element,
      'StackScrollMouseWheel',
      {},
    );
    cornerstoneTools.addToolForElement(element, cornerstoneTools['WwwcTool']);

    cornerstoneTools.setToolActiveForElement(element, 'Crosshairs', {
      mouseButtonMask: 1,
      synchronizationContext: synchronizer,
    });

    //windowing active
    cornerstoneTools.setToolActiveForElement(element, 'Wwwc', {
      mouseButtonMask: 2,
    });

    return await Promise.all(
      imageIds.map((imageId) => cornerstone.loadAndCacheImage(imageId)),
    );
  };

  // function sagittalmouse(e) {
  //   //console.log(e);
  //   //sagittal canvas
  //   const Canvas = document.querySelector('#lines');
  //   const Ctx = Canvas.getContext('2d');

  //   const x = e.nativeEvent.layerX;
  //   const y = e.nativeEvent.layerY;

  //   Ctx.clearRect(0, 0, 512, 512);
  //   Ctx.strokeStyle = '#4BFF2B';
  //   Ctx.lineWidth = 3;

  //   Ctx.beginPath();
  //   Ctx.moveTo(14, y);
  //   Ctx.lineTo(x - 16, y);
  //   Ctx.moveTo(x + 16, y);
  //   Ctx.lineTo(512, y);

  //   Ctx.moveTo(x, 0);
  //   Ctx.lineTo(x, y - 16);
  //   Ctx.moveTo(x, y + 16);
  //   Ctx.lineTo(x, 512);

  //   Ctx.stroke();
  //   Ctx.closePath();
  // }

  const handleLumbar = (event, newLumbar) => {
    setLumbars(newLumbar);
  };

  const detectLumbar = () => {
    // console.log('detect Lumbar');
    let list = searchParams.get('list');
    let formData = new FormData();
    formData.append('objId', searchParams.get('pjId'));
    formData.append('seriesId', series[list[0]].seriesNumber);
    formData.append('studyId', series[list[0]].studyNumber);
    formData.append('userId', userId);
    formData.append('studyDesc', seriesBodyPart);
    formData.append('exeType', 'manual');

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_FLASK_URL}/Lumbar`, formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then((res) => {
        return res.data.status;
      })
      .then((status) => {
        if (status === 'ok') {
          axios
            .get(
              `${LUMBAR}/list/${searchParams.get('pjId')}/${
                series[list[0]].seriesNumber
              }/${series[list[0]].studyNumber}`,
            )
            .then((res) => {
              if (res.data !== 'none') {
                setLumbarList(res.data['0']);
                if (seriesBodyPart === 'chest') {
                  setLumbars(['T12']);
                } else {
                  setLumbars(['L3']);
                }
              }
              setLoading(false);
            });
        }
      });
  };

  useEffect(() => {
    const Canvas3 = document.querySelector('#lines3');
    const Canvas4 = document.querySelector('#lines4');
    const Ctx3 = Canvas3.getContext('2d');
    const Ctx4 = Canvas4.getContext('2d');

    Ctx3.clearRect(0, 0, 360, 360);
    Ctx4.clearRect(0, 0, 360, 360);

    if (lumbars) {
      let len = lumbars.length;
      if (len > 0) {
        for (let i = 0; i < len; i++) {
          // console.log(lumbarList[lumbars[i]]);

          for (let j = 0; j < lumbarList[lumbars[i]].length; j++) {
            let y = (360 / indexMax) * lumbarList[lumbars[i]][j];

            if (lumbars[i] === 'C') Ctx3.strokeStyle = '#bbdefb';
            if (lumbars[i] === 'T1') Ctx3.strokeStyle = '#d50000';
            if (lumbars[i] === 'T2') Ctx3.strokeStyle = '#18ffff';
            if (lumbars[i] === 'T3') Ctx3.strokeStyle = '#eeff41';
            if (lumbars[i] === 'T4') Ctx3.strokeStyle = '#e040fb';
            if (lumbars[i] === 'T5') Ctx3.strokeStyle = '#00e676';
            if (lumbars[i] === 'T6') Ctx3.strokeStyle = '#d50000';
            if (lumbars[i] === 'T7') Ctx3.strokeStyle = '#18ffff';
            if (lumbars[i] === 'T8') Ctx3.strokeStyle = '#eeff41';
            if (lumbars[i] === 'T9') Ctx3.strokeStyle = '#e040fb';
            if (lumbars[i] === 'T10') Ctx3.strokeStyle = '#00e676';
            if (lumbars[i] === 'T11') Ctx3.strokeStyle = '#ffe0b2';
            if (lumbars[i] === 'T12') Ctx3.strokeStyle = '#bbdefb';
            if (lumbars[i] === 'T') Ctx3.strokeStyle = '#ffe0b2';
            if (lumbars[i] === 'L') Ctx3.strokeStyle = '#eeff41';
            if (lumbars[i] === 'L1') Ctx3.strokeStyle = '#d50000';
            if (lumbars[i] === 'L2') Ctx3.strokeStyle = '#18ffff';
            if (lumbars[i] === 'L3') Ctx3.strokeStyle = '#eeff41';
            if (lumbars[i] === 'L4') Ctx3.strokeStyle = '#e040fb';
            if (lumbars[i] === 'L5') Ctx3.strokeStyle = '#00e676';
            if (lumbars[i] === 'S') Ctx3.strokeStyle = '#ffe0b2';
            Ctx3.lineWidth = 1;
            Ctx3.beginPath();
            Ctx3.moveTo(20, y);
            Ctx3.lineTo(340, y);
            Ctx3.stroke();
            Ctx3.closePath();
            Ctx3.beginPath();

            if (lumbars[i] === 'C') Ctx4.strokeStyle = '#bbdefb';
            if (lumbars[i] === 'T1') Ctx4.strokeStyle = '#d50000';
            if (lumbars[i] === 'T2') Ctx4.strokeStyle = '#18ffff';
            if (lumbars[i] === 'T3') Ctx4.strokeStyle = '#eeff41';
            if (lumbars[i] === 'T4') Ctx4.strokeStyle = '#e040fb';
            if (lumbars[i] === 'T5') Ctx4.strokeStyle = '#00e676';
            if (lumbars[i] === 'T6') Ctx4.strokeStyle = '#d50000';
            if (lumbars[i] === 'T7') Ctx4.strokeStyle = '#18ffff';
            if (lumbars[i] === 'T8') Ctx4.strokeStyle = '#eeff41';
            if (lumbars[i] === 'T9') Ctx4.strokeStyle = '#e040fb';
            if (lumbars[i] === 'T10') Ctx4.strokeStyle = '#00e676';
            if (lumbars[i] === 'T11') Ctx4.strokeStyle = '#ffe0b2';
            if (lumbars[i] === 'T12') Ctx4.strokeStyle = '#bbdefb';
            if (lumbars[i] === 'L') Ctx4.strokeStyle = '#eeff41';
            if (lumbars[i] === 'T') Ctx4.strokeStyle = '#ffe0b2';
            if (lumbars[i] === 'L1') Ctx4.strokeStyle = '#d50000';
            if (lumbars[i] === 'L2') Ctx4.strokeStyle = '#18ffff';
            if (lumbars[i] === 'L3') Ctx4.strokeStyle = '#eeff41';
            if (lumbars[i] === 'L4') Ctx4.strokeStyle = '#e040fb';
            if (lumbars[i] === 'L5') Ctx4.strokeStyle = '#00e676';
            if (lumbars[i] === 'S') Ctx4.strokeStyle = '#ffe0b2';
            Ctx4.lineWidth = 1;
            Ctx4.beginPath();
            Ctx4.moveTo(20, y);
            Ctx4.lineTo(340, y);
            Ctx4.stroke();
            Ctx4.closePath();
          }
        }
      }
    }
  }, [lumbars]);

  const clearDetectLumbar = () => {
    const Canvas3 = document.querySelector('#lines3');
    const Canvas4 = document.querySelector('#lines4');
    const Ctx3 = Canvas3.getContext('2d');
    const Ctx4 = Canvas4.getContext('2d');

    Ctx3.clearRect(0, 0, 360, 360);
    Ctx4.clearRect(0, 0, 360, 360);
    setLoading(false);
    setLumbars(() => {});
    const lumbarlists = {
      C: [],
      T1: [],
      T2: [],
      T3: [],
      T4: [],
      T5: [],
      T6: [],
      T7: [],
      T8: [],
      T9: [],
      T10: [],
      T11: [],
      T12: [],
      T: [],
      L: [],
      L1: [],
      L2: [],
      L3: [],
      L4: [],
      L5: [],
      S: [],
    };
    setLumbarList(lumbarlists);
  };

  useEffect(() => {
    let mergeSeries = [];
    let list = '';
    //쿼리스트링 파싱
    if (searchParams.get('list') !== undefined) {
      list = [searchParams.get('list')];
      if (list[0] === null) list = props.ckboxArr;
    } else {
      list = searchParams.get('list').split(',');
      if (list[0] === null) list = props.ckboxArr.split(',');
    }

    //list배열 value에 해당하는 series index로 참조하여 여러개일경우 merge
    if (list.length === 1) {
      mergeSeries = series[list[0]].seriesImgIds;
    }
    if (list.length >= 2) {
      for (let i = 0; i < list.length; i++) {
        mergeSeries = [...mergeSeries, ...series[list[i]].seriesImgIds];
      }
    }

    // let studyDescription = series[list[0]].studyDescription;
    // let regex = /chest/i;
    // let BodyPart = series[list[0]].seriesBodyPart;

    // console.log(studyDescription);

    // if (studyDescription !== undefined) {
    //   if (studyDescription.search(regex) !== -1) {
    //     setStudyDesc('chest');
    //   } else {
    //     setStudyDesc('abdomen');
    //   }
    // } else {
    //   setStudyDesc('abdomen');
    // }

    // if (BodyPart !== undefined) {
    //   if (BodyPart.search(regex) !== -1) {
    //     setseriesBodyPart('chest');
    //   } else {
    //     setseriesBodyPart('abdomen');
    //   }
    // } else {
    //   setseriesBodyPart('abdomen');
    // }

    axios
      .get(
        `${LUMBAR}/list/${searchParams.get('pjId')}/${
          series[list[0]].seriesNumber
        }/${series[list[0]].studyNumber}/bodyPart`,
      )
      .then((res) => {
        if (res.data !== 'none') {
          if (res.data.condition === 104013) {
            setseriesBodyPart('chest');
          } else {
            setseriesBodyPart('abdomen');
          }
        } else {
          setseriesBodyPart('abdomen');
        }
        return res.data.condition;
      })
      .then((condition) => {
        cornerstone.loadImage(mergeSeries[0]);
        //Select DCM page 오픈시 labelList, dicom/imgids 초기화
        dispatch(resetLabel());
        dispatch(resetDicom());

        if (series[list[0]] !== undefined) {
          (async function () {
            init();
            const axial = document.querySelector('#axial');
            const sagittal = document.querySelector('#sagittal');
            const coronal = document.querySelector('#coronal');
            const images = await display(axial, mergeSeries);

            // axial.addEventListener('cornerstonenewimage', function (e) {
            //   let imageId = e.detail.image.imageId;
            //   // setDcmIndex(idx);
            // });

            const { sagittalImageIds, coronalImageIds } =
              generateSideImages(images);
            await display(coronal, coronalImageIds);
            await display(sagittal, sagittalImageIds);
          })();
          setIndexMax(mergeSeries.length - 1);
          setDcmIndex([0, mergeSeries.length - 1]);

          //stepper Ds,De 초기화
          dispatch(updateDsDe(0, mergeSeries.length - 1));
        }

        axios
          .get(
            `${LUMBAR}/list/${searchParams.get('pjId')}/${
              series[list[0]].seriesNumber
            }/${series[list[0]].studyNumber}`,
          )
          .then((res) => {
            // console.log(res.data['T'].toString());
            if (res.data) {
              if (res.data !== 'none') {
                setLumbarList(res.data['0']);
                //chest 인 경우
                if (condition === 104013) {
                  setLumbars(['T12']);
                } else {
                  setLumbars(['L3']);
                }
              }
              setLoading(false);
              // console.log( [
              //   Math.min(...res.data['L3']),
              //   Math.max(...res.data['L3']),
              // ]);

              // T-score not Segmentation 예외처리
              // console.log(searchParams.get('process'));
              if (res.data !== 'none')
                if (searchParams.get('process') === '103005') {
                  let maxIndex = mergeSeries.length - 1;
                  let index1 = 0;
                  let index2 = maxIndex;
                  if (condition === 104013) {
                    index1 = Math.max(...res.data[0]['T12']);
                    index2 = Math.min(...res.data[0]['T12']);
                  } else {
                    index1 = Math.max(...res.data[0]['L3']);
                    index2 = Math.min(...res.data[0]['L3']);
                  }

                  setDcmIndex([maxIndex - index1, maxIndex - index2]);

                  let y1 = (360 / maxIndex) * index2;
                  let y2 = (360 / maxIndex) * index1;

                  const Canvas = document.querySelector('#lines');
                  const Canvas2 = document.querySelector('#lines2');
                  const Ctx = Canvas.getContext('2d');
                  const Ctx2 = Canvas2.getContext('2d');
                  Ctx.clearRect(0, 0, 360, 360);
                  Ctx.strokeStyle = '#4BFF2B';
                  Ctx.lineWidth = 2;
                  Ctx.beginPath();
                  Ctx.moveTo(0, y1);
                  Ctx.lineTo(360, y1);
                  Ctx.stroke();
                  Ctx.closePath();
                  Ctx.beginPath();
                  Ctx.moveTo(0, y2);
                  Ctx.lineTo(360, y2);
                  Ctx.stroke();
                  Ctx.closePath();

                  Ctx2.clearRect(0, 0, 360, 360);
                  Ctx2.strokeStyle = '#4BFF2B';
                  Ctx2.lineWidth = 2;
                  Ctx2.beginPath();
                  Ctx2.moveTo(0, y1);
                  Ctx2.lineTo(360, y1);
                  Ctx2.stroke();
                  Ctx2.closePath();
                  Ctx2.beginPath();
                  Ctx2.moveTo(0, y2);
                  Ctx2.lineTo(360, y2);
                  Ctx2.stroke();
                  Ctx2.closePath();
                  dispatch(
                    updateDsDe(maxIndex - index2, maxIndex - index1 + 1),
                  );
                }
            }
          });
      });

    //뒤로가기버튼 조건처리
    // window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
    // eslint-disable-next-line
  }, []);

  const onBackButtonEvent = (e) => {
    dispatch(updateStep(0));
    // history.goBack();
  };

  // SliderLine 작업 진행 합니다.
  const handleChange = (e, newValue) => {
    const Canvas = document.querySelector('#lines');
    const Canvas2 = document.querySelector('#lines2');
    const Ctx = Canvas.getContext('2d');
    const Ctx2 = Canvas2.getContext('2d');

    if (e.type === 'mousemove') {
      if (checked) {
        let y1 = ((360 / indexMax) * (indexMax - newValue[1])).toFixed(2);
        let y2 = ((360 / indexMax) * (indexMax - newValue[0])).toFixed(2);

        Ctx.clearRect(0, 0, 360, 360);
        Ctx.strokeStyle = '#4BFF2B';
        Ctx.lineWidth = 2;
        Ctx.beginPath();
        Ctx.moveTo(0, y1);
        Ctx.lineTo(360, y1);
        Ctx.stroke();
        Ctx.closePath();
        Ctx.beginPath();
        Ctx.moveTo(0, y2);
        Ctx.lineTo(360, y2);
        Ctx.stroke();
        Ctx.closePath();

        Ctx2.clearRect(0, 0, 360, 360);
        Ctx2.strokeStyle = '#4BFF2B';
        Ctx2.lineWidth = 2;
        Ctx2.beginPath();
        Ctx2.moveTo(0, y1);
        Ctx2.lineTo(360, y1);
        Ctx2.stroke();
        Ctx2.closePath();
        Ctx2.beginPath();
        Ctx2.moveTo(0, y2);
        Ctx2.lineTo(360, y2);
        Ctx2.stroke();
        Ctx2.closePath();

        setDcmIndex(newValue);
        dispatch(
          updateDsDe(indexMax - newValue[1], indexMax - newValue[0] + 1),
        );
      } else {
        let y1 = (360 / indexMax) * (indexMax - newValue);

        Ctx.clearRect(0, 0, 360, 360);
        Ctx.strokeStyle = '#4BFF2B';
        Ctx.lineWidth = 2;
        Ctx.beginPath();
        Ctx.moveTo(0, y1);
        Ctx.lineTo(360, y1);
        Ctx.stroke();
        Ctx.closePath();

        Ctx2.clearRect(0, 0, 360, 360);
        Ctx2.strokeStyle = '#4BFF2B';
        Ctx2.lineWidth = 2;
        Ctx2.beginPath();
        Ctx2.moveTo(0, y1);
        Ctx2.lineTo(360, y1);
        Ctx2.stroke();
        Ctx2.closePath();

        setDcmIndex(newValue);
        dispatch(updateDsDe(indexMax - newValue, indexMax - newValue + 1));
      }
    }
  };

  function SelectedDicomDown() {
    // selectdcm 페이지에서 선택된 dicom 계산 후 서버로 전송
    const config = {
      responseType: 'blob',
    };
    let list = searchParams.get('list');
    let wadoURIs = series[list].seriesImgIds;
    let sopArr = [];
    let saveFileName = '';

    //Select Mode 에 따른 조건 처리
    if (checked) {
      let ds = indexMax - dcmIndex[1];
      let de = indexMax - dcmIndex[0] + 1;
      // console.log(ds, de);
      //Range Mode
      // 선택제외 파일 - range
      if (exclude) {
        wadoURIs = [...wadoURIs.slice(0, ds), ...wadoURIs.slice(de, indexMax)];

        let len = wadoURIs.length;

        for (let i = 0; i < len; i++) {
          sopArr.push(wadoURIs[i].split('objectUID=')[1].split('&')[0]);
        }
        saveFileName = Moment().format('YYMMDDHHmmss') + '_noselect.zip';
      }
      //선택된 파일 - range
      else {
        wadoURIs = wadoURIs.slice(ds, de);
        let len = wadoURIs.length;

        for (let i = 0; i < len; i++) {
          sopArr.push(wadoURIs[i].split('objectUID=')[1].split('&')[0]);
        }
        saveFileName = Moment().format('YYMMDDHHmmss') + '_select.zip';
      }
    } else {
      let idx = indexMax - dcmIndex;
      // 선택제외 파일 - slice
      if (exclude) {
        let len = wadoURIs.length;
        for (let i = 0; i < len; i++) {
          if (i !== idx)
            sopArr.push(wadoURIs[i].split('objectUID=')[1].split('&')[0]);
        }
        saveFileName = Moment().format('YYMMDDHHmmss') + '_noselect.zip';
      }
      //선택된 파일 - slice
      else {
        sopArr.push(wadoURIs[idx].split('objectUID=')[1].split('&')[0]);
        saveFileName = Moment().format('YYMMDDHHmmss') + '_select.zip';
      }
    }

    axios
      .post(
        `${LABEL_SERVER}/dcmzip`,
        { sopArr: sopArr, projectId: searchParams.get('pjId') },
        config,
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/zip' });
        saveAs(blob, saveFileName);
      });
  }

  function LabelingToolButton() {
    let list = searchParams.get('list').split(',');
    dispatch(updateStep(2));
    //선택 series 개수 1개일때
    if (list.length === 1) {
      //Select Mode 에 따른 조건 처리
      if (checked) {
        navigate(
          `/labeling/labelingtool?stUid=${searchParams.get('stUid')}&list=${
            list[0]
          }&Ds=${indexMax - dcmIndex[1]}&De=${
            indexMax - dcmIndex[0]
          }&pjcate=${searchParams.get('pjcate')}&pjId=${searchParams.get(
            'pjId',
          )}&stty=${searchParams.get('stty')}&process=${searchParams.get(
            'process',
          )}&seriesBodyPart=${seriesBodyPart}`,
          {
            state: {
              pjId: searchParams.get('id'),
              list: list[0],
              Ds: indexMax - dcmIndex[1],
              De: indexMax - dcmIndex[0],
            },
          },
        );
      } else {
        navigate(
          `/labeling/labelingtool?stUid=${searchParams.get('stUid')}&list=${
            list[0]
          }&Ds=${indexMax - dcmIndex}&De=${
            indexMax - dcmIndex
          }&pjcate=${searchParams.get('pjcate')}&pjId=${searchParams.get(
            'pjId',
          )}&stty=${searchParams.get('stty')}&process=${searchParams.get(
            'process',
          )}&seriesBodyPart=${seriesBodyPart}`,
          {
            state: {
              pjId: searchParams.get('id'),
              list: list[0],
              Ds: indexMax - dcmIndex,
              De: indexMax - dcmIndex,
            },
          },
        );
      }
    }
    // // 선택 series 개수 2개이상
    // else if (list.length >= 2) {
    //   history.push(
    //     `/labelingtool/${list[0]}?Ds=${indexMax - dcmIndex[1]}&De=${
    //       indexMax - dcmIndex[0]
    //     }`,
    //   );
    // }
  }

  function MouseWindowing() {
    let axial = document.querySelector('#axial');
    let sagittal = document.querySelector('#sagittal');
    let coronal = document.querySelector('#coronal');

    let axialVoi = cornerstone.getViewport(axial).voi;
    let sagittalVoi = cornerstone.getViewport(sagittal).voi;
    let coronalVoi = cornerstone.getViewport(coronal).voi;

    // axialVoi.windowCenter = 992;
    // axialVoi.windowWidth = 2780;
    // sagittalVoi.windowCenter = 992;
    // sagittalVoi.windowWidth = 2780;
    // coronalVoi.windowCenter = 992;
    // coronalVoi.windowWidth = 2780;

    axialVoi.windowCenter = 400;
    axialVoi.windowWidth = 400;
    sagittalVoi.windowCenter = 400;
    sagittalVoi.windowWidth = 400;
    coronalVoi.windowCenter = 400;
    coronalVoi.windowWidth = 400;

    cornerstone.updateImage(axial);
    cornerstone.updateImage(sagittal);
    cornerstone.updateImage(coronal);
  }
  return (
    <div>
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        style={{
          paddingTop: '40px',
          height: '100%',
          backgroundColor: '#8e8e8e',
        }}
      >
        <Grid
          item
          xs={12}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Card className={classes.root}>
            <Button
              variant="outlined"
              className={classes.CardActionArea}
              onClick={SelectedDicomDown}
              sx={{ textTransform: 'initial' }}
            >
              <Typography
                className={classes.title}
                variant="h5"
                component="h2"
                align="center"
              >
                {exclude
                  ? 'Non-Selected DICOM Download'
                  : 'Selected DICOM Download'}
              </Typography>
            </Button>

            <Button
              variant="outlined"
              className={classes.CardActionArea}
              onClick={LabelingToolButton}
              sx={{ textTransform: 'initial' }}
            >
              <Typography
                className={classes.title}
                variant="h5"
                component="h2"
                align="center"
              >
                {'Selected DICOM Segmentation'}
                <Typography gutterBottom>( Select range and click )</Typography>
              </Typography>
            </Button>

            <CardContent>
              <Grid
                item
                container
                columns={11}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="center"
                  sx={{ paddingBottom: '8px' }}
                >
                  <Grid item>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Lumbar Detection :
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ textTransform: 'none' }}
                        onClick={detectLumbar}
                        disabled={loading}
                      >
                        {loading ? (
                          <CircularProgress
                            size={20}
                            thickness={5}
                            // color={'inherit'}
                            sx={{ marginLeft: '4px' }}
                          >
                            <SearchIcon sx={{ marginRight: '8px' }} />
                          </CircularProgress>
                        ) : (
                          <SearchIcon sx={{ marginRight: '4px' }} />
                        )}{' '}
                        Detect
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{ textTransform: 'none' }}
                        onClick={clearDetectLumbar}
                      >
                        <BackspaceIcon sx={{ marginRight: '4px' }} />
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ width: '600px' }}>
                    {lumbarList && seriesBodyPart === 'chest' && (
                      <ToggleButtonGroup
                        size="small"
                        value={lumbars}
                        onChange={handleLumbar}
                        sx={{ height: '32px', boxShadow: '1px 1px 1px gray' }}
                      >
                        <ToggleButtonC value="C" disabled={!lumbarList['C']}>
                          C
                        </ToggleButtonC>
                        <ToggleButtonT1 value="T1" disabled={!lumbarList['T1']}>
                          T1
                        </ToggleButtonT1>
                        <ToggleButtonT2 value="T2" disabled={!lumbarList['T2']}>
                          T2
                        </ToggleButtonT2>
                        <ToggleButtonT3 value="T3" disabled={!lumbarList['T3']}>
                          T3
                        </ToggleButtonT3>
                        <ToggleButtonT4 value="T4" disabled={!lumbarList['T4']}>
                          T4
                        </ToggleButtonT4>
                        <ToggleButtonT5 value="T5" disabled={!lumbarList['T5']}>
                          T5
                        </ToggleButtonT5>
                        <ToggleButtonT6 value="T6" disabled={!lumbarList['T6']}>
                          T6
                        </ToggleButtonT6>
                        <ToggleButtonT7 value="T7" disabled={!lumbarList['T7']}>
                          T7
                        </ToggleButtonT7>
                        <ToggleButtonT8 value="T8" disabled={!lumbarList['T8']}>
                          T8
                        </ToggleButtonT8>
                        <ToggleButtonT9 value="T9" disabled={!lumbarList['T9']}>
                          T9
                        </ToggleButtonT9>
                        <ToggleButtonT10
                          value="T10"
                          disabled={!lumbarList['T10']}
                        >
                          T10
                        </ToggleButtonT10>
                        <ToggleButtonT11
                          value="T11"
                          disabled={!lumbarList['T11']}
                        >
                          T11
                        </ToggleButtonT11>
                        <ToggleButtonT12
                          value="T12"
                          disabled={!lumbarList['T12']}
                        >
                          T12
                        </ToggleButtonT12>
                        <ToggleButtonL value="L" disabled={!lumbarList['L']}>
                          L
                        </ToggleButtonL>
                      </ToggleButtonGroup>
                    )}

                    {lumbarList && seriesBodyPart === 'abdomen' && (
                      <ToggleButtonGroup
                        size="small"
                        value={lumbars}
                        onChange={handleLumbar}
                        sx={{ height: '32px', boxShadow: '1px 1px 1px gray' }}
                      >
                        <ToggleButtonL value="T" disabled={!lumbarList['L']}>
                          T
                        </ToggleButtonL>
                        <ToggleButtonL1 value="L1" disabled={!lumbarList['L1']}>
                          L1
                        </ToggleButtonL1>
                        <ToggleButtonL2 value="L2" disabled={!lumbarList['L2']}>
                          L2
                        </ToggleButtonL2>

                        <ToggleButtonL3 value="L3" disabled={!lumbarList['L3']}>
                          L3
                        </ToggleButtonL3>

                        <ToggleButtonL4 value="L4" disabled={!lumbarList['L4']}>
                          L4
                        </ToggleButtonL4>

                        <ToggleButtonL5 value="L5" disabled={!lumbarList['L5']}>
                          L5
                        </ToggleButtonL5>
                        <ToggleButtonS value="S" disabled={!lumbarList['S']}>
                          S
                        </ToggleButtonS>
                      </ToggleButtonGroup>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <Divider sx={{ borderBottomWidth: 2 }} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8}>
                  {searchParams.get('stty') === '102004' && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={MouseWindowing}
                      sx={{ textTransform: 'none' }}
                    >
                      Mouse Windowing
                    </Button>
                  )}
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={5}>
                    {'Exclude'}
                    <Checkbox
                      size="small"
                      checked={exclude}
                      onChange={() => {
                        setExclude(!exclude);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {!checked ? <b>Slice</b> : 'Slice'}
                    <Switch
                      checked={checked}
                      onChange={handleSwitchChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {checked ? <b>Range</b> : 'Range'}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider sx={{ borderBottomWidth: 2 }} />
              </Grid>
              <Grid
                item
                container
                columns={12}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ marginBottom: '8px' }}
              >
                <Grid item xs={4}>
                  <Typography variant="subtitle2">
                    Drag on the Image to Scroll throught the Series
                  </Typography>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle2" align="right">
                    Drag on the Scrollbar to Select the Desired Level
                  </Typography>
                </Grid>
              </Grid>

              <div>
                <div
                  id="axial"
                  className="cornerstone-element"
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  style={{
                    width: '360px',
                    height: '360px',
                    backgroundColor: 'black',
                    outline: 'solid',
                    outlineColor: 'blue',
                    outlineWidth: '3px',
                    outlineOffset: '1px',
                    position: 'relative',
                    float: 'left',
                    margin: '4px 4px 4px 5px',
                  }}
                >
                  <div id="mrtopright" className={classes.mrtopright}>
                    <span>
                      {checked
                        ? '(' +
                          [indexMax - dcmIndex[1], indexMax - dcmIndex[0]] +
                          ')'
                        : '(' + [indexMax - dcmIndex] + ')'}
                    </span>
                  </div>
                </div>

                <div
                  id="coronal"
                  className="cornerstone-element"
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  style={{
                    width: '360px',
                    height: '360px',
                    backgroundColor: 'black',
                    outline: 'solid',
                    outlineColor: 'blue',
                    outlineWidth: '3px',
                    outlineOffset: '1px',
                    float: 'left',
                    position: 'relative',
                    zIndex: '1',
                    margin: '4px 4px 4px 3px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'transparent',
                      position: 'absolute',
                      float: 'right',
                      top: '0',
                      zIndex: '2',
                    }}
                  >
                    <canvas id="lines3" width="360px" height="360px" />
                  </div>
                  <div
                    style={{
                      backgroundColor: 'transparent',
                      position: 'absolute',
                      float: 'right',
                      top: '0',
                      zIndex: '2',
                    }}
                  >
                    <canvas id="lines" width="360px" height="360px" />
                  </div>
                </div>

                <div
                  id="sagittal"
                  className="cornerstone-element"
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  style={{
                    width: '360px',
                    height: '360px',
                    backgroundColor: 'black',
                    outline: 'solid',
                    outlineColor: 'blue',
                    outlineWidth: '3px',
                    outlineOffset: '1px',
                    float: 'left',
                    position: 'relative',
                    zIndex: '1',
                    margin: '4px 4px 4px 3px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'transparent',
                      position: 'absolute',
                      float: 'right',
                      top: '0',
                      zIndex: '2',
                    }}
                  >
                    <canvas id="lines4" width="360px" height="360px" />
                  </div>
                  <div
                    style={{
                      backgroundColor: 'transparent',
                      position: 'absolute',
                      float: 'right',
                      top: '0',
                      zIndex: '2',
                    }}
                  >
                    <canvas id="lines2" width="360px" height="360px" />
                  </div>
                </div>
                <CustomeSlider
                  orientation="vertical"
                  value={dcmIndex}
                  onChange={handleChange}
                  //valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  max={indexMax}
                  style={{
                    padding: '0px',
                    height: '366px',
                    position: 'relative',
                  }}
                />
              </div>

              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item sx={{ width: '360px' }}>
                  <Typography variant="h5" align="center" fontWeight="bold">
                    Axial
                  </Typography>
                </Grid>
                <Grid item sx={{ width: '360px' }}>
                  <Typography variant="h5" align="center" fontWeight="bold">
                    Coronal
                  </Typography>
                </Grid>
                <Grid item sx={{ width: '360px' }}>
                  <Typography variant="h5" align="center" fontWeight="bold">
                    Sagittal
                  </Typography>
                </Grid>
              </Grid>

              <Grid>
                <Divider sx={{ borderBottomWidth: 2 }} />
              </Grid>
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={6} sx={{ marginTop: 2 }}>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              width: '100px',
                              backgroundColor: '#757575',
                              color: 'white',
                              fontSize: 14,
                              fontWeight: 600,
                            }}
                          >
                            Lumbar
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '500px',
                              backgroundColor: '#757575',
                              color: 'white',
                              fontSize: 14,
                              fontWeight: 600,
                            }}
                          >
                            Predict and Selected Position
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {lumbarList && seriesBodyPart === 'chest' && (
                        <TableBody>
                          <TableRow key={'C'}>
                            <TableCell component="th" scope="row">
                              {'C'}
                            </TableCell>
                            {lumbarList['C'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['C'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T1'}>
                            <TableCell component="th" scope="row">
                              {'T1'}
                            </TableCell>
                            {lumbarList['T1'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T1'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T2'}>
                            <TableCell component="th" scope="row">
                              {'T2'}
                            </TableCell>
                            {lumbarList['T2'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T2'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T3'}>
                            <TableCell component="th" scope="row">
                              {'T3'}
                            </TableCell>
                            {lumbarList['T3'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T3'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T4'}>
                            <TableCell component="th" scope="row">
                              {'T4'}
                            </TableCell>
                            {lumbarList['T4'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T4'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T5'}>
                            <TableCell component="th" scope="row">
                              {'T5'}
                            </TableCell>
                            {lumbarList['T5'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T5'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T6'}>
                            <TableCell component="th" scope="row">
                              {'T6'}
                            </TableCell>
                            {lumbarList['T6'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T6'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T7'}>
                            <TableCell component="th" scope="row">
                              {'T7'}
                            </TableCell>
                            {lumbarList['T7'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T7'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T8'}>
                            <TableCell component="th" scope="row">
                              {'T8'}
                            </TableCell>
                            {lumbarList['T8'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T8'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T9'}>
                            <TableCell component="th" scope="row">
                              {'T9'}
                            </TableCell>
                            {lumbarList['T9'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T9'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T10'}>
                            <TableCell component="th" scope="row">
                              {'T10'}
                            </TableCell>
                            {lumbarList['T10'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T10'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T11'}>
                            <TableCell component="th" scope="row">
                              {'T11'}
                            </TableCell>
                            {lumbarList['T11'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T11'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'T12'}>
                            <TableCell component="th" scope="row">
                              {'T12'}
                            </TableCell>
                            {lumbarList['T12'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T12'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'L'}>
                            <TableCell component="th" scope="row">
                              {'L'}
                            </TableCell>
                            {lumbarList['L'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['L'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      )}
                      {lumbarList && seriesBodyPart === 'abdomen' && (
                        <TableBody>
                          <TableRow key={'T'}>
                            <TableCell component="th" scope="row">
                              {'T'}
                            </TableCell>
                            {lumbarList['T'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['T'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'L1'}>
                            <TableCell component="th" scope="row">
                              {'L1'}
                            </TableCell>
                            {lumbarList['L1'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['L1'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'L2'}>
                            <TableCell component="th" scope="row">
                              {'L2'}
                            </TableCell>
                            {lumbarList['L2'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['L2'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'L3'}>
                            <TableCell component="th" scope="row">
                              {'L3'}
                            </TableCell>
                            {lumbarList['L3'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['L3'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'L4'}>
                            <TableCell component="th" scope="row">
                              {'L4'}
                            </TableCell>
                            {lumbarList['L4'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['L4'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'L5'}>
                            <TableCell component="th" scope="row">
                              {'L5'}
                            </TableCell>
                            {lumbarList['L5'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['L5'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={'S'}>
                            <TableCell component="th" scope="row">
                              {'S'}
                            </TableCell>
                            {lumbarList['S'] && (
                              <TableCell sx={{ wordBreak: 'break-all' }}>
                                {lumbarList['S'].toString()}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectDcm;
