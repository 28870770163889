import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setImageIdx } from '../../../../_reducers/dicomReducer';
import { updateBrushSize } from '../../../../_reducers/segmentReducer';
import {
  selectCurrLabelIndex,
  toggleLabelVisible,
  addLabelIndex,
  addLabelName,
  addSlicPos,
  // clearLabel,
  toggleLbLoad,
} from '../../../../_reducers/labelReducer';
import {
  updateBrushcolorIdx,
  updateLabelampMaxIdx,
} from '../../../../_reducers/segmentReducer';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import '../DicomViewer/ContextMenu.css';

import { Button, Grid, TextField, Divider, Typography } from '@mui/material';
import axios from 'axios';

import dicomParser from 'dicom-parser';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';
import CornerstoneViewport from 'react-cornerstone-viewport';

// Cornerstone Tools
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.init();

// IMAGE LOADER
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

cornerstoneWADOImageLoader.webWorkerManager.initialize({
  maxWebWorkers: 1,
  startWebWorkersOnDemand: false,
  taskConfiguration: {
    decodeTask: {
      initializeCodecsOnStartup: false,
      loadCodecsOnStartup: false,
      usePDFJS: false,
      strict: false,
    },
  },
  webWorkerTaskPaths: [
    'https://unpkg.com/cornerstone-wado-image-loader@4.2.1/dist/610.bundle.min.worker.js',
    'https://unpkg.com/cornerstone-wado-image-loader@4.2.1/dist/888.bundle.min.worker.js',
  ],
});

const { getters, setters, configuration, state } =
  cornerstoneTools.getModule('segmentation');

const DicomViewer = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const dicomfiles = useSelector((state) => state.dicom.imgIds);
  const imageIdx = useSelector((state) => state.dicom.currentImageIdIndex);
  const buttonstate = useSelector((state) => state.button);
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const lb = useSelector((state) => state.label);
  const labelmapMax = useSelector((state) => state.seg.labelmapMaxIndex);
  const label = useSelector((state) => state.label[currentDicomIdx]);
  const userId = useSelector((state) => state.user.userData._id);
  const currentDicomName = useSelector((state) => state.dicom.fileNames);
  const reduxDicom = useSelector((state) => state.dicom);

  const [ww, setWw] = useState(0);
  const [wc, setWc] = useState(0);

  // let stack = {
  //   currentImageIdIndex: 0,
  //   imageIds: dicomfiles,
  // };

  //dicom파일 선택 후 viewer 기본값 세팅
  useEffect(() => {
    if (dicomfiles.length !== 0) {
      // dicomfile load 후 첫 image viewing처리
      let element = document.querySelector('.viewport-element');
      cornerstone.loadImage(dicomfiles[0]).then(function (image) {
        cornerstone.displayImage(element, image);
      });
    }
  }, [dicomfiles]);

  useEffect(() => {
    if (dicomfiles.length !== 0) {
      //db 조회하여 현재 Dicom의 Label Load
      let formData = new FormData();
      formData.append('userId', userId);
      formData.append('objId', searchParams.get('pjId'));
      formData.append('dicomName', currentDicomName[currentDicomIdx]);
      formData.append(
        'sopUid',
        reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
      );
      formData.append(
        'studyUid',
        reduxDicom.imgIds[currentDicomIdx].split('&')[1].split('=')[1],
      );

      //brush colotLut 추출 -> label 정보 db 조회후 변환을 위함.
      let colorLut = [];
      colorLut.push(state.colorLutTables[0][1]);
      colorLut.push(state.colorLutTables[0][9]);
      colorLut.push(state.colorLutTables[0][25]);
      colorLut.push(state.colorLutTables[0][2]);
      colorLut.push(state.colorLutTables[0][13]);
      colorLut.push(state.colorLutTables[0][8]);
      colorLut.push(state.colorLutTables[0][16]);
      formData.append('colorLut', colorLut);

      // tscore - process status param추가
      formData.append('process', searchParams.get('process'));

      //각 dicom이미지 별 최초 1번만 label load 처리
      if (!lb[currentDicomIdx].lbLoad) {
        axios
          .post(`${process.env.REACT_APP_FLASK_URL}/LoadLabel`, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then((res) => {
            if (res.data !== 'none') {
              //label 정보 초기화
              // dispatch(clearLabel(currentDicomIdx));

              // load되어진 label dicom viewer Labelmap에 적용.
              let resultLen = res.data.labelName.length;

              let element = document.querySelector('.viewport-element');
              const { labelmap3D } = getters.labelmap2D(element);
              labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap = [
                1, 2, 8, 9, 13, 16, 25,
              ];
              let temp = labelmap3D.labelmaps2D[0];

              //loadlabel 추가를 위하여 cornerstonetool의 labelmap2d에 빈 labelmap 추가
              for (let k = 1; k < resultLen + 1; k++) {
                let labelCnt = res.data.dicomName.filter(
                  (name) => name === currentDicomName[0],
                ).length;
                for (let i = 1; i < labelCnt + 1; i++) {
                  setters.activeLabelmapIndex(element, i);
                }
              }
              // let checkIdx = 1;
              let checkIdx = labelmapMax;
              for (let i = 1; i < resultLen + 1; i++) {
                // res.data 에서 i번째 dicom이 redux filenames의 idx 추출
                let Idx = currentDicomName.findIndex(
                  (name) => name === res.data.dicomName[i - 1],
                );
                // idx 존재 할때만 처리
                if (Idx !== -1) {
                  // label add
                  // checkIdx = labelmapMax;
                  let insertedLabelIdx = lb[Idx].labelindex.length + 1;
                  setters.activeLabelmapIndex(element, checkIdx);
                  dispatch(addLabelIndex(checkIdx, currentDicomIdx));
                  dispatch(
                    addLabelName(res.data.labelName[i - 1], currentDicomIdx),
                  );
                  dispatch(addSlicPos(currentDicomIdx));
                  let binary_string = window.atob(res.data.labelImg[i - 1]);
                  let len = binary_string.length;
                  let bytes = new Uint8Array(len);
                  for (let j = 0; j < len; j++) {
                    bytes[j] = binary_string.charCodeAt(j);
                  }
                  let seg = new Uint16Array(bytes.buffer);
                  // cornerstone labelmap에 위에서 처리한 이미지 배열
                  getters.labelmaps3D(element).labelmaps3D[
                    checkIdx
                  ].labelmaps2D[Idx] = temp;
                  getters.labelmaps3D(element).labelmaps3D[
                    checkIdx
                  ].labelmaps2D[Idx] = {
                    labelname: res.data.labelName[insertedLabelIdx - 1],
                  };
                  getters.labelmaps3D(element).labelmaps3D[
                    checkIdx
                  ].labelmaps2D[Idx].pixelData = seg;
                  //현재 label 활성화 color 지정
                  getters.labelmaps3D(element).labelmaps3D[
                    checkIdx
                  ].labelmaps2D[Idx].segmentsOnLabelmap = Array.from(
                    new Set(seg),
                  );
                  cornerstone.updateImage(element);

                  //updateLabelampMaxIdx
                  checkIdx = checkIdx + 1;
                }
              }
              // update select labelindex
              dispatch(
                selectCurrLabelIndex(
                  label.labelname.length - 1,
                  currentDicomIdx,
                ),
              );
              dispatch(updateLabelampMaxIdx(checkIdx));
            }
            dispatch(toggleLbLoad(currentDicomIdx));
          });
      }
    }

    // eslint-disable-next-line
  }, [dicomfiles, currentDicomIdx]);

  useEffect(() => {
    let element = document.querySelector('.viewport-element');
    //Dicom Viewer Tool add
    cornerstoneTools.addToolForElement(element, cornerstoneTools.WwwcTool);
    cornerstoneTools.addToolForElement(element, cornerstoneTools.PanTool);
    cornerstoneTools.addToolForElement(element, cornerstoneTools.ZoomTool);
    cornerstoneTools.addToolForElement(
      element,
      cornerstoneTools.StackScrollTool,
    );
    cornerstoneTools.addStackStateManager(element, ['stack']);
    cornerstoneTools.addToolState(element, 'stack', {
      currentImageIdIndex: 0,
      imageIds: dicomfiles,
    });
    cornerstoneTools.addToolForElement(
      element,
      cornerstoneTools.ZoomMouseWheelTool,
    );
    cornerstoneTools.addToolForElement(
      element,
      cornerstoneTools.RectangleScissorsTool,
    );
    cornerstoneTools.addToolForElement(
      element,
      cornerstoneTools.CorrectionScissorsTool,
    );
    cornerstoneTools.addToolForElement(element, cornerstoneTools.BrushTool);
    //dicom rendering 시 event -> leftSide/DicomSelector index change
    element.addEventListener('cornerstonenewimage', (e) => {
      let imgId = e.detail.image.imageId;
      const imageIds = cornerstoneTools.getToolState(element, 'stack').data[0]
        .imageIds;
      let findIdx = imageIds.findIndex((dicom) => dicom === imgId);

      if (findIdx > 0) {
        dispatch(setImageIdx(findIdx));
      } else {
        dispatch(setImageIdx(0));
      }
      //draw 영역 fill영역만 활성화
      cornerstoneTools.getModule(
        'segmentation',
      ).configuration.renderOutline = false;
      cornerstoneTools.getModule('segmentation').configuration.fillAlpha = 0.55;
      cornerstoneTools.getModule(
        'segmentation',
      ).configuration.fillAlphaInactive = 0.3;
      setters.radius(3);
      // voi.windowCenter = 20;
      // voi.windowWidth = 500;
    });

    // keydown : `[`, `]` 키보드 버튼으로 Radius 크기 조절
    const KeyDown = (e) => {
      let element = document.querySelector('.viewport-element');
      //input영역 선택 중에 shortkey를 누르지 않을때
      if (e.target.constructor !== HTMLInputElement) {
        if (e.keyCode === 219) {
          if (configuration.radius < 0) {
            setters.radius(1);
            dispatch(updateBrushSize(1));
          } else {
            setters.radius(configuration.radius - 1);
            dispatch(updateBrushSize(configuration.radius));
          }
        } else if (e.keyCode === 221) {
          if (configuration.radius > 50) {
            setters.radius(50);
            dispatch(updateBrushSize(50));
          } else {
            setters.radius(configuration.radius + 1);
            dispatch(updateBrushSize(configuration.radius));
          }
        }
        cornerstone.updateImage(element);
      }
    };

    // KeyDown 이벤트 함수 등록 : Radius 조절
    window.addEventListener('keydown', KeyDown);
    return () => window.removeEventListener('keydown', KeyDown);
  }, [dispatch, dicomfiles]);

  //Windowing value 조절 함수
  function handleWindowing(e, data) {
    let element = document.querySelector('.viewport-element');
    let voi = cornerstone.getViewport(element).voi;
    voi.windowCenter = data.wc;
    voi.windowWidth = data.ww;
    setWw(data.ww);
    setWc(data.wc);
    cornerstone.updateImage(element);
  }

  useEffect(() => {
    // labelmap hide toggle : '1', '2', '3', '4' 키보드 버튼으로 1,2,3,4 라벨 hide toggle
    window.prevKeyCode = null;
    const KeyDownHideToggle = (e) => {
      let element = document.querySelector('.viewport-element');
      let currActiveLabelIndex = getters.activeLabelmapIndex(element);

      if (e.target.constructor !== HTMLInputElement) {
        //input영역 선택 중에 shortkey를 누르지 않을때
        //key 1~9 까지 10개
        // 첫번째 labellist 누를때 labellist 선택
        // 두번재 labellist 누를때 labellist hide
        if (e.keyCode >= 49 && e.keyCode <= 57) {
          switch (e.keyCode) {
            case 49:
              dispatch(selectCurrLabelIndex(0, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[0]);
              break;
            case 50:
              dispatch(selectCurrLabelIndex(1, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[1]);
              break;
            case 51:
              dispatch(selectCurrLabelIndex(2, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[2]);
              break;
            case 52:
              dispatch(selectCurrLabelIndex(3, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[3]);
              break;
            case 53:
              dispatch(selectCurrLabelIndex(4, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[4]);
              break;
            case 54:
              dispatch(selectCurrLabelIndex(5, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[5]);
              break;
            case 55:
              dispatch(selectCurrLabelIndex(6, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[6]);
              break;
            case 56:
              dispatch(selectCurrLabelIndex(7, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[7]);
              break;
            case 57:
              dispatch(selectCurrLabelIndex(8, currentDicomIdx));
              setters.activeLabelmapIndex(element, label.labelindex[8]);
              break;
            default:
              break;
          }

          if (window.prevKeyCode === e.keyCode) {
            const { labelmap3D } = getters.labelmap2D(element);
            let segmentsOnLabelmap =
              labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap;
            // 1개의 labelmap에 여러 색상 segments hide 처리
            for (let i = 1; i < segmentsOnLabelmap.length; i++) {
              setters.toggleSegmentVisibility(element, segmentsOnLabelmap[i]);
            }
            dispatch(toggleLabelVisible(currentDicomIdx));
            cornerstone.updateImage(element);
          }
        }
        window.prevKeyCode = e.keyCode;
      }
      //segmentsOnLabelmap 탑색하여 현재 labelmap 색상 활성화 처리
      const { labelmap3D } = getters.labelmap2D(element);
      let segmentsOnLabelmap =
        labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap;
      if (segmentsOnLabelmap.length > 0) {
        let max = segmentsOnLabelmap.reduce(function (previous, current) {
          return previous > current ? previous : current;
        });
        setters.activeSegmentIndex(element, max);
        dispatch(updateBrushcolorIdx(max));
      }
      setters.activeLabelmapIndex(currActiveLabelIndex);
    };

    window.addEventListener('keydown', KeyDownHideToggle);
    return () => window.removeEventListener('keydown', KeyDownHideToggle);
  }, [currentDicomIdx, dispatch, label.labelindex]);

  return (
    <div>
      <ContextMenuTrigger id="context-menu" holdToDisplay={-1}>
        <CornerstoneViewport
          imageIds={dicomfiles}
          imageIdIndex={imageIdx}
          style={{ width: '100%', height: 'calc(100vh - 184px)' }}
        />
      </ContextMenuTrigger>
      {!buttonstate[4] &&
        !buttonstate[5] &&
        !buttonstate[6] &&
        !buttonstate[8] && (
          <ContextMenu id="context-menu">
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              sx={{ width: '260px' }}
            >
              <Grid item sx={{ width: '140px' }}>
                <MenuItem onClick={handleWindowing} data={{ ww: 400, wc: 60 }}>
                  CT Abdomen
                </MenuItem>
                <MenuItem onClick={handleWindowing} data={{ ww: 600, wc: 300 }}>
                  CT Angio
                </MenuItem>
                <MenuItem
                  onClick={handleWindowing}
                  data={{ ww: 1500, wc: 300 }}
                >
                  CT Brain
                </MenuItem>
                <MenuItem onClick={handleWindowing} data={{ ww: 80, wc: 40 }}>
                  CT Chest
                </MenuItem>
                <MenuItem
                  onClick={handleWindowing}
                  data={{ ww: 1500, wc: -400 }}
                >
                  CT Lungs
                </MenuItem>
                <MenuItem
                  onClick={handleWindowing}
                  data={{ ww: 2500, wc: 300 }}
                >
                  CT Bone
                </MenuItem>
                <MenuItem onClick={handleWindowing} data={{ ww: 400, wc: 400 }}>
                  micro CT mouse
                </MenuItem>
              </Grid>
              <Divider orientation="vertical" sx={{ height: '210px' }} />
              <Grid item xs={4}>
                <Grid>
                  <Typography variant="subtitle1">Windowing</Typography>
                  <TextField
                    type="number"
                    size="small"
                    id="ww"
                    label="WW"
                    variant="outlined"
                    sx={{ width: '100%', padding: '12px 0px 0px 0px' }}
                    onChange={(e) => {
                      setWw(Number(e.target.value));
                    }}
                  />
                </Grid>
                <Grid>
                  <TextField
                    type="number"
                    size="small"
                    id="wc"
                    label="WC"
                    variant="outlined"
                    sx={{ width: '100%', padding: '8px 0px 0px 0px' }}
                    onChange={(e) => {
                      setWc(Number(e.target.value));
                    }}
                  />
                </Grid>
                <Grid sx={{ padding: '8px 0px 0px 0px' }}>
                  <Button
                    variant="contained"
                    onClick={(e) => handleWindowing(e, { ww: ww, wc: wc })}
                    sx={{ width: '100%' }}
                  >
                    apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </ContextMenu>
        )}
    </div>
  );
};

export default DicomViewer;
