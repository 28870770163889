import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSeries,
  addSeries,
  addSeriesDcmName,
  resetSeries,
  // sortSeries,
} from '../../../_reducers/seriesReducer';
import {
  selectDicomFiles,
  setImageRowCol,
} from '../../../_reducers/dicomReducer';
import { resetLabel, addLabel } from '../../../_reducers/labelReducer';

import { setSeriesidx } from '../../../_reducers/vfssReducer';
import {
  Box,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import BtnGroup from '../component/BtnGroup/BtnGroup';
import RightSide from '../component/RightSide/RightSide';

import cornerstone from 'cornerstone-core';
import { PROJECT_SERVER } from '../../../Config';
import axios from 'axios';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import VFSSDicomViewer from '../component/DicomViewer/VFSSDicomViewer';

import './VFSSLabeling.css';
import ProgressBar from '../../../components/progressBar/ProgressBar';
import { io } from 'socket.io-client';
const socket = io.connect(`${process.env.REACT_APP_EXPRESS_URL}/VFSSconvert`);

// Image Loader
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneWebImageLoader.configure({
  beforeSend: function (xhr) {
    // Add custom headers here (e.g. auth tokens)
    //xhr.setRequestHeader('x-auth-token', 'my auth token');
  },
});

const VFSSLabeling = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const series = useSelector((state) => state.series);
  const dicomfiles = useSelector((state) => state.dicom.imgIds);
  const selectedSeriesIdx = useSelector((state) => state.vfss.seriesIdx);

  const [progress, setProgress] = useState(0);
  const [dicomTags, setDicomTags] = useState();
  const [imageLoad, setImageLoad] = useState(false);
  const [vfssPercent, setVFSSPercent] = useState(0);

  function update(completed, total) {
    setProgress((completed / total) * 100);
  }

  //series onClick func
  function selectSeries(dicomFiles, dicomnames, selectedIdx) {
    dispatch(resetLabel());
    dispatch(addLabel(dicomFiles.length));

    dispatch(selectDicomFiles(dicomFiles, dicomnames));
    dispatch(setSeriesidx(selectedIdx));
  }

  function timer(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }

  useEffect(() => {
    // 제일 처음 series의 imgids setting
    let mergeSeries = [];
    let fileNames = [];
    if (series.length > 0) {
      mergeSeries = series[0].seriesImgIds;
      fileNames = series[0].seriesDcmName;
      dispatch(selectDicomFiles(mergeSeries, fileNames));
      // dispatch(addLabel(mergeSeries.length));
    }
  }, [dispatch, series]);

  // useEffect(() => {
  //   let element = document.querySelector('.viewport-element');
  //   if (element !== null) setViewRightSide(true);
  // }, [dicomfiles]);

  useEffect(() => {
    socket.emit('login', { uid: searchParams.get('pjId') });
    socket.on('percent', ({ msg }) => {
      setVFSSPercent(msg);
    });
    socket.emit('convert', {
      projectId: searchParams.get('pjId'),
      stUid: searchParams.get('stUid'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vfssPercent === 100) {
      function progressPromise(promises, tickCallback, vfssChkData) {
        let len = promises.length;
        let progress = 0;

        function tick(promise) {
          promise.then(function () {
            progress++;
            tickCallback(progress, len);
          });
          return promise;
        }

        return Promise.all(promises.map(tick))
          .then(function (loadedImages) {
            let loadedCnt = vfssChkData.cnt;
            let i = 0;
            let seriesNumArr = [];
            let series = [];
            while (i < loadedCnt) {
              //현재 loaded 된 imageId의 series를 cfssChkData에서 검색하여 추출
              // objectUID
              let sopUID = loadedImages[i].imageId
                .match('(?=objectUID=).*(?=&pjId=)')[0]
                .split('=')[1];
              // imgidx
              let instanceNum = loadedImages[i].imageId
                .match('(?=imgidx=).*')[0]
                .split('=')[1];
              let idx = vfssChkData.dicomTags['00080018'].indexOf(sopUID);

              // dicom tag parsing
              // access a string element
              // x0020000e : Series Instance UID
              // x0008103e  : Series Description Attribute
              // x00200013 : InstanceNumber
              let seriesNum = vfssChkData.dicomTags['00200011'][idx];
              let seriesDesc = vfssChkData.dicomTags['00081030'][idx];

              // SeriesNumArr : 선택한 file들의 series 정보 저장 변수
              // 새로운 series 가 추가될때마다 해당 배열에 저장하여 관리
              let find = seriesNumArr.find((num) => num === seriesNum);
              let findIdx = seriesNumArr.findIndex((num) => num === seriesNum);
              // console.log(find, findIdx);

              let imgOrientation = '';
              // series 처음 등록 일때 분기처리
              if (find === undefined) {
                seriesNumArr.push(seriesNum);
                series = {
                  seriesNumber: seriesNum,
                  seriesDescription: seriesDesc,
                  seriesOrientation: [],
                  seriesOrientMode: 0,
                  seriesImgIds: [loadedImages[i].imageId],
                  seriesDcmName: [vfssChkData.fileNames[idx][instanceNum]],
                  seriesInstanceNum: [instanceNum],
                };
                // addSeries : redux이용하여 series 저장 공간 생성
                // serSeries : seriesIdx 값을 참조하여 series 양식대로 저장
                dispatch(addSeries());

                dispatch(setSeries(idx, series));
                window.thumnail.push(loadedImages[i].imageId);
              }
              // 이전 series number와 같으면 ImgIds만 추가
              // param : name -> window dicomIds 객체로 부터 ImgIds 검색하여 추출하기 위한 param
              else if (findIdx !== -1) {
                dispatch(
                  addSeriesDcmName(
                    findIdx,
                    vfssChkData.fileNames[idx][instanceNum],
                    loadedImages[i].imageId,
                    instanceNum,
                    imgOrientation,
                  ),
                );
              }
              i++;
            }
          })
          .then(function () {
            // dispatch(sortSeries());
            // dispatch(modeSeries());
            // setFileload(true);
          });
      }

      axios
        .get(
          `${PROJECT_SERVER}/vfsscheck?img=${searchParams.get(
            'stUid',
          )}&pjId=${searchParams.get('pjId')}`,
        )
        .then(async (res) => {
          // db로 부터 dicom file load를 필요한 dicom count check
          setDicomTags(res.data.dicomTags);
          let seriesCnt = res.data.fileNames.length;

          let dicomTags = res.data.dicomTags;
          window.thumnail = [];
          window.dicomFileNames = [];
          let promiseArr = [];

          //series reducer 초기화
          dispatch(resetSeries());

          // dicom image row,col set
          dispatch(
            setImageRowCol(dicomTags['00280010'][0], dicomTags['00280011'][0]),
          );

          if (seriesCnt > 0) {
            for (let i = 0; i < seriesCnt; i++) {
              let dicomFrame = res.data.fileNames[i].length;
              //file 개수만큼 반복처리
              for (let n = 0; n < dicomFrame; n++) {
                let studyUID = dicomTags['0020000D'][i];
                let seriesUID = dicomTags['0020000E'][i];
                let objectUID = dicomTags['00080018'][i];

                let imgIds = `${
                  process.env.REACT_APP_FASTIFY_URL
                }/wado?requestType=WADO&studyUID=${studyUID}&seriesUID=${seriesUID}&objectUID=${objectUID}&pjId=${searchParams.get(
                  'pjId',
                )}&images=true&imgidx=${n}`;
                promiseArr.push(cornerstone.loadImage(imgIds));
                // dicom image load 지연 => image개수 많을경우 request error
                await timer(10);
              }

              window.dicomFileNames = res.data.fileNames[i];
            }
            progressPromise(promiseArr, update, res.data).then(function () {
              setProgress(100);
              setImageLoad(true);
              dispatch(setSeriesidx(0));
              //total label initialize
              dispatch(addLabel(res.data.cnt));
            });
          } else {
            setImageLoad(false);
            dispatch(selectDicomFiles([]));
            dispatch(setSeriesidx(''));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vfssPercent]);

  return (
    <div style={{ backgroundColor: 'gray' }}>
      {vfssPercent !== 100 && (
        <Box>
          <ProgressBar color={'primary'} value={vfssPercent} />
        </Box>
      )}
      {dicomfiles.length > 0 && (
        <div>
          {progress === 100 && <BtnGroup />}
          <Grid container columns={13}>
            <Grid item xs={2}>
              <ImageList
                rowHeight={160}
                cols={1}
                gap={8}
                sx={{ margin: '8px 8px 0px 8px' }}
              >
                {imageLoad &&
                  series.map((item, idx) => (
                    <ImageListItem
                      key={idx}
                      onClick={() => {
                        selectSeries(
                          item.seriesImgIds,
                          item.seriesDcmName,
                          idx,
                        );
                      }}
                    >
                      <Box
                        className="ImageListBox"
                        border={selectedSeriesIdx === idx ? 4 : 2}
                        borderColor={
                          selectedSeriesIdx === idx ? '#76ff03' : 'black'
                        }
                      >
                        <img
                          src={`${item.seriesImgIds[0]}&w=248&fit=crop&auto=format`}
                          srcSet={`${item.seriesImgIds[0]}&w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.seriesImgIds[0]}
                          loading="lazy"
                          style={{
                            height: '100%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                          }}
                        />
                        {dicomTags && (
                          <ImageListItemBar
                            title={`Ser '${item.seriesNumber}'`}
                            subtitle={`${item.seriesDescription}`}
                            sx={{
                              margin:
                                selectedSeriesIdx === idx
                                  ? '0px 4px 4px 4px'
                                  : '0px 2px 2px 2px',
                            }}
                          />
                        )}
                      </Box>
                    </ImageListItem>
                  ))}
              </ImageList>
            </Grid>

            <Grid item xs={7}>
              <VFSSDicomViewer dicomTags={dicomTags} />
            </Grid>
            <Grid item xs={4}>
              <RightSide />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default VFSSLabeling;
