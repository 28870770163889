import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  increaseImageIdx,
  decreaseImageIdx,
  setImageIdx,
} from '../../../../_reducers/dicomReducer';
import { Grid, Typography, IconButton, Slider, Tooltip } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import cornerstoneTools from 'cornerstone-tools';
import axios from 'axios';
import { dataURLtoFile } from '../../../../utils/Utils';

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const { getters, state } = cornerstoneTools.getModule('segmentation');

const DicomSelector = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const dicomCnt = useSelector((state) => state.dicom.imgIds.length);
  const currentDicomRow = useSelector((state) => state.dicom.imageRow);
  const currentDicomCol = useSelector((state) => state.dicom.imageCol);
  const label = useSelector((state) => state.label);
  const reduxDicom = useSelector((state) => state.dicom);
  const userId = useSelector((state) => state.user.userData._id);
  const currentDicomName = useSelector(
    (state) => state.dicom.fileNames[currentDicomIdx],
  );

  const [fileselect, setFileSelect] = useState(true);
  const [imgIdx, setImgIdx] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const SaveLabel = (
    label,
    currentDicomIdx,
    currentDicomCol,
    currentDicomRow,
  ) => {
    let element = document.querySelector('.viewport-element');
    const {
      labelmaps3D, // The `Labelmap2D` for this imageId.
    } = getters.labelmaps3D(element);

    let labelLen = label[currentDicomIdx].labelindex.length;

    //sever에 download한 label save
    let formData = new FormData();
    let labelnames = [];
    let i = 0;

    //label list가 존재할때
    if (label[currentDicomIdx].currentlabelindex > -2) {
      // labelname Empty or labelname Equal Check
      for (let k = 0; k < label[currentDicomIdx].labelname.length; k++) {
        if (label[currentDicomIdx].labelname[k].length === 0) {
          enqueueSnackbar(
            '이름이 공백인 Label이 존재합니다. 저장할 수 없습니다.',
            {
              variant: 'warning',
              autoHideDuration: 1200,
            },
          );

          return;
        }
      }
      let labelUniqueLen = Array.from(
        new Set(label[currentDicomIdx].labelname),
      ).length;
      if (labelUniqueLen !== labelLen) {
        enqueueSnackbar(
          '이름이 동일한 Label이 존재합니다. 저장할 수 없습니다.',
          {
            variant: 'warning',
            autoHideDuration: 1200,
          },
        );

        return;
      } else {
        //반복하여 labellist 탐색 후 download 처리
        while (i < labelLen) {
          // labellist 중 labelmap이 그려진 경우에만 동작 나머지는 건너뜀
          if (
            labelmaps3D[label[currentDicomIdx].labelindex[i]].labelmaps2D
              .length > 0
          ) {
            //current image convert to png
            const canvas = document.createElement('canvas');

            //vfss 일때 label 크기설정을 위한 예외처리
            let width =
              currentDicomCol === 0 || currentDicomCol === undefined
                ? 512
                : currentDicomCol;
            let height =
              currentDicomRow === 0 || currentDicomRow === undefined
                ? 512
                : currentDicomRow;

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            const imgData = ctx.createImageData(width, height);
            const len = imgData.data.length; // width * height * 4;

            let saveLabelmap =
              labelmaps3D[label[currentDicomIdx].labelindex[i]].labelmaps2D[
                currentDicomIdx
              ].pixelData;
            const colorLut = state.colorLutTables[0];
            for (let j = 0, k = 0; j < len; j += 4, k++) {
              //data[i] ->r
              //data[i+1] ->g
              //data[i+2] ->b
              //data[i+3] ->a
              let color = saveLabelmap[k];
              if (color > 0) {
                imgData.data[j + 0] = colorLut[color][0];
                imgData.data[j + 1] = colorLut[color][1];
                imgData.data[j + 2] = colorLut[color][2];
              } else {
                imgData.data[j + 0] =
                  imgData.data[j + 1] =
                  imgData.data[j + 2] =
                    0;
              }

              imgData.data[j + 3] = 255;
            }
            ctx.putImageData(imgData, 0, 0);

            //Get data from canvas
            let img_b64_url = canvas.toDataURL();
            let file = dataURLtoFile(img_b64_url, 'label.png');

            formData.append(label[currentDicomIdx].labelname[i], file);
            labelnames.push(label[currentDicomIdx].labelname[i]);
          }
          i++;
        }
        formData.append('labelNames', labelnames);
      }
      formData.append('userId', userId);
      formData.append('objId', searchParams.get('pjId'));
      formData.append('dicomName', currentDicomName);
      formData.append(
        'sopUid',
        reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
      );
      formData.append(
        'studyUid',
        reduxDicom.imgIds[currentDicomIdx].split('&')[1].split('=')[1],
      );
      formData.append('process', searchParams.get('process'));

      axios.post(`${process.env.REACT_APP_FLASK_URL}/SaveLabel`, formData, {
        'Content-Type': 'multipart/form-data',
      });
    }
  };

  //dicom file 미 선택 시 selector 및 개수 현황 비활성화
  useEffect(() => {
    if (dicomCnt > 0) {
      // dispatch(addLabel(dicomCnt));
      setFileSelect(false);
    } else {
      // dispatch(addLabel(0));
      setFileSelect(true);
    }
  }, [dicomCnt]);

  useEffect(() => {
    setImgIdx(currentDicomIdx + 1);
  }, [currentDicomIdx]);

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{
          height: '48px',
          backgroundColor: '#bfbfbf',
        }}
      >
        <Grid item container justifyContent="center" xs={2}>
          <IconButton
            onClick={() => {
              // if (location.pathname === '/labeling/main/VFSS') {
              //   SaveLabel(
              //     label,
              //     currentDicomIdx,
              //     currentDicomCol,
              //     currentDicomRow,
              //   );
              // }
              dispatch(decreaseImageIdx());
            }}
            disabled={fileselect}
            size="large"
          >
            <ArrowBackIos />
          </IconButton>
        </Grid>
        <Grid item container justifyContent="center" xs={8}>
          <Slider
            value={imgIdx}
            min={1}
            max={dicomCnt}
            valueLabelDisplay="auto"
            components={{
              ValueLabel: ValueLabelComponent,
            }}
            onChange={(e, value) => {
              setImgIdx(value);
            }}
            onChangeCommitted={(e, value) => {
              setImgIdx(value);
              dispatch(setImageIdx(value - 1));
            }}
            sx={{
              marginTop: '-4px',
              marginBottom: '-12px',
            }}
          />
          <Typography variant="h5">
            {dicomCnt > 0 && `${currentDicomIdx + 1} / ${dicomCnt}`}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" xs={2}>
          <IconButton
            onClick={() => {
              // if (location.pathname === '/labeling/main/VFSS') {
              //   SaveLabel(
              //     label,
              //     currentDicomIdx,
              //     currentDicomCol,
              //     currentDicomRow,
              //   );
              // }
              dispatch(increaseImageIdx());
            }}
            disabled={fileselect}
            size="large"
          >
            <ArrowForwardIos />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default DicomSelector;
