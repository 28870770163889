import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise';
import { composeWithDevTools } from '@redux-devtools/extension';

import { userReducer } from './userReducer';
import { buttonReducer } from './buttonReducer';
import { segmentReducer } from './segmentReducer';
import { dicomReducer } from './dicomReducer';
import { labelReducer } from './labelReducer';
import { grabcutReducer } from './grabcutReducer';
import { seriesReducer } from './seriesReducer';
import { stepperReducer } from './stepperReducer';
import { vfssReducer } from './vfssReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const rootReducer = combineReducers({
  user: userReducer,
  button: buttonReducer,
  seg: segmentReducer,
  dicom: dicomReducer,
  label: labelReducer,
  grabcut: grabcutReducer,
  series: seriesReducer,
  stepper: stepperReducer,
  vfss: vfssReducer,
});
const perReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk, promiseMiddleware];
// 미들웨어를 하나씩 풀어서 넣어준다.
const enhancer = composeWithDevTools(compose(applyMiddleware(...middlewares)));

// 리듀서+optional들을 넣어서 스토어를 만들어준다.
const store = createStore(perReducer, enhancer);

export default store;
