/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../_reducers/userReducer';
import { useSelector, useDispatch } from 'react-redux';

export default function (SpecificComponent, option, adminRoute = null) {
  function AuthenticationCheck() {
    let user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      //To know my current status, send Auth request
      dispatch(auth()).then((response) => {
        //Not Loggined in Status
        if (!response.payload.isAuth) {
          if (option) {
            navigate('/login');
          }
          //Loggined in Status
        } else {
          //supposed to be Admin page, but not admin person wants to go insid
          if (adminRoute && !response.payload.isAdmin) {
            navigate('/project');
          }
          //Logged in Status, but Try to go into log in page
          else {
            if (option === false) {
              navigate('/project');
            }
          }
        }
      });
    }, []);
    return <SpecificComponent user={user} />;
  }
  return <AuthenticationCheck />;
}
