import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { yellow } from '@mui/material/colors';

import { updatePredictLabelStatus } from '../../../../_reducers/vfssReducer';
import {
  selectCurrLabelIndex,
  toggleLabelVisibleAll,
  addLabelIndex,
  addLabelName,
  addSlicPos,
  clearLabel,
} from '../../../../_reducers/labelReducer';
import withStyles from '@mui/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import cornerstone from 'cornerstone-core';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';

import Dexie from 'dexie';

const db = new Dexie('VFSS');
db.version(1).stores({
  labels: '[dicomName+labelName]', // Primary key and indexed props
});
db.version(2).stores({
  predlabels: '[dicomName+labelName]', // Primary key and indexed props
});

const { getters, setters } = cornerstoneTools.getModule('segmentation');

const StyledButton = withStyles({
  root: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: '#58a5f0',
    borderRadius: 3,
    border: 0,
    color: 'black',
    '&:hover': {
      backgroundColor: '#004c8c',
      color: 'white',
    },
    height: '28px',
    minWidth: '28px',
    padding: '0px',
    margin: '8px 4px',
    boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const useStyles = makeStyles(() => ({
  root: {},
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: 4,
    position: 'relative',
  },
  buttonProgress: {
    display: 'flex',
    alignItems: 'center',
    color: yellow[700],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -18,
  },
  icon: {
    width: '28px',
    height: '28px',
    // padding: '2px',
    '&:hover': {
      filter:
        'invert(100%) sepia(100%) saturate(0%) hue-rotate(252deg) brightness(102%) contrast(102%)',
    },
  },
}));

const VFSSBtn = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const dicomCnt = useSelector((state) => state.dicom.imgIds.length);
  const dicomfiles = useSelector((state) => state.dicom.imgIds);
  const currentDicomName = useSelector((state) => state.dicom.fileNames);
  const reduxDicom = useSelector((state) => state.dicom);
  const segmentsHideStatus = useSelector((state) => state.seg.segmentsHide);
  const lb = useSelector((state) => state.label);

  //material ui snack bar
  const { enqueueSnackbar } = useSnackbar();

  const [predictBtnDisable, SetPredictBtnDisable] = useState(true);

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const userId = useSelector((state) => state.user.userData._id);

  const timer = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  //vfss predict btn
  function Predict() {
    let formData = new FormData();
    formData.append('objId', searchParams.get('pjId'));

    let sopUids = reduxDicom.imgIds[currentDicomIdx]
      .split('&')[3]
      .split('=')[1];
    formData.append('stUid', searchParams.get('stUid'));
    formData.append('sopUid', sopUids);
    formData.append('userId', userId);
    formData.append('imageRow', reduxDicom.imageRow);
    formData.append('imageCol', reduxDicom.imageCol);
    formData.append('dicomName', currentDicomName);
    formData.append('exeType', 'manual');

    //vfss progress circle
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 50 * currentDicomName.length);
    }
    axios
      .post(`${process.env.REACT_APP_FLASK_URL}/VFSS`, formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then((res) => {
        let urlSearchParamsObject = new URLSearchParams(dicomfiles[0]);
        let data = {
          pjId: searchParams.get('pjId'),
          studyUid: searchParams.get('stUid'),
          sopUid: urlSearchParamsObject.get('objectUID'),
        };
        // vfss predict result 요청
        axios
          .post(
            `${process.env.REACT_APP_EXPRESS_URL}/api/vfss/predictresult`,
            data,
            {
              'Content-Type': 'multipart/form-data',
            },
          )
          .then((res) => {
            let predictResult = res.data.predictResult;

            // predict label 결과 status 갱신
            dispatch(updatePredictLabelStatus(predictResult));
            afterVFSSPredict();
          });
      });
  }

  function getIndexedDBLabels(dicomName, num, element) {
    let keyList = [
      'Oral',
      'Pharyngeal',
      'Esophageal',
      'Penetration',
      'Aspiration',
    ];

    for (let i = 0; i < keyList.length; i++) {
      db.labels
        .get({
          dicomName: dicomName,
          labelName: keyList[i],
        })
        .then((docs) => {
          if (docs) {
            // label index 1~5 : predict label에서 index 사용
            // index 6~ : 일반 label 에서 index 사용
            let checkIdx =
              lb[num].labelindex.length === 1
                ? 6
                : lb[num].labelindex.length + 6;

            const { labelmap3D } = getters.labelmap2D(element);
            // labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap = [
            //   1, 2, 8, 9, 13, 16, 25,
            // ];
            let temp = labelmap3D.labelmaps2D[0];
            // setters.activeLabelmapIndex(element, i);
            let Idx = currentDicomName.findIndex(
              (name) => name === docs.dicomName,
            );
            // console.log('label -> checkIdx: ' + checkIdx + ' Idx: ' + Idx, i);
            // idx 존재 할때만 처리
            if (Idx !== -1) {
              if (currentDicomName[num] === docs.dicomName) {
                // label add
                setters.activeLabelmapIndex(element, checkIdx);

                dispatch(addLabelIndex(checkIdx, Idx));
                dispatch(addLabelName(docs.labelName, Idx));
                dispatch(addSlicPos(Idx));

                let binary_string = window.atob(docs.labelImg);
                let len = binary_string.length;
                let bytes = new Uint8Array(len);
                for (let j = 0; j < len; j++) {
                  bytes[j] = binary_string.charCodeAt(j);
                }
                let seg = new Uint16Array(bytes.buffer);
                // predict boundingbox 좌표로 array buffer 생성
                // let pos = [0.354651, 0.209812, 0.0496829, 0.039666];
                // let newSeg = new Uint16Array(drawBboxSeg(pos, 0, 946, 958));
                // cornerstone labelmap에 위에서 처리한 이미지 배열
                // getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D = [];
                getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[
                  Idx
                ] = temp;
                getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[
                  Idx
                ] = {
                  labelname: docs.labelName,
                  dicomname: docs.dicomName,
                };
                getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[
                  Idx
                ].pixelData = seg;
                // getters.labelmaps3D(element).labelmaps3D[num + 1].segmentsHidden =
                //   segmentsHideStatus ? segHideOnArr : segHideOffArr;
                //label hide 초기화
                // getters.labelmaps3D(element).labelmaps3D[
                //   checkIdx
                // ].segmentsHidden = [];
                //현재 label 활성화 color 지정
                let color = Array.from(new Set(seg));
                getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D[
                  Idx
                ].segmentsOnLabelmap = color;
                cornerstone.updateImage(element);
                // checkIdx = checkIdx + 1;
              }
              //label hide status
              if (segmentsHideStatus) dispatch(toggleLabelVisibleAll(i));
              // update select labelindex
              dispatch(selectCurrLabelIndex(0, num));
            }
          }
        });
    }
  }

  function getIndexedDBPredictLabels(dicomName, num, element) {
    let keyList = [
      'PredOral',
      'PredPharyngeal',
      'PredEsophageal',
      'PredPenetration',
      'PredAspiration',
    ];

    for (let i = 0; i < keyList.length; i++) {
      db.predlabels
        .get({
          dicomName: dicomName,
          labelName: keyList[i],
        })
        .then((docs) => {
          if (docs !== undefined) {
            // console.log('predict Label Load completed');
            // let checkIdx = (Idx + 1) * 10 + 1;
            // const { labelmap3D } = getters.labelmap2D(element);
            // labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap = [
            //   1, 2, 8, 9, 13, 16, 25,
            // ];
            // let temp = labelmap3D.labelmaps2D[0];
            // setters.activeLabelmapIndex(element, i);
            let Idx = currentDicomName.findIndex(
              (name) => name === docs.dicomName,
            );

            // idx 존재 할때만 처리
            if (Idx !== -1) {
              if (currentDicomName[num] === docs.dicomName) {
                let predLabelIdx = i + 1;
                // label add
                setters.activeLabelmapIndex(element, predLabelIdx);

                dispatch(addLabelIndex(predLabelIdx, Idx));
                dispatch(addLabelName(docs.labelName, Idx));
                dispatch(addSlicPos(Idx));
                let binary_string = window.atob(docs.labelImg);
                let len = binary_string.length;
                let bytes = new Uint8Array(len);
                for (let j = 0; j < len; j++) {
                  bytes[j] = binary_string.charCodeAt(j);
                }
                let seg = new Uint16Array(bytes.buffer);
                // predict boundingbox 좌표로 array buffer 생성
                // let pos = [0.354651, 0.209812, 0.0496829, 0.039666];
                // let newSeg = new Uint16Array(drawBboxSeg(pos, 0, 946, 958));
                // cornerstone labelmap에 위에서 처리한 이미지 배열
                // getters.labelmaps3D(element).labelmaps3D[checkIdx].labelmaps2D =
                //   [];
                // getters.labelmaps3D(element).labelmaps3D[num + 1].labelmaps2D[Idx] =
                //   temp;
                getters.labelmaps3D(element).labelmaps3D[
                  predLabelIdx
                ].labelmaps2D[Idx] = {
                  labelname: docs.labelName,
                };
                getters.labelmaps3D(element).labelmaps3D[
                  predLabelIdx
                ].labelmaps2D[Idx].pixelData = seg;
                // getters.labelmaps3D(element).labelmaps3D[num+1].segmentsHidden =
                //   segmentsHideStatus ? segHideOnArr : segHideOffArr;
                //label hide 초기화
                // getters.labelmaps3D(element).labelmaps3D[
                //   checkIdx
                // ].segmentsHidden = [];
                //현재 label 활성화 color 지정
                let color = Array.from(new Set(seg));
                getters.labelmaps3D(element).labelmaps3D[
                  predLabelIdx
                ].labelmaps2D[Idx].segmentsOnLabelmap = color;
                cornerstone.updateImage(element);
              }
              //label hide status
              if (segmentsHideStatus) dispatch(toggleLabelVisibleAll(num));
              // update select labelindex
              dispatch(selectCurrLabelIndex(0, num));
            }
          }
        });
    }
  }

  //after vfss predict - indexedDB 갱신
  function afterVFSSPredict() {
    // predict progress 초기화
    setLoading(false);

    let payload = {
      projectId: searchParams.get('pjId'),
      sopUid: reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
      studyUid: searchParams.get('stUid'),
    };
    axios
      .post(`${process.env.REACT_APP_FLASK_URL}/VFSSPredictLabel`, payload, {
        'Content-Type': 'multipart/form-data',
      })
      .then((res) => {
        db.predlabels.clear();
        if (res.data !== 'none') {
          let len = res.data.dicomName.length;
          let promiseArr = [];
          for (let i = 0; i < len; i++) {
            // console.log(i);
            // Add VFSS labels
            promiseArr.push(
              db.predlabels.put({
                dicomName: res.data.dicomName[i],
                labelColor: res.data.labelColor[i],
                labelImg: res.data.labelImg[i],
                labelName: res.data.labelName[i],
              }),
            );
          }
          Promise.all(promiseArr).then(() => {
            dispatch(clearLabel(currentDicomIdx));
            let element = document.querySelector('.viewport-element');
            getIndexedDBLabels(
              currentDicomName[currentDicomIdx],
              currentDicomIdx,
              element,
            );
            getIndexedDBPredictLabels(
              currentDicomName[currentDicomIdx],
              currentDicomIdx,
              element,
            );
            handleClickVariant('Predictlabel Load Completed', 'success');
          });
        }
      }) //error
      .catch((error) => {
        console.log(error);
      });
  }

  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };

  useEffect(() => {
    if (dicomCnt > 0) {
      SetPredictBtnDisable(false);
    } else {
      SetPredictBtnDisable(true);
    }
  }, [dicomCnt, SetPredictBtnDisable]);

  return (
    <>
      <StyledButton disabled={predictBtnDisable || loading} onClick={Predict}>
        <img
          className={classes.icon}
          src={process.env.PUBLIC_URL + '/imgs/icons/vfss.png'}
          alt="swallow"
        />
        {loading && (
          <CircularProgress
            className={classes.buttonProgress}
            size={36}
            thickness={6}
          />
        )}
      </StyledButton>
    </>
  );
};

export default VFSSBtn;
