// ducks 패턴: 액션타입, 액션크리에이터, 리듀서가 모두 동일한 화일에 존재

// action type: 스트링, 모듈명/액션타입으로 표시
export const SELECT_DICOM = 'BtnGroup/SELECT_DICOM';
export const SET_IMAGEIDX = 'BtnGroup/SET_IMAGEIDX';
export const INCREASE_IMAGEIDX = 'BtnGroup/INCREASE_IMAGEIDX';
export const DECREASE_IMAGEIDX = 'BtnGroup/DECREASE_IMAGEIDX';
export const RESET_DICOM = 'BtnGroup/RESET_DICOM';
export const SET_SEG = 'BtnGroup/SET_SEG';
export const SET_COMP = 'BtnGroup/SET_COMP';
export const SET_SIG = 'BtnGroup/SET_SIG';
export const SET_IMAGEROWCOL = 'BtnGroup/SET_IMAGEROWCOL';

// action creator: 액션을 동적으로 생성하는 펑션
export const selectDicomFiles = (dicomfiles, dicomnames) => ({
  type: SELECT_DICOM,
  payload: { dicomfiles, dicomnames },
});

export const setImageIdx = (imageIdx) => ({
  type: SET_IMAGEIDX,
  payload: imageIdx,
});

export const increaseImageIdx = (imageIdx) => ({
  type: INCREASE_IMAGEIDX,
  payload: imageIdx,
});

export const decreaseImageIdx = (imageIdx) => ({
  type: DECREASE_IMAGEIDX,
  payload: imageIdx,
});

export const resetDicom = () => ({
  type: RESET_DICOM,
  payload: '',
});
export const setSeg = (segValue) => ({
  type: SET_SEG,
  payload: segValue,
});
export const setComp = (compValue) => ({
  type: SET_COMP,
  payload: compValue,
});
export const setSig = (sigValue) => ({
  type: SET_SIG,
  payload: sigValue,
});
export const setImageRowCol = (row, col) => ({
  type: SET_IMAGEROWCOL,
  payload: { row, col },
});

const initialState = {
  imgIds: [],
  fileNames: [],
  currentImageIdIndex: 0,
  seg: 800,
  comp: 20,
  sig: 2,
  imageRow: 0,
  imageCol: 0,
};

export const dicomReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_DICOM:
      if (action.payload.dicomfiles.length > 0) {
        return {
          ...state,
          imgIds: action.payload.dicomfiles,
          fileNames: action.payload.dicomnames,
          currentImageIdIndex: 0,
        };
      }
      return state;
    case SET_IMAGEIDX:
      return { ...state, currentImageIdIndex: action.payload };
    case INCREASE_IMAGEIDX:
      if (state.imgIds.length - 1 >= state.currentImageIdIndex + 1) {
        return { ...state, currentImageIdIndex: state.currentImageIdIndex + 1 };
      } else {
        return { ...state, currentImageIdIndex: 0 };
      }

    case DECREASE_IMAGEIDX:
      if (0 <= state.currentImageIdIndex - 1) {
        return { ...state, currentImageIdIndex: state.currentImageIdIndex - 1 };
      } else {
        return { ...state, currentImageIdIndex: state.imgIds.length - 1 };
      }

    case RESET_DICOM:
      state = {
        imgIds: [],
        fileNames: [],
        currentImageIdIndex: 0,
        seg: 800,
        comp: 20,
        sig: 2,
      };
      return state;
    case SET_SEG:
      return { ...state, seg: action.payload };
    case SET_COMP:
      return { ...state, comp: action.payload };
    case SET_SIG:
      return { ...state, sig: action.payload };
    case SET_IMAGEROWCOL:
      state.imageRow = action.payload.row;
      state.imageCol = action.payload.col;
      return state;
    default:
      return state;
  }
};
