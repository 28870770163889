import React from 'react';
import OneClickBtn from './OneClickBtn';
import ToggleBtn from './ToggleBtn';

import { Grid } from '@mui/material';

const BtnGroup = () => {
  return (
    <div>
      <Grid
        container
        style={{
          width: '100%',
          height: '48px',
          backgroundColor: '#7F8C8D',
        }}
      >
        <Grid item>
          <OneClickBtn />
        </Grid>
        <Grid item>
          <ToggleBtn />
        </Grid>
      </Grid>
    </div>
  );
};

export default BtnGroup;
