import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Slider, Input, Button } from '@mui/material';
import { setSeg, setComp, setSig } from '../../../../_reducers/dicomReducer';
import { useSnackbar } from 'notistack';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

import axios from 'axios';
//slic slider mark
const slicMark1 = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 3000,
    label: '3000',
  },
];

const slicMark2 = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 30,
    label: '30',
  },
];
const slicMark3 = [
  {
    value: 0.0,
    label: '0.0',
  },
  {
    value: 10.0,
    label: '10.0',
  },
];

const SlicContextmenu = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const slicParam = useSelector((state) => state.dicom);
  const seg = useSelector((state) => state.dicom.seg);
  const comp = useSelector((state) => state.dicom.comp);
  const sig = useSelector((state) => state.dicom.sig);
  const userId = useSelector((state) => state.user.userData._id);
  const currentDicomName = useSelector(
    (state) => state.dicom.fileNames[currentDicomIdx],
  );
  const [segValue, setSegValue] = useState(seg);
  const [compValue, setCompValue] = useState(comp);
  const [sigValue, setSigValue] = useState(sig);

  const btnState = useSelector(
    (state) => state.button,
    () => {},
  );
  const { setters } = cornerstoneTools.getModule('segmentation');
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };

  //Slider change
  const handleSliderChange = (name) => (event, newValue) => {
    switch (name) {
      case 'segVal':
        setSegValue(newValue);
        dispatch(setSeg(Number(newValue)));
        break;
      case 'compVal':
        setCompValue(newValue);
        dispatch(setComp(Number(newValue)));
        break;
      case 'sigVal':
        setSigValue(newValue);
        dispatch(setSig(Number(newValue)));
        break;
      default:
        break;
    }
  };
  // input change handle
  const handleInputSegChange = (event) => {
    setSegValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleInputCompChange = (event) => {
    setCompValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleInputSigChange = (event) => {
    setSigValue(event.target.value === '' ? '' : Number(event.target.value));
  };
  const handleBlurSeg = () => {
    if (segValue < 0) {
      setSegValue(0);
    } else if (segValue > 3000) {
      setSegValue(3000);
    }
    dispatch(setSeg(segValue));
  };
  const handleBlurComp = () => {
    if (compValue < 0) {
      setCompValue(0);
    } else if (compValue > 30) {
      setCompValue(30);
    }
    dispatch(setComp(compValue));
  };
  const handleBlurSig = () => {
    if (sigValue < 0) {
      setSigValue(0);
    } else if (sigValue > 10) {
      setSigValue(10);
    }
    dispatch(setSig(sigValue));
  };

  useEffect(() => {
    setSegValue(seg);
    setCompValue(comp);
    setSigValue(sig);
  }, [seg, comp, sig]);

  function slicUpdate() {
    let element = document.querySelector('.viewport-element');
    if (btnState[8]) {
      setters.deleteSegment(element, 203, 0);

      let voi = cornerstone.getViewport(element).voi;
      let formData = new FormData();
      formData.append('seg', seg);
      formData.append('comp', comp);
      formData.append('sig', sig);
      formData.append('voi', JSON.stringify(voi));
      formData.append('userId', userId);
      formData.append('objId', searchParams.get('pjId'));
      formData.append('dicomName', currentDicomName);
      formData.append(
        'sopUid',
        slicParam.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
      );
      // VFSS인 경우 pj_category parameter 추가
      if (location.pathname === '/labeling/main/VFSS') {
        formData.append('pj_category', '101003');
      }
      axios
        .post(`${process.env.REACT_APP_FLASK_URL}/Slic`, formData, {
          'Content-Type': 'multipart/form-data',
        })
        .then((response) => {
          //이미지 flask 서버에 전송 후 result 가져옴
          // console.log('finish');
          //console.log(response);
          //console.log(response.data);
          if (response.data.result !== 'none') {
            //console.log(response.data.result);
            // let seg = Uint16Array.from(response.data.result);
            // //let movePt = [];
            // // draw SLIC segments
            // let enabledElements = cornerstone.getEnabledElements();
            // let element = enabledElements[0].element;

            // setters.labelmap3DForElement(element, seg, 0);
            // cornerstone.updateImage(element);

            let binary_string = window.atob(response.data.result);
            let len = binary_string.length;
            let bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binary_string.charCodeAt(i);
            }
            let seg = new Uint16Array(bytes.buffer);
            setters.labelmap3DForElement(element, seg, 0);
            cornerstone.updateImage(element);

            // element.addEventListener('cornerstonetoolsmousedrag', SlicMove);
            // element.addEventListener(
            //   'cornerstonetoolsmouseup',
            //   SlicLeftRelease,
            // );
            //mouse wheel 동작 시 zoom in / out
            cornerstoneTools.setToolActive('ZoomMouseWheel', {
              mouseButtonMask: 5,
            });
            //mouse wheel button drag 시 image move
            cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4 });
          } else {
            alert('Result : none');
          }
        })
        //error
        .catch((error) => {
          console.log(error);
          //console.log(error.response);
        });

      handleClickVariant('Slic Update', 'warning');
    } else {
      props.applyButton();
    }
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        sx={{
          width: '260px',
          padding: '4px 8px',
          backgroundColor: ' rgba(255, 255, 255, 0.9)',
        }}
      >
        <Grid container>
          <Typography sx={{ marginBottom: '8px' }}>SLIC</Typography>
        </Grid>
        <Grid container spacing={2} direction="row">
          <br />
          <Typography sx={{ marginLeft: '4px' }}>Number of segments</Typography>
          <Grid item container spacing={2} direction="row">
            <Grid item xs={1}></Grid>
            <Grid item xs={8}>
              <Slider
                value={typeof segValue === 'number' ? segValue : 0}
                onChange={handleSliderChange('segVal')}
                aria-labelledby="input-seg-slider"
                step={1}
                min={1}
                max={3000}
                marks={slicMark1}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                value={segValue}
                margin="dense"
                onChange={handleInputSegChange}
                onBlur={handleBlurSeg}
                inputProps={{
                  step: 1,
                  min: 1,
                  max: 3000,
                  type: 'number',
                  'aria-labelledby': 'input-seg-slider',
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} direction="row">
          <br />
          <Typography sx={{ marginLeft: '4px' }}>Compactness</Typography>
          <Grid item container spacing={2} direction="row">
            <Grid item xs={1}></Grid>
            <Grid item xs={8}>
              <Slider
                value={typeof compValue === 'number' ? compValue : 0}
                onChange={handleSliderChange('compVal')}
                aria-labelledby="input-comp-slider"
                step={1}
                min={1}
                max={30}
                marks={slicMark2}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                value={compValue}
                margin="dense"
                onChange={handleInputCompChange}
                onBlur={handleBlurComp}
                inputProps={{
                  step: 1,
                  min: 1,
                  max: 30,
                  type: 'number',
                  'aria-labelledby': 'input-comp-slider',
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} direction="row">
          <br />
          <Typography sx={{ marginLeft: '4px' }}>Sigma</Typography>
          <Grid item container spacing={2} direction="row">
            <Grid item xs={1}></Grid>
            <Grid item xs={8}>
              <Slider
                value={typeof sigValue === 'number' ? sigValue : 0}
                onChange={handleSliderChange('sigVal')}
                aria-labelledby="input-sig-slider"
                step={0.1}
                min={0}
                max={10.0}
                marks={slicMark3}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                value={sigValue}
                margin="dense"
                onChange={handleInputSigChange}
                onBlur={handleBlurSig}
                inputProps={{
                  step: 0.1,
                  min: 0,
                  max: 10.0,
                  type: 'number',
                  'aria-labelledby': 'input-sig-slider',
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button size="small" onClick={slicUpdate}>
            Apply
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SlicContextmenu;
