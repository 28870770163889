import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import {
  addLabelIndex,
  addLabelName,
  selectCurrLabelIndex,
} from '../../../../_reducers/labelReducer';
import { updateLabelampMaxIdx } from '../../../../_reducers/segmentReducer';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { yellow } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const StyledButton = withStyles({
  root: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: '#58a5f0',
    borderRadius: 3,
    border: 0,
    color: 'black',
    '&:hover': {
      backgroundColor: '#004c8c',
      color: 'white',
    },
    height: '28px',
    minWidth: '28px',
    padding: '0px',
    margin: '8px 4px',
    boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

//icon style
const useStyles = makeStyles({
  root: {},
  buttonProgress: {
    display: 'flex',
    alignItems: 'center',
    color: yellow[700],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -18,
  },
  icon: {
    width: '28px',
    height: '28px',
    // padding: '2px',
    '&:hover': {
      filter:
        'invert(100%) sepia(100%) saturate(0%) hue-rotate(252deg) brightness(102%) contrast(102%)',
    },
  },
});

const LiverCirrhosisBtn = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const dicomCnt = useSelector((state) => state.dicom.imgIds.length);
  const label = useSelector((state) => state.label);
  const labelmapMax = useSelector((state) => state.seg.labelmapMaxIndex);
  const labelIndexs = useSelector(
    (state) => state.label[currentDicomIdx].labelindex,
  );
  const currentDicomName = useSelector((state) => state.dicom.fileNames);
  const { setters, getters } = cornerstoneTools.getModule('segmentation');
  const reduxDicom = useSelector((state) => state.dicom);
  //material ui snack bar
  const { enqueueSnackbar } = useSnackbar();

  const [predictBtnDisable, SetPredictBtnDisable] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  //label add btn event
  function Predict() {
    let element = document.querySelector('.viewport-element');
    let voi = cornerstone.getViewport(element).voi;
    let formData = new FormData();
    formData.append('voi', JSON.stringify(voi));
    formData.append('objId', searchParams.get('pjId'));
    formData.append('dicomName', currentDicomName);
    let sopUids = [];
    for (let i = 0; i < reduxDicom.imgIds.length; i++) {
      sopUids.push(reduxDicom.imgIds[i].split('&')[3].split('=')[1]);
    }
    formData.append('sopUids', sopUids);
    //urinary stone progress circle
    if (!loading) {
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setLoading(false);
      }, 1000 * currentDicomName.length);
    }
    axios
      .post(`${process.env.REACT_APP_FLASK_URL}/LiverCirrhosis`, formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then((res) => {
        if (res.data) {
          // predict liver Div 체크 후 없을때 새로 추가
          if (document.querySelectorAll('.predict, .cirrhosis').length === 0) {
            const resultDiv = document.createElement('div');
            resultDiv.classList.add('predict');
            resultDiv.classList.add('cirrhosis');
            resultDiv.innerHTML = `<div class="name">${res.data.result.name}</div>
          <div class="acc">acc : ${res.data.result.acc}</div>`;
            let topLeftOverlay = document.querySelectorAll(
              '.top-left, .overlay-element',
            )[0];
            resultDiv.style.backgroundColor = 'red';
            resultDiv.style.color = 'white';
            // resultDiv.style.fontWeight = 'bold';
            resultDiv.style.border = '1px solid red';
            resultDiv.style.padding = '8px';
            resultDiv.style.marginTop = '8px';
            resultDiv.style.width = '136px';
            topLeftOverlay.append(resultDiv);
          } else {
            let predictDiv = document.querySelectorAll(
              '.predict, .cirrhosis',
            )[0];

            predictDiv.querySelector('.name').textContent =
              res.data.result.name;
            predictDiv.querySelector('.acc').textContent =
              'acc : ' + res.data.result.acc;
          }
        }

        handleClickVariant('LiverCirrhosisPredict Complete', 'success');
        //console.log('predict complete');
      })
      //error
      .catch((error) => {
        //console.log("error");
        console.log(error);
        //console.log(error.response);
      });
  }

  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };

  useEffect(() => {
    if (dicomCnt > 0) {
      SetPredictBtnDisable(false);
    } else {
      SetPredictBtnDisable(true);
    }
  }, [dicomCnt]);

  return (
    <>
      <StyledButton disabled={predictBtnDisable || loading} onClick={Predict}>
        <img
          className={classes.icon}
          src={process.env.PUBLIC_URL + '/imgs/icons/liver.png'}
          alt="liver"
        />
        {loading && (
          <CircularProgress
            className={classes.buttonProgress}
            size={36}
            thickness={6}
          />
        )}
      </StyledButton>
    </>
  );
};

export default LiverCirrhosisBtn;
