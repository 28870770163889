import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import CollectionsIcon from '@mui/icons-material/Collections';
import { useSnackbar } from 'notistack';

import {
  updateKeyCode,
  updateStartEndPos,
  updateAddDragPos,
  updateDelDragPos,
  clearDragPos,
} from '../../../../_reducers/grabcutReducer';
import { setToggleButton } from '../../../../_reducers/buttonReducer';
import { updateBrushcolorIdx } from '../../../../_reducers/segmentReducer';
import axios from 'axios';

const { getDiffBetweenPixelData } = cornerstoneTools.importInternal(
  'util/segmentationUtils',
);

const StyledButton = withStyles({
  root: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: '#58a5f0',
    borderRadius: 3,
    border: 0,
    color: 'black',
    '&:hover': {
      backgroundColor: '#004c8c',
      color: 'white',
    },
    height: '28px',
    minWidth: '28px',
    padding: '0px',
    margin: '8px 4px',
    boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const GrabcutBtn = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  //Grabcut eventListener 등록 check flag
  const [grabcutAddEvent, setGrabcutAddEvent] = useState(false);

  const dispatch = useDispatch();
  const imgIds = useSelector((state) => state.dicom.imgIds);
  const btnState = useSelector(
    (state) => state.button,
    () => {},
  );
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const label = useSelector((state) => state.label);
  const segidx = useSelector((state) => state.seg);
  const grabcut = useSelector((state) => state.grabcut);
  const userId = useSelector((state) => state.user.userData._id);
  const currentDicomName = useSelector(
    (state) => state.dicom.fileNames[currentDicomIdx],
  );
  const reduxDicom = useSelector((state) => state.dicom);
  const brushColorIndex = useSelector((state) => state.seg.brushColorIndex);
  //material ui snack bar
  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };

  // const changeLAbel = useCallback(
  //   (e) => {
  //     changeLabelmap(e);
  //     console.log(e);
  //   },
  //   // eslint-disable-next-line
  //   [currentDicomIdx, imgIds],
  // );

  // function changeLabelmap(colorLUTIndex) {
  //   let element = document.querySelector('.viewport-element');
  //   const { getters, setters } = cornerstoneTools.getModule('segmentation');
  //   const {
  //     toggleSegmentVisibility,
  //     colorLUT: setColorLUT,
  //     activeSegmentIndex: setActiveSegmentIndex,
  //     activeLabelmapIndex: setActiveLabelmapIndex,
  //     colorLUTIndexForLabelmap3D: setColorLUTIndexForLabelmap3D,
  //   } = setters;
  //   const { isSegmentVisible, labelmap3D: getLabelmap3D } = getters;
  //   if (colorLUTIndex == null) {
  //     return;
  //   }

  //   if (!element) {
  //     return;
  //   }
  //   let colorArr = [];
  //   // if (colorLUTIndex === 1000) {
  //   //   colorArr = [255, 255, 255, 255];
  //   //   setColorLUT(colorLUTIndex, colorArr);
  //   // } else if (colorLUTIndex === 2000) {
  //   //   colorArr = [0, 0, 0, 0];
  //   //   setColorLUT(colorLUTIndex, colorArr);
  //   // } else {
  //   // }
  //   setActiveSegmentIndex(element, colorLUTIndex);

  //   // setColorLUTIndexForLabelmap3D(0, colorLUTIndex);
  //   // if (!isSegmentVisible(element, colorLUTIndex, colorLUTIndex)) {
  //   //   toggleSegmentVisibility(element, colorLUTIndex);
  //   // }

  //   cornerstone.updateImage(element, true);
  // }

  function Grabcut() {
    let element = document.querySelector('.viewport-element');
    if (btnState[5]) {
      //Grabcut disable
      dispatch(setToggleButton('grabcut', false));
      // cornerstoneTools.store.state.tools[4].configuration.alwaysEraseOnClick = false;
      window.removeEventListener('keydown', KeyDown);
      element.removeEventListener('cornerstonetoolsmousedrag', GrabcutMove);
      element.removeEventListener('cornerstonetoolsmouseup', GrabcutRelease);
      cornerstoneTools.setToolPassiveForElement(element, 'RectangleScissors');
      setGrabcutAddEvent(false);
      //draspos clear
      dispatch(clearDragPos([]));
      // zoom 기능 비활성화
      cornerstoneTools.setToolPassiveForElement(element, 'ZoomMouseWheel');
      // pan 기능 비활성화
      cornerstoneTools.setToolPassiveForElement(element, 'Pan');
      cornerstoneTools.setToolPassiveForElement(element, 'Brush');
    } else {
      //Grabcut Active
      dispatch(setToggleButton('grabcut', true));
      // cornerstoneTools.store.state.tools[4].configuration.alwaysEraseOnClick = true;
      window.addEventListener('keydown', KeyDown);
      element.addEventListener('cornerstonetoolsmousedrag', GrabcutMove);
      element.addEventListener('cornerstonetoolsmouseup', GrabcutRelease);
      cornerstoneTools.setToolActiveForElement(element, 'RectangleScissors', {
        mouseButtonMask: 2,
      });
      cornerstoneTools.setToolActiveForElement(element, 'Brush', {
        mouseButtonMask: 1,
      });
      //mouse wheel 동작 시 zoom in / out
      cornerstoneTools.setToolActiveForElement(element, 'ZoomMouseWheel', {
        mouseButtonMask: 5,
      });
      //mouse wheel button drag 시 image move
      cornerstoneTools.setToolActiveForElement(element, 'Pan', {
        mouseButtonMask: 4,
      });
      setGrabcutAddEvent(true);
    }
    cornerstone.updateImage(element);
  }

  function KeyDownCallback(e) {
    let element = document.querySelector('.viewport-element');
    const { getters, setters } = cornerstoneTools.getModule('segmentation');
    const { labelmap3D } = getters.labelmap2D(element);

    //keycode
    // d:68 - 제거배경 선택
    // a:65 - 복원전경 선택
    // n:78 - 변경사항 적용
    //keycode에 맞는 parameter setting 후 flask로 전송

    let voi = cornerstone.getViewport(element).voi;
    let formData = new FormData();
    formData.append('keycode', grabcut.keycode);
    formData.append('startendpos', JSON.stringify(grabcut.startendpos));
    formData.append('deldragpos', JSON.stringify(grabcut.deldragpos));
    formData.append('adddragpos', JSON.stringify(grabcut.adddragpos));
    formData.append('voi', JSON.stringify(voi));
    formData.append('color', labelmap3D.activeSegmentIndex);
    formData.append(
      'sopUid',
      reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
    );

    if (e.keyCode === 68) {
      handleClickVariant('제거배경 선택 하세요.', 'info');
      dispatch(updateKeyCode(e.keyCode));

      cornerstoneTools.store.state.tools[
        cornerstoneTools.store.state.tools.length - 1
      ].configuration.alwaysEraseOnClick = false;
    } else if (e.keyCode === 65) {
      handleClickVariant('복원배경 선택 하세요.', 'info');
      dispatch(updateKeyCode(e.keyCode));

      cornerstoneTools.store.state.tools[
        cornerstoneTools.store.state.tools.length - 1
      ].configuration.alwaysEraseOnClick = false;
    } else if (e.keyCode === 78) {
      //n:78 - 변경사항 적용
      formData.append('userId', userId);
      formData.append('objId', searchParams.get('pjId'));
      formData.append('dicomName', currentDicomName);

      // VFSS인 경우 pj_category parameter 추가
      if (location.pathname === '/labeling/main/VFSS') {
        formData.append('pj_category', '101003');
      }

      axios
        .post(`${process.env.REACT_APP_FLASK_URL}/Grabcut`, formData, {
          'Content-Type': 'multipart/form-data',
        })
        .then((response) => {
          //이미지 flask 서버에 전송 후 result 가져옴
          let binary_string = window.atob(response.data.result);
          let len = binary_string.length;
          let bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
          }
          let seg = new Uint16Array(bytes.buffer);
          // let seg = Uint16Array.from(response.data.result);

          //draw rectangle pixeldata pushState
          pushState(
            element,
            currentDicomIdx,
            labelmap3D.labelmaps2D[currentDicomIdx].pixelData,
            seg,
          );

          labelmap3D.labelmaps2D[currentDicomIdx].pixelData = seg;
          cornerstone.updateImage(element);
          // setters.activeSegmentIndex(element, segidx.brushColorIndex);
          handleClickVariant('변경사항 적용완료', 'success');
        })
        //error
        .catch((error) => {
          console.log(error);
        });
    } else if (e.keyCode === 82) {
      //r:82 - leftdragpos,rightdragpos 좌표 저장 배열 clear
      handleClickVariant('Clear', 'success');
      dispatch(clearDragPos([]));

      //초기화 시 rect box 만 남김
      //keycode에 관계없이 mouse right drag로 rectangle 그리기
      let element = document.querySelector('.viewport-element');

      //activeSegmentIndex : 2~11
      //console.log(labelmap3D.activeSegmentIndex);
      let brushColor = segidx.brushColorIndex;
      //labelreducer-rect에 저장하기전 가지고 있을 변수
      let xMin, xMax, yMin, yMax, width, height;
      xMin = grabcut.startendpos[0];
      yMin = grabcut.startendpos[1];
      xMax = grabcut.startendpos[2];
      yMax = grabcut.startendpos[3];
      width = xMax - xMin;
      height = yMax - yMin;

      //reactangle 그릴 1회용 canvas 생성
      let enabledElement = cornerstone.getEnabledElement(element);
      let elementWidth = enabledElement.image.width;
      let elementHeight = enabledElement.image.height;
      const rectangleCanvas = document.createElement('canvas');
      rectangleCanvas.width = elementWidth;
      rectangleCanvas.height = elementHeight;
      const rectangleCtx = rectangleCanvas.getContext('2d');

      //좌표로 rectangle draw
      rectangleCtx.lineWidth = 2;
      rectangleCtx.strokeStyle = 'red';

      //parameter : 좌상단x,좌상단y,width,height

      rectangleCtx.strokeRect(
        parseInt(xMin),
        parseInt(yMin),
        parseInt(width),
        parseInt(height),
      );
      var imgData = rectangleCtx.getImageData(
        0,
        0,
        elementWidth,
        elementHeight,
      );
      const len = imgData.data.length; // width * height * 4;

      //그려진 reactangle labelmap에 input
      let seg = new Uint16Array(elementWidth * elementHeight);
      for (let i = 0, k = 0; i < len; i += 4, k++) {
        //data[i] ->r
        //data[i+1] ->g
        //data[i+2] ->b
        //data[i+3] ->a

        if (imgData.data[i] === 255) {
          seg[k] = brushColor;
        }
      }

      //draw rectangle pixeldata pushState
      pushState(
        element,
        currentDicomIdx,
        labelmap3D.labelmaps2D[currentDicomIdx].pixelData,
        seg,
      );

      labelmap3D.labelmaps2D[currentDicomIdx].pixelData = seg;

      cornerstone.updateImage(element);
      rectangleCanvas.remove();
    }
  }

  const KeyDown = useCallback(
    (e) => {
      KeyDownCallback(e);
    },
    // eslint-disable-next-line
    [currentDicomIdx, imgIds],
  );

  const GrabcutMove = useCallback(
    (e) => {
      GrabcutMoveCallback(e);
    },
    // eslint-disable-next-line
    [grabcut, currentDicomIdx],
  );

  function GrabcutMoveCallback(e) {
    let keycode = grabcut.keycode;
    const pos = e.detail.currentPoints.image;
    let posX = parseInt(pos.x);
    let posY = parseInt(pos.y);
    let movePt = [];

    if (keycode !== 0) {
      if (keycode === 68) {
        movePt = [...grabcut.deldragpos, [posX, posY]];
      } else if (keycode === 65) {
        movePt = [...grabcut.adddragpos, [posX, posY]];
      }
      //movePt 2차원 배열 중복제거
      let uniques = [];
      let itemsFound = {};
      for (let i = 0, l = movePt.length; i < l; i++) {
        let stringified = JSON.stringify(movePt[i]);
        if (itemsFound[stringified]) {
          continue;
        }
        uniques.push(movePt[i]);
        itemsFound[stringified] = true;
      }
      if (keycode === 68) {
        dispatch(updateDelDragPos(uniques));
      } else if (keycode === 65) {
        dispatch(updateAddDragPos(uniques));
      }
    }
  }

  //mouseup
  function GrabcutReleaseCallback(e) {
    if (e.detail.event.button === 2) {
      //keycode에 관계없이 mouse right drag로 rectangle 그리기
      let element = document.querySelector('.viewport-element');

      const { getters, setters } = cornerstoneTools.getModule('segmentation');
      const { labelmap3D } = getters.labelmap2D(element);

      // //rectangle draw history 삭제
      setters.undo(element);
      labelmap3D.redo.pop();

      //activeSegmentIndex : 2~11
      let brushColor = labelmap3D.activeSegmentIndex;

      const pointS = e.detail.startPoints.image;
      const pointL = e.detail.lastPoints.image;
      let point = [pointS.x, pointS.y, pointL.x, pointL.y];

      //labelreducer-rect에 저장하기전 가지고 있을 변수
      let xMin, xMax, yMin, yMax, width, height;

      let enabledElement = cornerstone.getEnabledElement(element);
      let elementWidth = enabledElement.image.width;
      let elementHeight = enabledElement.image.height;
      //point 좌표가 음수인경우(dicom이미지 보다 마우스 위치가 바깥쪽일때 발생함.) -> 0으로 처리
      //point 좌표가 512>= 인경우(dicom이미지 보다 마우스 위치가 바깥쪽일때 발생함.) -> 512로 처리
      for (let i = 0; i < 4; i++) {
        if (point[i] < 0) point[i] = 0;
      }
      for (let i = 0; i < 4; i++) {
        if (i % 2 === 0) {
          if (point[i] >= elementWidth) point[i] = elementWidth;
        } else {
          if (point[i] >= elementWidth) point[i] = elementHeight;
        }
      }
      // set xMin, xMax
      if (point[0] < point[2]) {
        xMin = point[0];
        xMax = point[2];
      } else {
        xMin = point[2];
        xMax = point[0];
      }
      //set ymin, ymax
      if (point[1] < point[3]) {
        yMin = point[1];
        yMax = point[3];
      } else {
        yMin = point[3];
        yMax = point[1];
      }
      //set width, height
      width = xMax - xMin;
      height = yMax - yMin;

      dispatch(updateStartEndPos([xMin, yMin, xMax, yMax]));

      //reactangle 그릴 1회용 canvas 생성
      const rectangleCanvas = document.createElement('canvas');
      rectangleCanvas.width = elementWidth;
      rectangleCanvas.height = elementHeight;
      const rectangleCtx = rectangleCanvas.getContext('2d');

      //좌표로 rectangle draw
      rectangleCtx.lineWidth = 2;
      rectangleCtx.strokeStyle = 'red';

      //parameter : 좌상단x,좌상단y,width,height

      rectangleCtx.strokeRect(
        parseInt(xMin),
        parseInt(yMin),
        parseInt(width),
        parseInt(height),
      );
      var imgData = rectangleCtx.getImageData(
        0,
        0,
        elementWidth,
        elementHeight,
      );
      const len = imgData.data.length; // width * height * 4;

      //그려진 reactangle labelmap에 input
      let seg = new Uint16Array(elementWidth * elementHeight);
      for (let i = 0, k = 0; i < len; i += 4, k++) {
        //data[i] ->r
        //data[i+1] ->g
        //data[i+2] ->b
        //data[i+3] ->a

        if (imgData.data[i] === 255) {
          seg[k] = brushColor;
        }
      }

      //draw rectangle pixeldata pushState
      pushState(
        element,
        currentDicomIdx,
        labelmap3D.labelmaps2D[currentDicomIdx].pixelData,
        seg,
      );

      labelmap3D.labelmaps2D[currentDicomIdx].pixelData = seg;
      cornerstone.updateImage(element);
      rectangleCanvas.remove();
      dispatch(updateKeyCode(0));
      dispatch(clearDragPos([]));
    }
  }
  const GrabcutRelease = useCallback(
    (e) => {
      GrabcutReleaseCallback(e);
    },
    // eslint-disable-next-line
    [currentDicomIdx, brushColorIndex],
  );

  //pushState function
  function pushState(
    element,
    currentDicomIdx,
    previousPixelData,
    newPixelData,
  ) {
    //undo, redo를 이용하기 위하여 pixeldata 비교한 후 pushstate
    let { setters } = cornerstoneTools.getModule('segmentation');
    let operation = {
      imageIdIndex: currentDicomIdx,
      diff: getDiffBetweenPixelData(previousPixelData, newPixelData),
    };
    setters.pushState(element, [operation]);
  }

  //다른 버튼 클릭시  evenlistener가 등록되있는지 확인후 등록되있으면 remove
  useEffect(() => {
    if (grabcutAddEvent && !btnState[5]) {
      let element = document.querySelector('.viewport-element');
      window.removeEventListener('keydown', KeyDown);
      element.removeEventListener('cornerstonetoolsmousedrag', GrabcutMove);
      element.removeEventListener('cornerstonetoolsmouseup', GrabcutRelease);
      cornerstoneTools.setToolDisabled('RectangleScissors');
      setGrabcutAddEvent(false);
      //기능 비활성화
      cornerstoneTools.setToolPassiveForElement(element, 'RectangleScissors');
      // cornerstoneTools.setToolPassiveForElement(element, 'ZoomMouseWheel');
      // cornerstoneTools.setToolPassiveForElement(element, 'Pan');
      //draspos clear
      dispatch(clearDragPos([]));
    }
    // eslint-disable-next-line
  }, [btnState]);

  //버튼 활성화 된상태에서 labellist가 없을 경우 조건처리
  useEffect(() => {
    if (label[currentDicomIdx])
      if (label[currentDicomIdx].labelindex.length <= 0 && grabcutAddEvent) {
        return () => {
          let element = document.querySelector('.viewport-element');
          window.removeEventListener('keydown', KeyDown);
          if (element !== null) {
            element.removeEventListener(
              'cornerstonetoolsmousedrag',
              GrabcutMove,
            );
            element.removeEventListener(
              'cornerstonetoolsmouseup',
              GrabcutRelease,
            );
            cornerstoneTools.setToolPassiveForElement(
              element,
              'RectangleScissors',
            );
            setGrabcutAddEvent(false);
            dispatch(setToggleButton('grabcut', false));
            // zoom 기능 비활성화
            cornerstoneTools.setToolPassiveForElement(
              element,
              'ZoomMouseWheel',
            );
            // pan 기능 비활성화
            cornerstoneTools.setToolPassiveForElement(element, 'Pan');
            cornerstoneTools.setToolPassiveForElement(element, 'Brush');
            //draspos clear
            dispatch(clearDragPos([]));
          }
        };
      }
  });

  // 버튼 활성화 상태에서 dicomidx 이동 시 버튼 비활성화
  useEffect(() => {
    return () => {
      let element = document.querySelector('.viewport-element');
      window.removeEventListener('keydown', KeyDown);
      if (element !== null) {
        element.removeEventListener('cornerstonetoolsmousedrag', GrabcutMove);
        element.removeEventListener('cornerstonetoolsmouseup', GrabcutRelease);
        cornerstoneTools.setToolPassiveForElement(element, 'RectangleScissors');
        setGrabcutAddEvent(false);
        dispatch(setToggleButton('grabcut', false));
        // // zoom 기능 비활성화
        cornerstoneTools.setToolPassiveForElement(element, 'ZoomMouseWheel');
        // // pan 기능 비활성화
        cornerstoneTools.setToolPassiveForElement(element, 'Pan');
        cornerstoneTools.setToolPassiveForElement(element, 'Brush');
        //draspos clear
        dispatch(clearDragPos([]));
      }
    };
    // eslint-disable-next-line
  }, [currentDicomIdx, imgIds]);

  //다른 버튼 클릭시 버튼 활성화 Check 후 setting
  // key event 처리
  useEffect(() => {
    const KeyDown = (e) => {
      if (e.target.constructor !== HTMLInputElement) {
        if (label[currentDicomIdx].labelindex.length > 0) {
          // 키보드 'g'버튼
          if (e.keyCode === 71) {
            Grabcut();
          }
        }
      }
    };
    window.addEventListener('keydown', KeyDown);
    return () => window.removeEventListener('keydown', KeyDown);
    // eslint-disable-next-line
  }, [btnState]);

  return (
    <div>
      <StyledButton
        onClick={Grabcut}
        disabled={props.btndisable}
        style={{
          backgroundColor:
            props.btndisable === false
              ? btnState[5] === true
                ? '#004c8c'
                : '#58a5f0'
              : '',
          color:
            props.btndisable === false
              ? btnState[5] === true
                ? 'white'
                : 'black'
              : '',
        }}
      >
        <CollectionsIcon sx={{ width: '28px', height: '28px' }} />
      </StyledButton>
    </div>
  );
};

export default GrabcutBtn;
