// ducks 패턴: 액션타입, 액션크리에이터, 리듀서가 모두 동일한 화일에 존재

// action type: 스트링, 모듈명/액션타입으로 표시
export const UPDATE_KEYCODE = 'grabcut/UPDATE_KEYCODE';
export const UPDATE_STARTENDPOS = 'grabcut/UPDATE_STARTENDPOS';
export const UPDATE_DELDRAGPOS = 'grabcut/UPDATE_DELDRAGPOS';
export const UPDATE_ADDDRAGPOS = 'grabcut/UPDATE_ADDDRAGPOS';
export const CLEAR_DRAGPOS = 'grabcut/CLEAR_DRAGPOS';

// action creator: 액션을 동적으로 생성하는 펑션
export const updateKeyCode = (keycode) => ({
  type: UPDATE_KEYCODE,
  payload: keycode,
});
export const updateStartEndPos = (startendpos) => ({
  type: UPDATE_STARTENDPOS,
  payload: startendpos,
});
export const updateDelDragPos = (deldragpos) => ({
  type: UPDATE_DELDRAGPOS,
  payload: deldragpos,
});
export const updateAddDragPos = (adddragpos) => ({
  type: UPDATE_ADDDRAGPOS,
  payload: adddragpos,
});
export const clearDragPos = (dragpos) => ({
  type: CLEAR_DRAGPOS,
  payload: dragpos,
});
const initialState = {
  keycode: 0,
  startendpos: [],
  deldragpos: [],
  adddragpos: [],
};

export const grabcutReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_KEYCODE:
      state.keycode = action.payload;
      return state;
    case UPDATE_STARTENDPOS:
      state.startendpos = action.payload;
      return state;
    case UPDATE_DELDRAGPOS:
      state.deldragpos = action.payload;
      return state;
    case UPDATE_ADDDRAGPOS:
      state.adddragpos = action.payload;
      return state;
    case CLEAR_DRAGPOS:
      state.deldragpos = action.payload;
      state.adddragpos = action.payload;
      return state;
    default:
      return state;
  }
};
