import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonGroup, MenuItem, Popover } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

import {
  updateSlicPos,
  updateSlicDelPos,
} from '../../../../_reducers/labelReducer';
import { setToggleButton } from '../../../../_reducers/buttonReducer';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import '../DicomViewer/ContextMenu.css';
import SlicContextmenu from '../BtnGroup/SlicContextmenu';

// const StyledButton = withStyles({
//   root: {
//     //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
//     background: '#58a5f0',
//     borderRadius: 3,
//     border: 0,
//     color: 'black',
//     '&:hover': {
//       backgroundColor: '#004c8c',
//       color: 'white',
//     },
//     height: 52,
//     width: 80,
//     padding: '0 20px',
//     margin: '8px 4px',
//     boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
//   },
//   label: {
//     textTransform: 'capitalize',
//   },
// })(Button);

const StyledButton = withStyles({
  root: {
    background: '#58a5f0',
    borderRadius: 3,
    border: 0,
    color: 'black',
    '&:hover': {
      backgroundColor: '#004c8c',
      color: 'white',
    },
    '&:disabled': {
      backgroundColor: '#58a5f0',
    },
    width: '28px',
    height: '28px',
    padding: '0px 0px 0px 0px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const SlicBtn = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  //slic eventListener 등록 check flag
  const [slicAddEvent, setSlicAddEvent] = useState(false);

  // split button
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const dispatch = useDispatch();
  const btnState = useSelector(
    (state) => state.button,
    () => {},
  );
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const label = useSelector((state) => state.label);
  const slicParam = useSelector((state) => state.dicom);
  const currentDicomName = useSelector(
    (state) => state.dicom.fileNames[currentDicomIdx],
  );
  const { getters, setters } = cornerstoneTools.getModule('segmentation');
  //material ui snack bar
  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (text, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(text, { variant });
  };

  //SLIC
  function Slic() {
    let element = document.querySelector('.viewport-element');
    if (btnState[8]) {
      dispatch(setToggleButton('slic', false));

      element.removeEventListener('cornerstonetoolsmousedrag', SlicMove);
      element.removeEventListener('cornerstonetoolsmouseup', SlicLeftRelease);
      setSlicAddEvent(false);
      // zoom 기능 비활성화
      cornerstoneTools.setToolDisabled('ZoomMouseWheel');
      // pan 기능 비활성화
      cornerstoneTools.setToolDisabled('Pan');

      setters.deleteSegment(element, 203, 0);
      cornerstoneTools.setToolDisabled('Brush');
      handleClickVariant('Slic Deactivate', 'warning');
    } else {
      dispatch(setToggleButton('slic', true));

      let voi = cornerstone.getViewport(element).voi;
      let formData = new FormData();
      formData.append('seg', slicParam.seg);
      formData.append('comp', slicParam.comp);
      formData.append('sig', slicParam.sig);
      formData.append('voi', JSON.stringify(voi));
      formData.append('objId', searchParams.get('pjId'));
      formData.append('dicomName', currentDicomName);
      formData.append(
        'sopUid',
        slicParam.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
      );

      // VFSS인 경우 pj_category parameter 추가
      if (location.pathname === '/labeling/main/VFSS') {
        formData.append('pj_category', '101003');
      }

      axios
        .post(`${process.env.REACT_APP_FLASK_URL}/Slic`, formData, {
          'Content-Type': 'multipart/form-data',
        })
        .then((response) => {
          //이미지 flask 서버에 전송 후 result 가져옴
          if (response.data.result !== 'none') {
            let binary_string = window.atob(response.data.result);
            let len = binary_string.length;
            let bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binary_string.charCodeAt(i);
            }
            let seg = new Uint16Array(bytes.buffer);
            setters.labelmap3DForElement(element, seg, 0);
            cornerstone.updateImage(element);

            // element.addEventListener('cornerstonetoolsmousedrag', SlicMove);
            // element.addEventListener(
            //   'cornerstonetoolsmouseup',
            //   SlicLeftRelease,
            // );
            setSlicAddEvent(true);
            //mouse wheel 동작 시 zoom in / out
            cornerstoneTools.setToolActive('ZoomMouseWheel', {
              mouseButtonMask: 5,
            });
            //mouse wheel button drag 시 image move
            cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4 });
          } else {
            alert('Result : none');
          }
          handleClickVariant('Slic Activate', 'success');
        })
        //error
        .catch((error) => {
          console.log(error);
          //console.log(error.response);
        });
      cornerstoneTools.setToolActive('Brush', { mouseButtonMask: 1 });
    }
  }
  // eslint-disable-next-line
  const SlicMove = useCallback((e) => {
    SlicMoveCallback(e);
  });

  function SlicMoveCallback(e) {
    // console.log('mouse move func');
    const pos = e.detail.currentPoints.image;
    let posX = parseInt(pos.x);
    let posY = parseInt(pos.y);
    let movePt = [];
    if (label[currentDicomIdx].currentlabelindex >= 0) {
      if (e.detail.buttons === 1) {
        movePt = [
          ...label[currentDicomIdx].slic[
            label[currentDicomIdx].currentlabelindex
          ].slicpos,
          [posX, posY],
        ];

        //movePt 2차원 배열 중복제거
        let uniques = [];
        let itemsFound = {};
        for (let i = 0, l = movePt.length; i < l; i++) {
          let stringified = JSON.stringify(movePt[i]);
          if (itemsFound[stringified]) {
            continue;
          }
          uniques.push(movePt[i]);
          itemsFound[stringified] = true;
        }
        dispatch(updateSlicPos(uniques, currentDicomIdx));
        //return uniques;
      } else if (e.detail.buttons === 2) {
        movePt = [
          ...label[currentDicomIdx].slic[
            label[currentDicomIdx].currentlabelindex
          ].slicdelpos,
          [posX, posY],
        ];
        //movePt 2차원 배열 중복제거
        let uniques = [];
        let itemsFound = {};
        for (let i = 0, l = movePt.length; i < l; i++) {
          let stringified = JSON.stringify(movePt[i]);
          if (itemsFound[stringified]) {
            continue;
          }
          uniques.push(movePt[i]);
          itemsFound[stringified] = true;
        }

        dispatch(updateSlicDelPos(uniques, currentDicomIdx));
      }
    }
  }
  // eslint-disable-next-line
  const SlicLeftRelease = useCallback((e) => {
    SlicLeftReleaseCallback(e);
  });

  function SlicLeftReleaseCallback(e) {
    // console.log('left release func');
    //mouse wheelclick release 를 제외한 이벤트에서만 동작
    if (e.detail.event.button !== 1) {
      const pos = e.detail.currentPoints.image;
      const clickButton = e.detail.event.button;
      let posX = parseInt(pos.x);
      let posY = parseInt(pos.y);
      let movePt = [];
      let moveDelPt = [];
      movePt =
        label[currentDicomIdx].slic[label[currentDicomIdx].currentlabelindex]
          .slicpos;
      if (e.detail.event.button === 2) {
        moveDelPt =
          label[currentDicomIdx].slic[label[currentDicomIdx].currentlabelindex]
            .slicdelpos;
      }

      let fileName = currentDicomName;

      let element = document.querySelector('.viewport-element');
      let voi = cornerstone.getViewport(element).voi;
      const { labelmap3D } = getters.labelmap2D(element);
      let data = labelmap3D.labelmaps2D[currentDicomIdx].pixelData;
      //console.log(labelmap3D.labelmaps2D[0].pixelData);
      let formData = new FormData();
      formData.append('mouseEvent', 'up');
      formData.append('mouseButton', clickButton);
      formData.append(
        'slicImg',
        new Blob([data], { type: 'application/octet-stream' }),
      );
      formData.append('movePt', JSON.stringify(movePt));
      if (e.detail.event.button === 2) {
        formData.append('moveDelPt', JSON.stringify(moveDelPt));
      }
      formData.append('posX', posX);
      formData.append('posY', posY);
      formData.append('fileName', fileName);
      formData.append('color', labelmap3D.activeSegmentIndex);
      formData.append('voi', JSON.stringify(voi));
      formData.append('seg', slicParam.seg);
      formData.append('comp', slicParam.comp);
      formData.append('sig', slicParam.sig);
      formData.append('objId', searchParams.get('pjId'));
      formData.append('dicomName', currentDicomName);
      formData.append(
        'sopUid',
        slicParam.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
      );

      // VFSS인 경우 pj_category parameter 추가
      if (location.pathname === '/labeling/main/VFSS') {
        formData.append('pj_category', '101003');
      }

      axios
        .post(`${process.env.REACT_APP_FLASK_URL}/SlicMouseEvent`, formData, {
          'Content-Type': 'multipart/form-data',
        })
        .then((response) => {
          //이미지 flask 서버에 전송 후 result 가져옴
          //console.log('SlicMouseEvent finish');
          // let seg = Uint16Array.from(response.data.result);
          // labelmap3D.labelmaps2D[currentDicomIdx].pixelData = seg;
          // cornerstone.updateImage(element);
          let binary_string = window.atob(response.data.result);
          let len = binary_string.length;
          let bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
          }
          let seg = new Uint16Array(bytes.buffer);
          labelmap3D.labelmaps2D[currentDicomIdx].pixelData = seg;
          cornerstone.updateImage(element);

          //slic 적용된 색상으로 draw color set
          setters.activeSegmentIndex(element, labelmap3D.activeSegmentIndex);

          dispatch(updateSlicPos([], currentDicomIdx));
          dispatch(updateSlicDelPos([], currentDicomIdx));
        })
        //error
        .catch((error) => {
          console.log(error);
        });

      cornerstone.updateImage(element);
    }
  }

  //다른 버튼 클릭시  evenlistener가 등록되있는지 확인후 등록되있으면 remove
  useEffect(() => {
    if (slicAddEvent && !btnState[8]) {
      let element = document.querySelector('.viewport-element');
      element.removeEventListener('cornerstonetoolsmousedrag', SlicMove);
      element.removeEventListener('cornerstonetoolsmouseup', SlicLeftRelease);
      setSlicAddEvent(false);

      setters.deleteSegment(element, 203, 0);
    }
  }, [btnState, SlicLeftRelease, SlicMove, setters, slicAddEvent]);
  //버튼 활성화 된상태에서 labellist가 없을 경우 조건처리
  useEffect(() => {
    if (label[currentDicomIdx])
      if (label[currentDicomIdx].labelindex.length <= 0 && slicAddEvent) {
        // console.log('remove event');
        let element = document.querySelector('.viewport-element');
        element.removeEventListener('cornerstonetoolsmousedrag', SlicMove);
        element.removeEventListener('cornerstonetoolsmouseup', SlicLeftRelease);
        setSlicAddEvent(false);
        dispatch(setToggleButton('slic', false));
        setters.deleteSegment(element, 203, 0);
        cornerstoneTools.setToolDisabled('Brush');
      }
  }, [
    slicAddEvent,
    SlicMove,
    SlicLeftRelease,
    dispatch,
    setters,
    label,
    currentDicomIdx,
  ]);

  useEffect(() => {
    let element = document.querySelector('.viewport-element');
    if (slicAddEvent) {
      element.addEventListener('cornerstonetoolsmousedrag', SlicMove);
      element.addEventListener('cornerstonetoolsmouseup', SlicLeftRelease);
    }
    return () => {
      element.removeEventListener('cornerstonetoolsmousedrag', SlicMove);
      element.removeEventListener('cornerstonetoolsmouseup', SlicLeftRelease);
    };
  }, [
    slicAddEvent,
    slicParam.sig,
    slicParam.comp,
    slicParam.seg,
    SlicLeftRelease,
    SlicMove,
  ]);

  return (
    <div>
      {/* <StyledButton
        onClick={Slic}
        disabled={props.btndisable}
        style={{
          backgroundColor:
            props.btndisable === false
              ? btnState[8] === true
                ? '#004c8c'
                : '#58a5f0'
              : '',
          color:
            props.btndisable === false
              ? btnState[8] === true
                ? 'white'
                : 'black'
              : '',
        }}
      >
        SLIC
      </StyledButton> */}

      <ButtonGroup
        variant="contained"
        aria-label="split button"
        disabled={props.btndisable}
        sx={{
          margin: '8px 4px',
          backgroundColor:
            props.btndisable === false
              ? btnState[8] === true
                ? '#004c8c'
                : '#58a5f0'
              : '',
          color:
            props.btndisable === false
              ? btnState[8] === true
                ? 'white'
                : 'black'
              : '',
          boxShadow: '0 3px 5px 2px rgba(26, 35, 126, .3)',
        }}
      >
        <StyledButton onClick={Slic}>
          {props.btndisable === false ? (
            btnState[8] === true ? (
              <img
                src={process.env.PUBLIC_URL + '/imgs/icons/slic.png'}
                alt="slic"
                style={{
                  width: '28px',
                  height: '28px',
                  filter:
                    'invert(100%) sepia(100%) saturate(0%) hue-rotate(252deg) brightness(102%) contrast(102%)',
                }}
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + '/imgs/icons/slic.png'}
                alt="slic"
                style={{
                  width: '28px',
                  height: '28px',
                }}
              />
            )
          ) : (
            <img
              src={process.env.PUBLIC_URL + '/imgs/icons/slic.png'}
              alt="slic"
              style={{
                width: '28px',
                height: '28px',
                filter: `opacity(23%)`,
              }}
            />
          )}
        </StyledButton>
        <StyledButton
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          sx={{
            width: '16px',
            padding: 0,
          }}
        >
          <ArrowDropDownIcon sx={{ width: '100%' }} />
        </StyledButton>
      </ButtonGroup>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem>
          <SlicContextmenu applyButton={Slic} />
        </MenuItem>
      </Popover>
    </div>
  );
};

export default SlicBtn;
