import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  selectCurrLabelIndex,
  toggleLabelVisible,
  updateLabelName,
} from '../../../../_reducers/labelReducer';
import { updateBrushcolorIdx } from '../../../../_reducers/segmentReducer';
import { updatesubtractlabel } from '../../../../_reducers/labelReducer';
import { updateLabelidx } from '../../../../_reducers/vfssReducer';
import {
  List,
  ListItemButton,
  ListItemIcon,
  Grid,
  TextField,
  IconButton,
  Checkbox,
  MenuItem,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LabelImportantRoundedIcon from '@mui/icons-material/LabelImportantRounded';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

const { setters, getters, configuration, state } =
  cornerstoneTools.getModule('segmentation');

const StyledListItem = withStyles({
  root: {
    height: 52,
    '&.Mui-selected': {
      backgroundColor: '#BDC3C7',
    },
    justifyItems: 'center',
  },
})(ListItemButton);

const LabelNameSelectList = [
  {
    value: 'Oral',
    label: 'Oral',
  },
  {
    value: 'Pharyngeal',
    label: 'Pharyngeal',
  },
  {
    value: 'Esophageal',
    label: 'Esophageal',
  },
  {
    value: 'Penetration',
    label: 'Penetration',
  },
  {
    value: 'Aspiration',
    label: 'Aspiration',
  },
  {
    value: 'PredOral',
    label: 'OralPredict',
  },
  {
    value: 'PredPharyngeal',
    label: 'PharyngealPredict',
  },
  {
    value: 'PredEsophageal',
    label: 'EsophagealPredict',
  },
  {
    value: 'PredPenetration',
    label: 'PenetrationPredict',
  },
  {
    value: 'PredAspiration',
    label: 'AspirationPredict',
  },
  {
    value: 'None',
    label: 'ㅤ',
  },
];

const LabelList = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const imgIds = useSelector((state) => state.dicom.imgIds);
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const label = useSelector((state) => state.label);
  const series = useSelector((state) => state.series);
  const seriesIndex = useSelector((state) => state.vfss.seriesIdx);
  const [subtractLabels, setSubtractLabels] = useState('');

  const location = useLocation();

  const handleChange = useCallback(
    (e) => {
      handleChangeCallback(e);
    },
    // eslint-disable-next-line
    [currentDicomIdx, seriesIndex, label],
  );

  // VFSS labelname change handler
  const handleChangeCallback = (e) => {
    let currentLabelIdx = label[currentDicomIdx].currentlabelindex;
    let labelIdx = label[currentDicomIdx].labelindex[currentLabelIdx];
    dispatch(updateLabelName(e.target.value, currentDicomIdx));

    let colorIdx = selectColorName(e.target.value);

    if (colorIdx !== 0) {
      let element = document.querySelector('.viewport-element');
      setters.activeSegmentIndex(element, colorIdx);
      dispatch(updateBrushcolorIdx(colorIdx));
      // cornerstone.updateImage(element);
      const { labelmap3D } = getters.labelmap2D(element);
      setters.activeLabelmapIndex(element, labelIdx);
      // Labelname 변경 시 현재 그려진 seg 영역 있는 경우 색상도 같이
      if (
        getters.labelmaps3D(element).labelmaps3D[labelIdx].labelmaps2D[
          currentDicomIdx
        ] &&
        location.pathname === '/labeling/main/VFSS'
      ) {
        let pixelTemp =
          getters.labelmaps3D(element).labelmaps3D[labelIdx].labelmaps2D[
            currentDicomIdx
          ].pixelData;

        let view = new Uint16Array(pixelTemp);

        for (var i = 0; i < view.length; i++) {
          if (view[i] !== 0) view[i] = colorIdx;
        }

        getters.labelmaps3D(element).labelmaps3D[labelIdx].labelmaps2D[
          currentDicomIdx
        ].pixelData = view;

        labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap = [
          0,
          colorIdx,
        ];
      }
      cornerstone.updateImage(element);
    }
  };

  function selectColorName(index) {
    switch (index) {
      case 'Oral':
        return 1;
      case 'Pharyngeal':
        return 25;
      case 'Esophageal':
        return 9;
      case 'Aspiration':
        return 16;
      case 'Penetration':
        return 2;
      default:
        return 0;
    }
  }
  useEffect(() => {
    let imgLen = imgIds.length;
    if (imgLen > 0) {
      let element = document.querySelector('.viewport-element');
      if (element !== null && label[currentDicomIdx]) {
        if (label[currentDicomIdx].labelindex.length > 0) {
          let currentLabelIdx = label[currentDicomIdx].currentlabelindex;
          setters.activeLabelmapIndex(
            element,
            label[currentDicomIdx].labelindex[currentLabelIdx],
          );
          // setters.activeSegmentIndex(element, brushColor);
          //segmentsOnLabelmap 탑색하여 현재 labelmap 색상 활성화 처리
          const { labelmap3D } = getters.labelmap2D(element);

          //vfss series 변경 후 최초 labelmaps 참조 예외처리
          let segmentsOnLabelmap;
          if (currentDicomIdx === 0) {
            segmentsOnLabelmap = labelmap3D.labelmaps2D[0].segmentsOnLabelmap;
          } else {
            segmentsOnLabelmap =
              labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap;
          }
          //segmentsOnLabelmap null 일때 예외처리
          if (segmentsOnLabelmap.length > 0) {
            let max = segmentsOnLabelmap.reduce(function (previous, current) {
              return previous > current ? previous : current;
            });
            setters.activeSegmentIndex(element, max);
            dispatch(updateBrushcolorIdx(max));
          }
          cornerstone.updateImage(element);
        }
        // subtract checkbox list 오름차순 정렬 후 최산값 갱신
        setSubtractLabels(
          [...label[currentDicomIdx].subtractlabel].sort(function (a, b) {
            return a - b;
          }),
        );
        // console.log(label[currentDicomIdx].subtractlabel);
        // console.log(label[currentDicomIdx + labelStartIdx].visible);
      }
    }

    // eslint-disable-next-line
  }, [currentDicomIdx]);

  //label list click function
  const handleListItemClick = (e, index) => {
    //checkbox 클릭을 제외하고 동작
    if (e.target.type !== 'checkbox') {
      let element = document.querySelector('.viewport-element');

      dispatch(selectCurrLabelIndex(index, currentDicomIdx));
      setters.activeLabelmapIndex(
        element,
        label[currentDicomIdx].labelindex[index],
      );

      //segmentsOnLabelmap 탑색하여 현재 labelmap 색상 활성화 처리
      const { labelmap3D } = getters.labelmap2D(element);
      let segmentsOnLabelmap =
        labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap;
      if (segmentsOnLabelmap.length > 0) {
        let max = segmentsOnLabelmap.reduce(function (previous, current) {
          return previous > current ? previous : current;
        });
        setters.activeSegmentIndex(element, max);
        dispatch(updateBrushcolorIdx(max));
      }
    } else {
      const currentIndex = subtractLabels.indexOf(index);
      let newChecked = [...subtractLabels];
      if (currentIndex === -1) {
        newChecked.push(index);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setSubtractLabels(newChecked);
      dispatch(updatesubtractlabel(currentDicomIdx, newChecked));
    }
  };

  //label visible toggle function
  function labelVisibleToggle(e) {
    e.stopPropagation();
    let element = document.querySelector('.viewport-element');
    const { labelmap3D } = getters.labelmap2D(element);
    let segmentsOnLabelmap =
      labelmap3D.labelmaps2D[currentDicomIdx].segmentsOnLabelmap;

    // 1개의 labelmap에 여러 색상 segments hide 처리
    for (let i = 0; i < segmentsOnLabelmap.length; i++) {
      setters.toggleSegmentVisibility(element, segmentsOnLabelmap[i]);
    }
    dispatch(toggleLabelVisible(currentDicomIdx));
  }

  useEffect(() => {
    let start = 0;
    let end = 0;
    if (seriesIndex === 0) {
      end = series[0].seriesImgIds.length - 1;
    } else if (seriesIndex === 1) {
      for (let i = 0; i < seriesIndex; i++) {
        start += series[i].seriesImgIds.length;
      }
      end = start + series[seriesIndex].seriesImgIds.length;
    }
    dispatch(updateLabelidx(start, end));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesIndex]);

  return (
    <div>
      <List
        component="nav"
        style={{
          height:
            location.pathname === '/labeling/main/VFSS'
              ? searchParams.get('viewer') === 'vfss'
                ? 'calc(100vh - 414px)'
                : 'calc(100vh - 828px)'
              : 'calc(100vh - 474px)',
          backgroundColor: '#D7DBDD',
          overflow: 'auto',
          padding: '8px 8px 8px 8px',
        }}
      >
        {label[currentDicomIdx] &&
          label[currentDicomIdx].labelname.map((value, index) => (
            <StyledListItem
              divider={true}
              selected={label[currentDicomIdx].currentlabelindex === index}
              onClickCapture={(e) => handleListItemClick(e, index)}
              key={index}
            >
              <Grid container direction="row" alignItems="center">
                <Grid item xs={1}>
                  {label[currentDicomIdx].currentlabelindex === index ? (
                    <ListItemIcon
                      style={{
                        minWidth: '32px',
                        color: '#5E6686',
                      }}
                    >
                      <LabelImportantRoundedIcon />
                    </ListItemIcon>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <ListItemIcon>
                    <Checkbox
                      edge="end"
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                      checked={
                        location.pathname === '/labeling/main/VFSS'
                          ? subtractLabels.find((e) => e === index) !==
                            undefined
                            ? true
                            : false
                          : label[currentDicomIdx].subtractlabel.find(
                              (e) => e === index,
                            ) !== undefined
                          ? true
                          : false
                      }
                    />
                  </ListItemIcon>
                </Grid>
                <Grid item xs={8}>
                  {location.pathname !== '/labeling/main/VFSS' && (
                    <TextField
                      // id={value}
                      value={label[currentDicomIdx].labelname[index]}
                      // defaultValue={value}
                      onChange={handleChange}
                      size="small"
                    />
                  )}
                  {location.pathname === '/labeling/main/VFSS' && (
                    <TextField
                      id="LabelName"
                      select
                      label="LabelName"
                      defaultValue="None"
                      value={label[currentDicomIdx].labelname[index]}
                      size="small"
                      sx={{ width: '90%' }}
                      onChange={(e) => {
                        handleChange(e, index);
                      }}
                    >
                      {LabelNameSelectList.map((option) => (
                        <MenuItem
                          disabled={option.value.indexOf('Pred') !== -1}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid item xs={1}>
                  <ListItemIcon
                    onClickCapture={labelVisibleToggle}
                    style={{ minWidth: '24px' }}
                  >
                    <IconButton style={{ padding: '4px 4px 4px 4px' }}>
                      {label[currentDicomIdx].visible[index] === true ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </ListItemIcon>
                </Grid>
              </Grid>
            </StyledListItem>
          ))}
      </List>
    </div>
  );
};

export default LabelList;
