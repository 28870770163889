import React from 'react';
import {
  Dialog,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';

import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
  table: {
    minWidth: 450,
  },
});

const DialogStyles = () => ({
  root: {
    background: '#90a4ae',
    height: '48px',
    margin: 0,
    padding: 2,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'black',
  },
});

const DialogTitle = withStyles(DialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography
        style={{
          height: '48px',
          padding: '8px 4px 4px 12px',
          fontSize: '1.4rem',
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles(() => ({
  root: {
    padding: 8,
    background: '#cfd8dc',
  },
}))(MuiDialogContent);

const StyledTableCell = withStyles({
  root: {
    padding: '4px 4px 4px 4px',
    height: '20px',
    backgroundColor: '#E8E8E8',
    '&.MuiTableCell-head': {
      backgroundColor: '#BABABA',
      width: '45%',
    },
    '&.MuiTableCell-body': {
      height: '16px',
      backgroundColor: '#F2F4F4',
    },
  },
})(TableCell);

function createData(key, name, detail) {
  return { key, name, detail };
}

const rows = [
  createData(0, '`', 'Label List ALL Hide Toggle'),
  createData(1, '1 ~ 9', 'Label List Select & Hide'),
  createData(2, 'P', 'Mask Preview'),
  createData(3, '[', 'Brush Size Down'),
  createData(4, ']', 'Brush Size Up'),
  createData(5, 'W', 'Brush Active / Deactive'),
  createData(6, 'E', 'Erase Active / Deactive'),
  createData(7, 'G', 'Grabcut Active / Deactive'),
  createData(8, 'Ctrl + Z', 'Undo'),
  createData(9, 'Ctrl + Shift + Z', 'Redo'),
  createData(10, 'Mouse Left Drag', 'Draw'),
  createData(11, 'Mouse Right Drag', 'Windowing'),
  createData(12, 'Mouse Wheel', 'Zoom In/Out'),
  createData(13, 'Mouse Wheel Drag', 'Pan'),
];

const HelpDialog = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog onClose={props.onClose} open={props.open}>
        <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
          LabelingTool Keyboard Shortcuts
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    <Typography variant="h6">Command</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography variant="h6">Action</Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.key}>
                    <StyledTableCell align="center">
                      <Typography variant="body1">
                        <strong>{row.name}</strong>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography variant="body2">{row.detail}</Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default HelpDialog;
