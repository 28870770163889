import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ImageSearch } from '@mui/icons-material';
import axios from 'axios';
////////////////////
// custom style
////////////////////
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    height: '177px',
    padding: '8px 8px 8px 8px',
    backgroundColor: ' rgba(255, 255, 255, 0.9)',
  },
}));

const HuValue = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const currentDicomName = useSelector(
    (state) => state.dicom.fileNames[currentDicomIdx],
  );
  const reduxDicom = useSelector((state) => state.dicom);
  const [graph, setGraph] = useState('');

  useEffect(() => {
    if (location.pathname !== '/labeling/main/VFSS') {
      let formData = new FormData();
      formData.append('objId', searchParams.get('pjId'));
      formData.append('dicomName', currentDicomName);
      if (reduxDicom.imgIds.length > 0) {
        formData.append(
          'sopUid',
          reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
        );

        if (currentDicomName !== undefined) {
          axios
            .post(`${process.env.REACT_APP_FLASK_URL}/HUHist`, formData, {
              'Content-Type': 'multipart/form-data',
            })
            .then((res) => {
              // console.log(res);
              setGraph(res.data.graph);
            })
            //error
            .catch((error) => {
              console.log(error);
              //console.log(error.response);
            });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, reduxDicom.imgIds, currentDicomIdx]);

  return (
    <div className={classes.root}>
      <Grid container>
        <ImageSearch />
        <Typography>Hu Histogram</Typography>
        <Grid container spacing={2} direction="row" justifyContent="center">
          <Grid item>
            <div>
              <img
                src={`data:image/png;base64,${graph}`}
                alt="huvalue"
                style={{
                  // position: 'absolute',
                  top: '0',
                  left: '0',
                  right: '0',
                  bottom: '0',
                  width: '100%',
                  height: '144px',
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HuValue;
