import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import dicomParser from 'dicom-parser';
import axios from 'axios';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Box,
  Collapse,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    padding: '8px 8px 8px 8px',
    backgroundColor: '#EAECEE',
  },
}));

const StyledTableCell = withStyles({
  root: {
    padding: '4px 4px 4px 4px',
    height: '20px',
    backgroundColor: '#EAECEE',
    '&.MuiTableCell-head': {
      backgroundColor: '#FAE5D3',
      width: '45%',
    },
    '&.MuiTableCell-body': {},
  },
})(TableCell);

const StyledTableCellDetail = withStyles({
  root: {
    padding: '4px 4px 4px 4px',
    height: '16px',
    backgroundColor: '#F2F4F4',
    '&.MuiTableCell-head': {
      backgroundColor: '#FDEDEC',
      width: '45%',
    },
    '&.MuiTableCell-body': {},
  },
})(TableCell);

const QuantitativeAnalysis = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const currentLabelIdx = useSelector(
    (state) => state.label[currentDicomIdx].currentlabelindex,
  );
  const labelIndexs = useSelector(
    (state) => state.label[currentDicomIdx].labelindex,
  );
  const labelNames = useSelector(
    (state) => state.label[currentDicomIdx].labelname,
  );
  const imgIds = useSelector((state) => state.dicom.imgIds);
  const currentDicomName = useSelector(
    (state) => state.dicom.fileNames[currentDicomIdx],
  );
  const reduxDicom = useSelector((state) => state.dicom);
  const { getters, state } = cornerstoneTools.getModule('segmentation');

  const [QAData, setQAData] = useState(null);
  function CollapseRow(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow onClick={() => setOpen(!open)}>
          <StyledTableCell variant="head">
            {row.Name + ' (cm²)'}
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell>{row.Area.toFixed(3)}</StyledTableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Divider />
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  component="div"
                  sx={{ backgroundColor: '#e3f0d3' }}
                >
                  {row.Name} Detail
                </Typography>
                <Divider />
                <div style={{ position: 'relative' }}>
                  <img
                    src={`data:image/png;base64,${row.graph}`}
                    alt="graph"
                    style={{
                      // position: 'absolute',
                      top: '0',
                      left: '0',
                      right: '0',
                      bottom: '0',
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
                <Table size="small" aria-label="detail">
                  <TableBody>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        Volume (cm³)
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.Volume}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        Volume (mm³)
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.Volume_mm3}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        PixelCount
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.PixelCount}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        PixelSpacing (cm)
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.PixelSpacing}
                      </StyledTableCellDetail>
                    </TableRow>
                    {/* <TableRow>
                      <StyledTableCellDetail variant="head">
                        Perimeter (mm)
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.Perimeter.toFixed(3)}
                      </StyledTableCellDetail>
                    </TableRow> */}
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        Pixel Min
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.PixelMin.toFixed(0)}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        Pixel Max
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.PixelMax.toFixed(0)}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        Pixel SD
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.PixelSD.toFixed(3)}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        Pixel Mean
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.PixelMean.toFixed(3)}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        Pixel CV(%)
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.PixelCV.toFixed(3)}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        HU Min
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.HUMin.toFixed(0)}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        HU Max
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.HUMax.toFixed(0)}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        HU SD
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.HUSD.toFixed(3)}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        HU Mean
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.HUMean.toFixed(3)}
                      </StyledTableCellDetail>
                    </TableRow>
                    <TableRow>
                      <StyledTableCellDetail variant="head">
                        HU CV(%)
                      </StyledTableCellDetail>
                      <StyledTableCellDetail>
                        {row.HUCV.toFixed(3)}
                      </StyledTableCellDetail>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  function QACalc() {
    let element = document.querySelector('.viewport-element');
    const {
      labelmaps3D, // The `Labelmap2D` for this imageId.
    } = getters.labelmaps3D(element);

    let labelLen = labelIndexs.length;
    //sever에 download한 label save
    let formData = new FormData();
    let sarcopeniaimgs = [];
    let i = 0;

    //label list가 존재할때
    if (currentLabelIdx > -1) {
      // label draw 영역 check 존재하는 경우에만 진행
      if (
        labelmaps3D[labelIndexs[i]].labelmaps2D[currentDicomIdx] !== undefined
      ) {
        //dicom tag parsing
        cornerstone
          .loadImage(imgIds[currentDicomIdx])
          .then(function (loadedImages) {
            let byteArray = loadedImages.data.byteArray;
            let dataSet = dicomParser.parseDicom(byteArray /*, options */);

            //반복하여 labellist 탐색 후 download 처리
            while (i < labelLen) {
              // labellist 중 labelmap이 그려진 경우에만 동작 나머지는 건너뜀
              //current image convert to png
              const canvas = document.createElement('canvas');
              //width, height는 해당 dicomTag 정보참조하여 설정
              let width = dataSet.int16('x00280011');
              let height = dataSet.int16('x00280010');

              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              const imgData = ctx.createImageData(width, height);
              const len = imgData.data.length; // width * height * 4;

              let saveLabelmap =
                labelmaps3D[labelIndexs[i]].labelmaps2D[currentDicomIdx]
                  .pixelData;
              const colorLut = state.colorLutTables[0];
              for (let j = 0, k = 0; j < len; j += 4, k++) {
                //data[i] ->r
                //data[i+1] ->g
                //data[i+2] ->b
                //data[i+3] ->a
                let color = saveLabelmap[k];
                if (color > 0) {
                  imgData.data[j + 0] = colorLut[color][0];
                  imgData.data[j + 1] = colorLut[color][1];
                  imgData.data[j + 2] = colorLut[color][2];
                } else {
                  imgData.data[j + 0] =
                    imgData.data[j + 1] =
                    imgData.data[j + 2] =
                      0;
                }

                imgData.data[j + 3] = 255;
              }
              ctx.putImageData(imgData, 0, 0);

              //Get data from canvas
              let img_b64_url = canvas.toDataURL();
              //labelIndexs[i] >= 3 * currentDicomIdx + sarcopeniaStartIdx &&
              //labelIndexs[i] <= 3 * currentDicomIdx + sarcopeniaStartIdx + 2
              // 위 범위에 해당하는 labelmap pixelData를 formdata로 flask 서버에 전송할 sarcopeniaimgs에 패키징함
              //img url to base64
              let img_b64 = img_b64_url.replace(
                /^data:image\/(png|jpg);base64,/,
                '',
              );
              sarcopeniaimgs.push(img_b64);
              i++;
            }

            // dicom tag parsing
            // access a string element
            // 0010,0020 : PatientID
            // 0008,0018  : SOPInstanceUID
            // 0020,000D : StudyInstanceUID
            formData.append('StudyInstanceUID', dataSet.string('x0020000d'));
          })
          .then(function () {
            formData.append('labelnames', labelNames);
            formData.append('sarcopeniaimgs', sarcopeniaimgs);
            formData.append('objId', searchParams.get('pjId'));
            formData.append('dicomName', currentDicomName);
            formData.append(
              'sopUid',
              reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
            );

            axios
              .post(`${process.env.REACT_APP_FLASK_URL}/QACalc`, formData, {
                'Content-Type': 'multipart/form-data',
              })
              .then((res) => {
                setQAData(res.data);
              })
              //error
              .catch((error) => {
                console.log(error);
                //console.log(error.response);
              });
          });
      }
    }
  }
  function convertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  }

  function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
      items.unshift(headers);
    }

    // Convert Object to JSON
    let jsonObject = JSON.stringify(items);

    let csv = convertToCSV(jsonObject);

    let exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      let link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }

    //CSV 다운로드 후 server directory 저장
    let formData = new FormData();
    formData.append('csv', blob);
    formData.append('objId', searchParams.get('pjId'));
    formData.append('dicomName', currentDicomName);
    formData.append(
      'sopUid',
      reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
    );

    axios
      .post(`${process.env.REACT_APP_FLASK_URL}/QACalcDown`, formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => {})
      //error
      .catch((error) => {
        console.log(error);
        //console.log(error.response);
      });
  }
  //QA Download
  function QADown() {
    //입력값 공백일때 download 불가 처리
    // PersonId, Condition
    if (
      document.getElementById('PersonID').value === '' ||
      document.getElementById('PersonID').value === null
    ) {
      return alert(
        '`PersonID`가 입력되지않았습니다.\n\n 입력후 Download 할 수 있습니다.',
      );
    }
    if (
      document.getElementById('Condition').value === '' ||
      document.getElementById('Condition').value === null
    ) {
      return alert(
        '`Condition`이 입력되지않았습니다.\n\n 입력후 Download 할 수 있습니다.',
      );
    }

    let headers = {
      DicomName: 'Dicom Name',
      PersonID: 'Person ID',
      // MuscleArea: 'Muscle Area',
      // MusclePixelCount: 'Muscle PixelCount',
      // MusclePixelSpacing: 'Muscle PixelSpacing',
      // MusclePerimeter: 'Muscle Perimeter',
      // MusclePixelMin: 'Muscle PixelMin',
      // MusclePixelMax: 'Muscle PixelMax',
      // MusclePixelMean: 'Muscle PixelMean',
      // MusclePixelCV: 'Muscle PixelCV',
      // MuscleHUMin: 'Muscle HUMin',
      // MuscleHUMax: 'Muscle HUMax',
      // MuscleHUMean: 'Muscle HUMean',
      // MuscleHUCV: 'Muscle HUCV',
      // SubcutaneousArea: 'Subcutaneous Area',
      // SubcutaneousPixelCount: 'Subcutaneous PixelCount',
      // SubcutaneousPixelSpacing: 'Subcutaneous PixelSpacing',
      // SubcutaneousPerimeter: 'Subcutaneous Perimeter',
      // SubcutaneousPixelMin: 'Subcutaneous PixelMin',
      // SubcutaneousPixelMax: 'Subcutaneous PixelMax',
      // SubcutaneousPixelMean: 'Subcutaneous PixelMean',
      // SubcutaneousPixelCV: 'Subcutaneous PixelCV',
      // SubcutaneousHUMin: 'Subcutaneous HUMin',
      // SubcutaneousHUMax: 'Subcutaneous HUMax',
      // SubcutaneousHUMean: 'Subcutaneous HUMean',
      // SubcutaneousHUCV: 'Subcutaneous HUCV',
      // VisceralArea: 'Visceral Area',
      // VisceralPixelCount: 'Visceral PixelCount',
      // VisceralPixelSpacing: 'Visceral PixelSpacing',
      // VisceralPerimeter: 'Visceral Perimeter',
      // VisceralPixelMin: 'Visceral PixelMin',
      // VisceralPixelMax: 'Visceral PixelMax',
      // VisceralPixelMean: 'Visceral PixelMean',
      // VisceralPixelCV: 'Visceral PixelCV',
      // VisceralHUMin: 'Visceral HUMin',
      // VisceralHUMax: 'Visceral HUMax',
      // VisceralHUMean: 'Visceral HUMean',
      // VisceralHUCV: 'Visceral HUCV',
      // MASA: 'MA/SA',
      // MAVA: 'MA/VA',
      // MASAVA: 'MA/(SA+VA)',
      // MPSP: 'MP/SP',
      // MPVP: 'MP/VP',
      // MPSPVP: 'MP/(SP+VP)',
      Age: 'Age',
      Sex: 'Sex',
      Weight: 'Weight',
      height: 'height',
      BloodType: 'Blood Type',
      Condition: 'Condition',
      BMI: 'BMI',
    };

    for (let i = 0; i < QAData.Labels.length; i++) {
      let labelName = QAData.Labels[i].Name;
      headers[labelName + '_Volume'] = labelName + '.Volume';
      headers[labelName + '_Volume_mm3'] = labelName + '.Volume_mm3';
      headers[labelName + '_Area'] = labelName + '.Area';
      headers[labelName + '_PixelCount'] = labelName + '.PixelCount';
      headers[labelName + '_PixelSpacing'] = labelName + '.PixelSpacing';
      headers[labelName + '_PixelMin'] = labelName + '.PixelMin';
      headers[labelName + '_PixelMax'] = labelName + '.PixelMax';
      headers[labelName + '_PixelMean'] = labelName + '.PixelMean';
      headers[labelName + '_PixelCV'] = labelName + '.PixelCV';
      headers[labelName + '_HUMin'] = labelName + '.HUMin';
      headers[labelName + '_HUMax'] = labelName + '.HUMax';
      headers[labelName + '_HUMean'] = labelName + '.HUMean';
      headers[labelName + '_HUCV'] = labelName + '.HUCV';
    }

    let itemsFormatted = [];
    CalcBMI();

    let row = {
      DicomName: document.getElementById('DicomName').value,
      PersonID: document.getElementById('PersonID').value,
      Age: document.getElementById('Age').value,
      Sex: document.getElementById('Sex').value,
      Weight: document.getElementById('Weight').value,
      Height: document.getElementById('Height').value,
      BloodType: document.getElementById('BloodType').value,
      Condition: document.getElementById('Condition').value,
      BMI: document.getElementById('BMI').value,
    };

    for (let i = 0; i < QAData.Labels.length; i++) {
      let labelName = QAData.Labels[i].Name;
      row[labelName + '_Volume'] = QAData.Labels[i].Volume;
      row[labelName + '_Volume_mm3'] = QAData.Labels[i].Volume_mm3;
      row[labelName + '_Area'] = QAData.Labels[i].Area;
      row[labelName + '_PixelCount'] = QAData.Labels[i].PixelCount;
      row[labelName + '_PixelSpacing'] = QAData.Labels[i].PixelSpacing;
      row[labelName + '_PixelMin'] = QAData.Labels[i].PixelMin;
      row[labelName + '_PixelMax'] = QAData.Labels[i].PixelMax;
      row[labelName + '_PixelMean'] = QAData.Labels[i].PixelMean;
      row[labelName + '_PixelCV'] = QAData.Labels[i].PixelCV;
      row[labelName + '_HUMin'] = QAData.Labels[i].HUMin;
      row[labelName + '_HUMax'] = QAData.Labels[i].HUMax;
      row[labelName + '_HUMean'] = QAData.Labels[i].HUMean;
      row[labelName + '_HUCV'] = QAData.Labels[i].HUCV;
    }

    // format the data
    itemsFormatted.push({
      ...row,
    });

    let fileTitle =
      document.getElementById('DicomName').value + '_QuantitativeAnalysis'; // or 'my-unique-title'

    exportCSVFile(headers, itemsFormatted, fileTitle);
  }

  function CalcBMI() {
    if (
      document.getElementById('Weight').value !== null &&
      document.getElementById('Height').value
    ) {
      let weight = document.getElementById('Weight').value;
      let height = document.getElementById('Height').value / 100;
      if (!isNaN(weight / (height * height))) {
        document.getElementById('BMI').value = (
          weight /
          (height * height)
        ).toFixed(3);
      } else {
        document.getElementById('BMI').value = '';
      }
    } else {
      document.getElementById('BMI').value = '';
    }
  }

  useEffect(() => {
    if (QAData) {
      document.getElementById('DicomName').value = currentDicomName;
    }
  }, [QAData, currentDicomName]);

  return (
    <div className={classes.root}>
      <Box>
        <Typography variant="h6" gutterBottom component="div">
          Quantitative Analysis
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell variant="head">Dicom Name</StyledTableCell>
                <StyledTableCell>
                  <TextField disabled id="DicomName" size="small" />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">
                  Person ID <span style={{ color: 'red' }}>*</span>
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    id="PersonID"
                    defaultValue={QAData ? QAData.personID : ''}
                    size="small"
                    value={QAData ? QAData.personID : ''}
                  />
                </StyledTableCell>
              </TableRow>
              {QAData &&
                QAData['Labels'].map((value, index) => (
                  <CollapseRow key={index} row={value} />
                ))}

              {/* <TableRow>
                <StyledTableCell variant="head">MA/SA ratio</StyledTableCell>
                <StyledTableCell>
                  {QAData &&
                    (QAData.MASA !== null ? QAData.MASA.toFixed(3) : '')}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">MA/VA ratio</StyledTableCell>
                <StyledTableCell>
                  {QAData &&
                    (QAData.MAVA !== null ? QAData.MAVA.toFixed(3) : '')}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">
                  MA/(SA+VA) ratio
                </StyledTableCell>
                <StyledTableCell>
                  {QAData &&
                    (QAData.MASAVA !== null ? QAData.MASAVA.toFixed(3) : '')}
                </StyledTableCell>
              </TableRow> */}
              {/* <TableRow>
                <StyledTableCell variant="head">MP/SP ratio</StyledTableCell>
                <StyledTableCell>
                  {QAData &&
                    (QAData.MPSP !== null ? QAData.MPSP.toFixed(3) : '')}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">MP/VP ratio</StyledTableCell>
                <StyledTableCell>
                  {QAData &&
                    (QAData.MPVP !== null ? QAData.MPVP.toFixed(3) : '')}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">
                  MP/(SP+VP) ratio
                </StyledTableCell>
                <StyledTableCell>
                  {QAData &&
                    (QAData.MPSPVP !== null ? QAData.MPSPVP.toFixed(3) : '')}
                </StyledTableCell>
              </TableRow> */}
              <TableRow>
                <StyledTableCell variant="head">나이</StyledTableCell>
                <StyledTableCell>
                  {QAData &&
                    (QAData.Age !== null ? (
                      <TextField
                        id="Age"
                        defaultValue={QAData.Age}
                        size="small"
                      />
                    ) : (
                      ''
                    ))}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">성별</StyledTableCell>
                <StyledTableCell>
                  {QAData &&
                    (QAData.Sex !== null ? (
                      <TextField
                        id="Sex"
                        defaultValue={QAData.Sex}
                        size="small"
                      />
                    ) : (
                      ''
                    ))}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">체중(kg)</StyledTableCell>
                <StyledTableCell>
                  {QAData && (
                    <TextField
                      id="Weight"
                      defaultValue={QAData.Weight || 0}
                      onChange={CalcBMI}
                      type="number"
                      size="small"
                    />
                  )}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">키(cm)</StyledTableCell>
                <StyledTableCell>
                  {QAData && (
                    <TextField
                      id="Height"
                      defaultValue={QAData.Height || 0}
                      onChange={CalcBMI}
                      type="number"
                      size="small"
                    />
                  )}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">혈액형</StyledTableCell>
                <StyledTableCell>
                  <TextField
                    id="BloodType"
                    size="small"
                    value={QAData ? QAData.BloodType : ''}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">
                  Condition <span style={{ color: 'red' }}>*</span>
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    id="Condition"
                    size="small"
                    value={QAData ? QAData.condition : ''}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell variant="head">BMI</StyledTableCell>
                <StyledTableCell>
                  {QAData && (
                    <TextField
                      id="BMI"
                      defaultValue={
                        (
                          QAData.Weight /
                          (((QAData.Height / 100) * QAData.Height) / 100)
                        ).toFixed(3) || 0
                      }
                      size="small"
                    />
                  )}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={QACalc}
                  >
                    QA Calc
                  </Button>
                </StyledTableCell>
                <StyledTableCell colSpan={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={QADown}
                    disabled={!QAData}
                  >
                    QA CSV Download
                  </Button>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default QuantitativeAnalysis;
