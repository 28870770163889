// ducks 패턴: 액션타입, 액션크리에이터, 리듀서가 모두 동일한 화일에 존재

// action type: 스트링, 모듈명/액션타입으로 표시
export const SET_SERIESIDX = 'vfss/SET_SERIESIDX';
export const UPDATE_LABELIDX = 'vfss/UPDATE_LABELIDX';
export const UPDATE_PREDICTLABEL_STATUS = 'vfss/UPDATE_PREDICTLABEL_STATUS';
export const UPDATE_LABEL_VISIBLE = 'vfss/UPDATE_LABEL_VISIBLE';
export const UPDATE_PREDICTLABEL_VISIBLE = 'vfss/UPDATE_PREDICTLABEL_VISIBLE';

// action creator: 액션을 동적으로 생성하는 펑션
export const setSeriesidx = (seriesIdx) => ({
  type: SET_SERIESIDX,
  payload: seriesIdx,
});

export const updateLabelidx = (startIdx, endIdx) => ({
  type: UPDATE_LABELIDX,
  payload: { startIdx, endIdx },
});

export const updatePredictLabelStatus = (status) => ({
  type: UPDATE_PREDICTLABEL_STATUS,
  payload: status,
});
export const updateLabelVislble = (status) => ({
  type: UPDATE_LABEL_VISIBLE,
  payload: status,
});
export const updatePredictLabelVisible = (status) => ({
  type: UPDATE_PREDICTLABEL_VISIBLE,
  payload: status,
});

const initialState = {
  seriesIdx: '',
  labelStartIdx: 0,
  labelEndIdx: 0,
  predictStatus: { n: false, p: false, a: false },
  labelVisible: true,
  predlabelVisible: true,
};

export const vfssReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERIESIDX:
      state.seriesIdx = action.payload;
      return { ...state };
    case UPDATE_LABELIDX:
      state.labelStartIdx = action.payload.startIdx;
      state.labelEndIdx = action.payload.endIdx;
      return {
        ...state,
      };
    case UPDATE_PREDICTLABEL_STATUS:
      state.predictStatus = action.payload;
      return { ...state };
    case UPDATE_LABEL_VISIBLE:
      state.labelVisible = action.payload;
      return { ...state };
    case UPDATE_PREDICTLABEL_VISIBLE:
      state.predlabelVisible = action.payload;
      return { ...state };
    default:
      return state;
  }
};
