import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import DicomSelector from './DicomSelector';
import Radius from './Radius';
import DrawColor from './DrawColor';
import HuValue from './HuValue';
import LabelBtnGroup from './LabelBtnGroup';
import LabelList from './LabelList';
import QuantitativeAnalysis from './QuantitativeAnalysis';
import VFSSProcess from './VFSSProcess';
import VFSSPredictStatus from './VFSSPredictStatus';
import VFSSLabelClass from './VFSSLabelClass';
import VFSSPredLabelClass from './VFSSPredLabelClass';
import { AppBar, Grid, Divider, Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LabelingProcess from './LabelingProcess';

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
  }),
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const RightSide = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(0);
  const [QApannel, setQApannel] = useState(true);
  const [HuHist, setHuHist] = useState(true);
  const [checkVFSSViewer, setCheckVFSSViewer] = useState(false);
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // tab pannel 비활성화 처리
  useEffect(() => {
    // console.log(location);
    if (location.pathname === '/labeling/main/VFSS') {
      setQApannel(false);
      setHuHist(false);
      if (searchParams.get('viewer') === 'vfss') {
        setCheckVFSSViewer(false);
      } else {
        setCheckVFSSViewer(true);
      }
    } else {
      setQApannel(true);
      setHuHist(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <StyledTab
            label={searchParams.get('viewer') !== 'vfss' ? 'Label' : 'VFSS'}
          />
          {QApannel && <StyledTab label="QA" />}
        </Tabs>
      </AppBar>
      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        // style={{ height: '100%' }}
      >
        <TabPanel value={value} index={0}>
          {checkVFSSViewer && (
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              alignItems="stretch"
              style={{
                backgroundColor: '#D7DBDD',
                padding: '8px 8px 8px 8px',
              }}
            >
              <Grid item>
                <Grid container>
                  <Grid item xs>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                      >
                        <Grid item xs={7} lg={6}>
                          <Radius />
                        </Grid>
                        <Divider />
                        <Grid item xs={5} lg={6}>
                          <DrawColor />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {HuHist && <Divider orientation="vertical" flexItem />}
                  {HuHist && (
                    <Grid item xs>
                      <Grid item>
                        <HuValue />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {location.pathname !== '/labeling/main/VFSS' && !checkVFSSViewer && (
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              alignItems="stretch"
              style={{
                backgroundColor: '#D7DBDD',
                padding: '8px 8px 8px 8px',
              }}
            >
              <Grid item>
                <Grid container>
                  <Grid item xs>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="stretch"
                      >
                        <Grid item xs={7} lg={6}>
                          <Radius />
                        </Grid>
                        <Divider />
                        <Grid item xs={5} lg={6}>
                          <DrawColor />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {HuHist && <Divider orientation="vertical" flexItem />}
                  {HuHist && (
                    <Grid item xs>
                      <Grid item>
                        <HuValue />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <LabelingProcess />
              </Grid>
            </Grid>
          )}

          {location.pathname === '/labeling/main/VFSS' && (
            <Grid item>
              {checkVFSSViewer && <VFSSProcess />}
              <VFSSPredictStatus />
              {checkVFSSViewer && <VFSSLabelClass />}
              <VFSSPredLabelClass />
            </Grid>
          )}
          <Grid item>
            <DicomSelector />
            {checkVFSSViewer && <LabelBtnGroup />}
            {location.pathname !== '/labeling/main/VFSS' &&
              !checkVFSSViewer && <LabelBtnGroup />}
            <Divider />
            <LabelList vfssCheck={checkVFSSViewer} />
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <QuantitativeAnalysis />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default RightSide;
