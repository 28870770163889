import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setImageIdx } from '../../../../_reducers/dicomReducer';
import { Grid, Divider, Button, Box, Typography } from '@mui/material';
import { LABEL_SERVER } from '../../../../Config';
import axios from 'axios';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {},
  seriesLabelOral: {
    minWidth: '100%',
    height: '28px',
    padding: 0,
    textTransform: 'none',
    backgroundColor: '#DD5555',
    color: 'black',
    '&:hover': { backgroundColor: '#aa2222' },
  },
  seriesLabelPharyngeal: {
    minWidth: '100%',
    height: '28px',
    padding: 0,
    textTransform: 'none',
    backgroundColor: '#EAEA48',
    color: 'black',
    '&:hover': { backgroundColor: '#b7b715' },
  },
  seriesLabelEsophageal: {
    minWidth: '100%',
    height: '28px',
    padding: 0,
    textTransform: 'none',
    backgroundColor: '#E18151',
    color: 'black',
    '&:hover': { backgroundColor: '#ae4e1e' },
  },
  seriesLabelPenetration: {
    minWidth: '100%',
    height: '28px',
    padding: 0,
    textTransform: 'none',
    backgroundColor: '#4EE47A',
    color: 'black',
    '&:hover': { backgroundColor: '#1bb148' },
  },
  seriesLabelAspiration: {
    minWidth: '100%',
    height: '28px',
    padding: 0,
    textTransform: 'none',
    backgroundColor: '#8854DE',
    color: 'black',
    '&:hover': { backgroundColor: '#5621ab' },
  },
  seriesLabelLast: {
    minWidth: '100%',
    height: '28px',
    padding: 0,
    textTransform: 'none',
    backgroundColor: 'black',
    color: 'white',
    '&:hover': { backgroundColor: '#666666' },
  },
});

const VFSSPredLabelClass = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const dicomfiles = useSelector((state) => state.dicom.imgIds);
  const selectedSeriesIdx = useSelector((state) => state.vfss.seriesIdx);
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const cinePlay = useSelector((state) => state.seg.cinePlay);

  const [VFSSClass, setVFSSClass] = useState();
  const [VFSSClassStatus, setVFSSClassStatus] = useState({
    Oral: -1,
    Pharyngeal: -1,
    Esophageal: -1,
    Penetration: -1,
    Aspiration: -1,
  });

  const VFSSclassSearch = (labelName) => {
    let urlSearchParamsObject = new URLSearchParams(dicomfiles[0]);
    let data = {
      pjId: searchParams.get('pjId'),
      studyUid: searchParams.get('stUid'),
      sopUid: urlSearchParamsObject.get('objectUID'),
    };

    axios
      .post(`${LABEL_SERVER}/VFSSPredclass`, data, {
        'Content-Type': 'multipart/form-data',
      })
      .then((res) => {
        if (res.data.status !== 'none') {
          setVFSSClassStatus({
            Oral: res.data.result['Oral'].length > 0 ? 0 : -1,
            Pharyngeal: res.data.result['Pharyngeal'].length > 0 ? 0 : -1,
            Esophageal: res.data.result['Esophageal'].length > 0 ? 0 : -1,
            Penetration: res.data.result['Penetration'].length > 0 ? 0 : -1,
            Aspiration: res.data.result['Aspiration'].length > 0 ? 0 : -1,
            Last: res.data.result['Last'].length > 0 ? 0 : -1,
          });
        }
      })
      .then(() => {
        let value = VFSSClassStatus[labelName];

        // 현재 상태와 같은경우
        if (VFSSClass[labelName][value] === currentDicomIdx) {
          //  다음 이미지로 이동
          if (value + 1 <= VFSSClass[labelName].length - 1) {
            dispatch(setImageIdx(VFSSClass[labelName][value + 1]));
            VFSSClassStatus[labelName] = value + 1;
            setVFSSClassStatus({ ...VFSSClassStatus });
          } else {
            // class의 마지막 이미지인경우 처음 idx 이미지로 이동
            VFSSClassStatus[labelName] = 0;
            dispatch(setImageIdx(VFSSClass[labelName][0]));
            setVFSSClassStatus({ ...VFSSClassStatus });
          }
        } else {
          // 현재 상태와 다른경우 표시된 dicom으로 이동
          dispatch(setImageIdx(VFSSClass[labelName][value]));
          VFSSClassStatus[labelName] = value;
          setVFSSClassStatus({ ...VFSSClassStatus });
        }
      });
  };

  // series, dicom index 변경 시 label class check
  // cine view 동작 시 예외처리 필요함.
  useEffect(() => {
    if (selectedSeriesIdx !== '') {
      let urlSearchParamsObject = new URLSearchParams(dicomfiles[0]);
      let data = {
        pjId: searchParams.get('pjId'),
        studyUid: searchParams.get('stUid'),
        sopUid: urlSearchParamsObject.get('objectUID'),
      };
      axios
        .post(`${LABEL_SERVER}/VFSSPredclass`, data, {
          'Content-Type': 'multipart/form-data',
        })
        .then((res) => {
          if (res.data.status !== 'none') {
            // dispatch(setImageIdx(res.data.idx));
            setVFSSClass(res.data.result);
            setVFSSClassStatus({
              Oral: res.data.result['Oral'].length > 0 ? 0 : -1,
              Pharyngeal: res.data.result['Pharyngeal'].length > 0 ? 0 : -1,
              Esophageal: res.data.result['Esophageal'].length > 0 ? 0 : -1,
              Penetration: res.data.result['Penetration'].length > 0 ? 0 : -1,
              Aspiration: res.data.result['Aspiration'].length > 0 ? 0 : -1,
              Last: res.data.result['Last'].length > 0 ? 0 : -1,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeriesIdx]);

  // useEffect(() => {
  //   if (!cinePlay) {

  //     let urlSearchParamsObject = new URLSearchParams(dicomfiles[0]);
  //     let data = {
  //       pjId: searchParams.get('pjId'),
  //       studyUid: searchParams.get('stUid'),
  //       sopUid: urlSearchParamsObject.get('objectUID'),
  //     };
  //     axios
  //       .post(`${LABEL_SERVER}/VFSSPredclass`, data, {
  //         'Content-Type': 'multipart/form-data',
  //       })
  //       .then((res) => {
  //         if (res.data.status !== 'none') {
  //           // dispatch(setImageIdx(res.data.idx));
  //           setVFSSClass(res.data.result);
  //         }
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentDicomIdx, cinePlay]);
  return (
    <div style={{ backgroundColor: '#D7DBDD' }}>
      <Divider />
      <Box style={{ padding: 4, margin: 4 }}>
        {VFSSClass && (
          <Grid
            container
            columns={13}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={2}>
              <Typography variant="subtitle2" component="h6" align="center">
                PredLbl
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                className={classes.seriesLabelOral}
                disabled={!(VFSSClass['Oral'].length > 0)}
                onClick={(e) => {
                  e.stopPropagation();
                  VFSSclassSearch('Oral');
                }}
              >
                <div
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                  }}
                >
                  <div style={{ marginLeft: '4px', float: 'left' }}>O</div>
                  <div>
                    {VFSSClass['Oral'].length > 0 &&
                      VFSSClassStatus['Oral'] +
                        1 +
                        '/' +
                        VFSSClass['Oral'].length}
                  </div>
                </div>
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                className={classes.seriesLabelPharyngeal}
                disabled={!(VFSSClass['Pharyngeal'].length > 0)}
                onClick={(e) => {
                  e.stopPropagation();
                  VFSSclassSearch('Pharyngeal');
                }}
              >
                <div
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                  }}
                >
                  <div style={{ marginLeft: '4px', float: 'left' }}>Ph</div>
                  <div>
                    {VFSSClass['Pharyngeal'].length > 0 &&
                      VFSSClassStatus['Pharyngeal'] +
                        1 +
                        '/' +
                        VFSSClass['Pharyngeal'].length}
                  </div>
                </div>
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                className={classes.seriesLabelEsophageal}
                disabled={!(VFSSClass['Esophageal'].length > 0)}
                onClick={(e) => {
                  e.stopPropagation();
                  VFSSclassSearch('Esophageal');
                }}
              >
                <div
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                  }}
                >
                  <div style={{ marginLeft: '4px', float: 'left' }}>E</div>
                  <div>
                    {VFSSClass['Esophageal'].length > 0 &&
                      VFSSClassStatus['Esophageal'] +
                        1 +
                        '/' +
                        VFSSClass['Esophageal'].length}
                  </div>
                </div>
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                className={classes.seriesLabelPenetration}
                disabled={!(VFSSClass['Penetration'].length > 0)}
                onClick={(e) => {
                  e.stopPropagation();
                  VFSSclassSearch('Penetration');
                }}
              >
                <div
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                  }}
                >
                  <div style={{ marginLeft: '4px', float: 'left' }}>P</div>
                  <div>
                    {VFSSClass['Penetration'].length > 0 &&
                      VFSSClassStatus['Penetration'] +
                        1 +
                        '/' +
                        VFSSClass['Penetration'].length}
                  </div>
                </div>
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                className={classes.seriesLabelAspiration}
                disabled={!(VFSSClass['Aspiration'].length > 0)}
                onClick={(e) => {
                  e.stopPropagation();
                  VFSSclassSearch('Aspiration');
                }}
              >
                <div
                  style={{
                    width: '100%',
                    verticalAlign: 'middle',
                  }}
                >
                  <div style={{ marginLeft: '4px', float: 'left' }}>A</div>
                  <div>
                    {VFSSClass['Aspiration'].length > 0 &&
                      VFSSClassStatus['Aspiration'] +
                        1 +
                        '/' +
                        VFSSClass['Aspiration'].length}
                  </div>
                </div>
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                className={classes.seriesLabelLast}
                disabled={!(VFSSClass['Last'].length > 0)}
                onClick={(e) => {
                  e.stopPropagation();
                  VFSSclassSearch('Last');
                }}
              >
                Last
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
      <Divider />
    </div>
  );
};

export default VFSSPredLabelClass;
