import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectDicomFiles,
  setImageRowCol,
} from '../../../_reducers/dicomReducer';
import {
  setSeries,
  addSeries,
  addSeriesDcmName,
  resetSeries,
  sortSeries,
  modeSeries,
} from '../../../_reducers/seriesReducer';
import { PROJECT_SERVER } from '../../../Config';
import axios from 'axios';

import dicomParser from 'dicom-parser';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  LinearProgress,
  CircularProgress,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// Externals
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
// cornerstoneTools.init();

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  mrtopright: {
    position: 'absolute',
    fontSize: '16px',
    top: 3,
    right: 3,
    color: 'white',
  },
  mrtopleft: {
    position: 'absolute',
    fontSize: '16px',
    top: 3,
    left: 3,
    color: 'white',
  },
  card: {
    border: '2px solid #33FF3C',
  },
  none: {
    border: '1px solid black',
  },
});

const MainPage = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const series = useSelector((state) => state.series);
  const [fileload, setFileload] = useState(false);
  const [progress, setProgress] = useState(0);

  const displayThumnail = async (element, imageIds) => {
    if (imageIds !== undefined) {
      // const image = await cornerstone.loadImage(imageIds);
      try {
        cornerstone.displayImage(
          element,
          await cornerstone.loadImage(imageIds),
        );
        setProgress(100);
      } catch (error) {
        console.log(error);
      }
    }
  };
  function timer(ms) {
    return new Promise((res) => setTimeout(res, ms));
  }
  //thumnail 이미지 처리
  useEffect(() => {
    if (fileload) {
      let seriesLen = series.length;
      let checkArr = [];
      //thumnail 이미지 생성 조건 : 처음 file 선택하여 load 시 or dicomselect Page에서 뒤로가기로 접근 시
      if (seriesLen > 0) {
        for (let i = 0; i < seriesLen; i++) {
          let thumnailEle = document.querySelector('#thumnail' + String(i));

          cornerstone.enable(thumnailEle);
          displayThumnail(thumnailEle, window.thumnail[i]);

          checkArr.push(false);
        }
      }
    }
  }, [fileload, series.length]);

  // function arrayBufferToBase64(arr) {
  //   var binary = '';
  //   let buffer = new Uint8Array(arr.buffer, arr.byteOffset, arr.byteLength);
  //   var bytes = new Uint8Array(buffer);
  //   var len = bytes.byteLength;
  //   for (var i = 0; i < len; i++) {
  //     binary += String.fromCharCode(bytes[i]);
  //   }
  //   return window.btoa(binary);
  // }

  function update(completed, total) {
    setProgress((completed / total) * 100);
  }

  //db dicom file load
  useEffect(() => {
    dispatch(resetSeries());
    function progressPromise(promises, tickCallback) {
      let len = promises.length;
      let progress = 0;

      function tick(promise) {
        promise.then(function () {
          progress++;
          tickCallback(progress, len);
        });
        return promise;
      }

      return Promise.all(promises.map(tick))
        .then(function (loadedImages) {
          // console.log(loadedImages);
          let loadedCnt = loadedImages.length;
          let i = 0;
          let seriesNumArr = [];
          let series = [];
          let seriesIdx = 0;

          while (i < loadedCnt) {
            let byteArray = loadedImages[i].data.byteArray;
            let dataSet = dicomParser.parseDicom(byteArray /*, options */);

            // dicom tag parsing
            // access a string element
            // x0020000e : Series Instance UID
            // x0008103e  : Series Description Attribute
            // x00200013 : InstanceNumber
            let seriesNum = dataSet.string('x0020000e');
            let seriesDesc = dataSet.string('x0008103e');
            let instanceNum = dataSet.string('x00200013');
            let studyNum = dataSet.string('x0020000d');
            // rgb file series 구분 처리
            let photometric = dataSet.string('x00280004');
            // studyDescription
            let studyDescription = dataSet.string('x00081030');
            let bodyPart = dataSet.string('x00180015');
            if (photometric !== 'RGB') {
              // SeriesNumArr : 선택한 file들의 series 정보 저장 변수
              // 새로운 series 가 추가될때마다 해당 배열에 저장하여 관리
              let find = seriesNumArr.find((num) => num === seriesNum);
              let findIdx = seriesNumArr.findIndex((num) => num === seriesNum);
              // console.log(find, findIdx);

              let imgOrientation = '';
              if (dataSet.string('x00200037') !== undefined) {
                imgOrientation = dataSet.string('x00200037').split('\\');
                // 각 value 실수인경우 정수로 변경
                imgOrientation = imgOrientation.map(function (value) {
                  return parseInt(value, 10);
                });
              }

              // series 처음 등록 일때 분기처리
              if (find === undefined) {
                seriesNumArr.push(seriesNum);
                series = {
                  studyNumber: studyNum,
                  studyDescription: studyDescription,
                  seriesNumber: seriesNum,
                  seriesDescription: seriesDesc,
                  seriesBodyPart: bodyPart,
                  seriesOrientation: [imgOrientation],
                  seriesOrientMode: 0,
                  seriesImgIds: [loadedImages[i].imageId],
                  seriesDcmName: [window.dicomFileNames[i]],
                  seriesInstanceNum: [instanceNum],
                };
                // addSeries : redux이용하여 series 저장 공간 생성
                // serSeries : seriesIdx 값을 참조하여 series 양식대로 저장

                dispatch(addSeries());
                dispatch(setSeries(seriesIdx++, series));
                window.thumnail.push(loadedImages[i].imageId);
              }
              // 이전 series number와 같으면 ImgIds만 추가
              // param : name -> window dicomIds 객체로 부터 ImgIds 검색하여 추출하기 위한 param
              else if (findIdx > -1) {
                dispatch(
                  addSeriesDcmName(
                    findIdx,
                    window.dicomFileNames[i],
                    loadedImages[i].imageId,
                    instanceNum,
                    imgOrientation,
                  ),
                );
              }
            }
            i++;
          }
        })
        .then(function () {
          dispatch(sortSeries());
          dispatch(modeSeries());
          setFileload(true);
        });
    }

    let studyUID = searchParams.get('stUid');
    if (!studyUID) studyUID = props.studyUID;

    axios
      .get(
        `${PROJECT_SERVER}/check?img=${studyUID}&pjId=${searchParams.get(
          'pjId',
        )}`,
      )
      .then(async (res) => {
        // db로 부터 dicom file load를 필요한 dicom count check
        let filesLen = res.data.cnt;
        let dicomTags = res.data.dicomTags;
        window.thumnail = [];
        window.dicomFileNames = [];
        let promiseArr = [];

        let i = 0;
        //series reducer 초기화
        dispatch(resetSeries());

        // dicom image row,col set
        dispatch(
          setImageRowCol(dicomTags['00280010'][0], dicomTags['00280011'][0]),
        );

        if (filesLen > 0) {
          // console.log(Math.floor(+new Date() / 1000));
          //file 개수만큼 반복처리
          while (i < filesLen) {
            // let imgIds = `wadouri:${PROJECT_SERVER}/loadfiles?img=${searchParams.get(
            //   'id',
            // )}&idx=${i}`;
            let imgIds = `wadouri:${
              process.env.REACT_APP_FASTIFY_URL
            }/wado?requestType=WADO&studyUID=${
              dicomTags['0020000D'][i]
            }&seriesUID=${dicomTags['0020000E'][i]}&objectUID=${
              dicomTags['00080018'][i]
            }&pjcate=${searchParams.get('pjcate')}&pjId=${searchParams.get(
              'pjId',
            )}&contentType=application/dicom&transferSyntax=*`;
            promiseArr.push(cornerstone.loadImage(imgIds));
            await timer(25);
            i++;
          }
          window.dicomFileNames = res.data.fileNames;
          // cornerstone.loadImage promise 배열에 저장 후 모두 완료시 data 추출하여 seriesReducer에  dispatch
          progressPromise(promiseArr, update).then(function (results) {
            setProgress(100);
          });
        } else {
          //error message
          setFileload(false);
          dispatch(selectDicomFiles([]));
        }
      });
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            fontSize={20}
            sx={{ color: 'white', fontWeight: 'bold' }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  // function reset() {
  //   window.location.reload(false);
  // }

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <div className={classes.root}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ height: '4px' }}
          />
        </div>
        {/* <StyledButton onClick={reset}>reset</StyledButton> */}
        {progress !== 100 && (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <div style={{ marginTop: '200px', display: 'inline-block' }}>
              <CircularProgressWithLabel value={progress} size="5rem" />
            </div>
          </div>
        )}

        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item xs={1}></Grid>
          <Grid
            id="cardlist"
            item
            xs={10}
            spacing={5}
            container
            direction="row"
            marginTop={1}
          >
            {series &&
              series.map((value, index) => (
                <Grid item xs={3} key={index}>
                  <Card
                    className={`${
                      JSON.stringify(value.seriesOrientMode) ===
                      JSON.stringify(['1', '0', '0', '0', '1', '0'])
                        ? classes.card
                        : classes.none
                    }`}
                    onClick={(e) => {
                      let element = document.querySelector(
                        `#thumnailCheckbox${index}`,
                      );
                      element.checked = !element.checked;
                    }}
                  >
                    <CardActionArea>
                      <div
                        id={`thumnail${index}`}
                        className="cornerstone-element"
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        style={{
                          width: '100%',
                          height: '250px',
                          backgroundColor: 'black',
                          float: 'center',
                        }}
                      >
                        <div id="mrtopleft" className={classes.mrtopleft}>
                          <input
                            id={`thumnailCheckbox${index}`}
                            type="checkbox"
                            onChange={(e) => {
                              e.target.checked = !e.target.checked;
                            }}
                            style={{
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        </div>
                        <div id="mrtopright" className={classes.mrtopright}>
                          <span>
                            {'( imgs: ' +
                              series[index].seriesImgIds.length +
                              ' )'}
                          </span>
                        </div>
                      </div>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h3">
                          {value.seriesDescription}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MainPage;
