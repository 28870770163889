// ducks 패턴: 액션타입, 액션크리에이터, 리듀서가 모두 동일한 화일에 존재

// action type: 스트링, 모듈명/액션타입으로 표시
export const UPDATE_BRUSHCOLORIDX = 'rightside/UPDATE_BRUSHCOLORIDX';
export const UPDATE_BRUSHSIZE = 'rightside/UPDATE_BRUSHSIZE';
export const ADD_LABELMAPIDX = 'rightside/ADD_LABELMAPIDX';
export const DEL_LABELMAPIDX = 'rightside/DEL_LABELMAPIDX';
export const UPDATE_LABELMAPMAXIDX = 'rightside/LABELMAP_MAXIDX';
export const UPDATE_SEGMENTSHIDE = 'rightside/UPDATE_SEGMENTSHIDE';
export const UPDATE_CINEPLAY = 'rightside/UPDATE_CINEPLAY';
export const UPDATE_CINEFPS = 'rightside/UPDATE_CINEFPS';

// action creator: 액션을 동적으로 생성하는 펑션
export const updateBrushcolorIdx = (brushColorIndex) => ({
  type: UPDATE_BRUSHCOLORIDX,
  payload: brushColorIndex,
});

export const updateBrushSize = (brushSize) => ({
  type: UPDATE_BRUSHSIZE,
  payload: brushSize,
});

export const addLabelmapIdx = (labelmapIndexs) => ({
  type: ADD_LABELMAPIDX,
  payload: labelmapIndexs,
});

export const updateLabelampMaxIdx = (labelmapMaxIndex) => ({
  type: UPDATE_LABELMAPMAXIDX,
  payload: labelmapMaxIndex,
});
export const updateSegmentsHide = (segmentsHideStatus) => ({
  type: UPDATE_SEGMENTSHIDE,
  payload: segmentsHideStatus,
});

export const updateCinePlay = (cineplayStatus) => ({
  type: UPDATE_CINEPLAY,
  payload: cineplayStatus,
});

export const updateCineFPS = (cineplayFPSStatus) => ({
  type: UPDATE_CINEFPS,
  payload: cineplayFPSStatus,
});

const initialState = {
  labelmapIndexs: [],
  brushColorIndex: 1,
  brushSize: 3,
  labelmapMaxIndex: 0,
  segmentsHide: false,
  cinePlay: false,
  cineFPS: 5,
};

export const segmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BRUSHCOLORIDX:
      return { ...state, brushColorIndex: action.payload };

    case UPDATE_BRUSHSIZE:
      return { ...state, brushSize: action.payload };
    case ADD_LABELMAPIDX:
      state.labelmapIndexs = [...state.labelmapIndexs, action.payload];
      return state;
    case DEL_LABELMAPIDX:
      return state;
    case UPDATE_LABELMAPMAXIDX:
      return { ...state, labelmapMaxIndex: action.payload };
    case UPDATE_SEGMENTSHIDE:
      return { ...state, segmentsHide: action.payload };
    case UPDATE_CINEPLAY:
      return { ...state, cinePlay: action.payload };
    case UPDATE_CINEFPS:
      return { ...state, cineFPS: action.payload };
    default:
      return state;
  }
};
