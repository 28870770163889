import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  addLabelIndex,
  addLabelName,
  delLabelIndex,
  delLabelName,
  decreaseCurrLabelIndex,
  selectCurrLabelIndex,
  addSlicPos,
  delSlicPos,
} from '../../../../_reducers/labelReducer';
import { updateLabelampMaxIdx } from '../../../../_reducers/segmentReducer';
import {
  updateLabelVislble,
  updatePredictLabelVisible,
} from '../../../../_reducers/vfssReducer';
import { Button, Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import axios from 'axios';
import JSZip from 'jszip';
import saveAs from 'file-saver';

import { dataURLtoFile } from '../../../../utils/Utils';
import { useSnackbar } from 'notistack';

import Dexie from 'dexie';

const db = new Dexie('VFSS');
db.version(1).stores({
  labels: '[dicomName+labelName]', // Primary key and indexed props
});
db.version(2).stores({
  predlabels: '[dicomName+labelName]', // Primary key and indexed props
});

const StyledButton = withStyles({
  root: {
    //background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: '#56d4ca',
    borderRadius: 3,
    border: 0,
    color: 'black',
    '&:hover': {
      backgroundColor: '#00736b',
      color: '#FFF',
    },
    height: 32,
    width: '90%',
    margin: '8px 0px 8px 0px',
    boxShadow: '0 3px 5px 2px rgba(3, 146, 127, .3)',
    fontSize: 14,
    textTransform: 'none',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const colorList = [1, 2, 8, 9, 13, 16, 25];
const predLabelColorList = [65521, 63286, 63673, 63657, 61789, 61784, 63664];
let segHideOnArr = [];
let segHideOffArr = [];
let segPredHideOnArr = [];
let segPredHideOffArr = [];

for (let i = 0; i < colorList.length; i++) {
  segHideOnArr[colorList[i]] = true;
  segHideOffArr[colorList[i]] = false;
}
for (let i = 0; i < predLabelColorList.length; i++) {
  segPredHideOnArr[predLabelColorList[i]] = true;
  segPredHideOffArr[predLabelColorList[i]] = false;
}
const LabelBtnGroup = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentDicomIdx = useSelector(
    (state) => state.dicom.currentImageIdIndex,
  );
  const dicom = useSelector((state) => state.dicom.imgIds);
  const currentDicomRow = useSelector((state) => state.dicom.imageRow);
  const currentDicomCol = useSelector((state) => state.dicom.imageCol);
  const label = useSelector((state) => state.label);

  const togglebuttonState = useSelector((state) => state.button);
  const labelmapMax = useSelector((state) => state.seg.labelmapMaxIndex);
  const userId = useSelector((state) => state.user.userData._id);
  const currentDicomName = useSelector(
    (state) => state.dicom.fileNames[currentDicomIdx],
  );
  const labelVisible = useSelector((state) => state.vfss.labelVisible);
  const predlabelVisible = useSelector((state) => state.vfss.predlabelVisible);
  const { enqueueSnackbar } = useSnackbar();

  const { setters, getters, state } =
    cornerstoneTools.getModule('segmentation');
  let element;
  let activeLabelIndex;
  const reduxDicom = useSelector((state) => state.dicom);

  //labellist add/del button state
  const [addBtnDisable, SetAddBtnDisable] = useState(true);
  const [delBtnDisable, SetDelBtnDisable] = useState(true);

  //label add btn event
  function AddLabel() {
    // console.log('Add Label');
    // slic 가이드라인 idx : 0
    element = document.querySelector('.viewport-element');

    let arrMin = Math.min(...label[currentDicomIdx].labelindex);
    let arrMax = Math.max(...label[currentDicomIdx].labelindex);
    let newArr = [];
    for (let i = arrMin; i <= arrMax; i++) {
      newArr.push(i);
    }

    let difference = newArr.filter(
      (x) => !label[currentDicomIdx].labelindex.includes(x),
    );
    let indexMin = Math.min(...difference);
    let indexMax = Math.max(...label[currentDicomIdx].labelindex);

    if (indexMin === Infinity) {
      dispatch(
        selectCurrLabelIndex(
          label[currentDicomIdx].labelindex.length,
          currentDicomIdx,
        ),
      );

      //vfss 인경우 Labelname 예외처리
      if (location.pathname === '/labeling/main/VFSS') {
        let len = 6;
        // vfss label delete 후 label list 개수 0일때 예외처리
        if (indexMax !== -Infinity) {
          len = indexMax + 6;
        }

        dispatch(updateLabelampMaxIdx(len));
        dispatch(addLabelIndex(len, currentDicomIdx));
        setters.activeLabelmapIndex(element, len);
        dispatch(addLabelName('', currentDicomIdx));
      } else {
        dispatch(updateLabelampMaxIdx(labelmapMax + 1));
        dispatch(addLabelIndex(labelmapMax + 1, currentDicomIdx));
        setters.activeLabelmapIndex(element, labelmapMax + 1);
        dispatch(
          addLabelName(
            'label ' + (label[currentDicomIdx].labelname.length + 1),
            currentDicomIdx,
          ),
        );
      }
    } else {
      dispatch(
        selectCurrLabelIndex(
          label[currentDicomIdx].labelindex.length,
          currentDicomIdx,
        ),
      );
      dispatch(updateLabelampMaxIdx(indexMax));
      dispatch(addLabelIndex(indexMin, currentDicomIdx));
      setters.activeLabelmapIndex(element, indexMin);
      dispatch(addLabelName('label ' + indexMin, currentDicomIdx));
    }

    dispatch(addSlicPos(currentDicomIdx));
    // setters.activeSegmentIndex(element, brushColor);
    // console.log(getters.labelmaps3D(element).labelmaps3D);
  }

  //label del btn event
  function DelLabel(label, currentDicomIdx) {
    // console.log('Del Label');
    dispatch(
      delSlicPos(label[currentDicomIdx].currentlabelindex, currentDicomIdx),
    );
    dispatch(
      delLabelIndex(label[currentDicomIdx].currentlabelindex, currentDicomIdx),
    );
    dispatch(
      delLabelName(label[currentDicomIdx].currentlabelindex, currentDicomIdx),
    );
    dispatch(updateLabelampMaxIdx(labelmapMax - 1));

    if (label[currentDicomIdx].currentlabelindex === 0) {
      if (label[currentDicomIdx].labelname.length !== 0) {
        dispatch(selectCurrLabelIndex(0, currentDicomIdx));
      } else {
        dispatch(selectCurrLabelIndex(-1, currentDicomIdx));
      }
    } else if (label[currentDicomIdx].currentlabelindex > 0) {
      dispatch(decreaseCurrLabelIndex(currentDicomIdx));
    }

    element = document.querySelector('.viewport-element');
    activeLabelIndex = getters.activeLabelmapIndex(element);

    //선택 라벨 삭제.
    const { labelmap2D } = getters.labelmap2D(element);

    //setters.deleteSegment 로는 active되어진 segments 만 삭제가 가능
    // -> 여러 색상으로 labelmap이 draw 되었을 경우 모두 삭제 처리 해주어야 함.
    //labelmapArr -> 배열 i번째 인자부터 0번째 인자까지 순차탐색하여 setters.deleteSegment 실행
    let seg;
    let segmentCnt = labelmap2D.segmentsOnLabelmap.length;
    const labelmapArr = labelmap2D.segmentsOnLabelmap;
    for (let i = 0; i < segmentCnt; i++) {
      seg = labelmapArr[1];
      setters.deleteSegment(element, seg, activeLabelIndex);
    }

    //pixel data 0으로 안채워지는 경우 한번더 0으로 채움 - label 삭제
    labelmap2D.pixelData.fill(0);

    //이전 labelmap select
    if (label[currentDicomIdx].currentlabelindex === 0) {
      if (label[currentDicomIdx].labelname.length !== 0) {
        setters.activeLabelmapIndex(
          element,
          label[currentDicomIdx].labelindex[0],
        );
      } else {
        setters.activeLabelmapIndex(element, 0);
      }
    } else if (label[currentDicomIdx].currentlabelindex > 0) {
      setters.activeLabelmapIndex(
        element,
        label[currentDicomIdx].labelindex[
          label[currentDicomIdx].currentlabelindex
        ],
      );
    }
    cornerstone.updateImage(element);
  }

  function DownloadLabel() {
    element = document.querySelector('.viewport-element');
    const {
      labelmaps3D, // The `Labelmap2D` for this imageId.
    } = getters.labelmaps3D(element);

    let labelLen = label[currentDicomIdx].labelindex.length;

    //sever에 download한 label save
    let formData = new FormData();
    let sarcopeniaimgs = [];
    let i = 0;
    //image파일 압출할 zip 객체 생성
    let zip = new JSZip();
    let labelZip = new FormData();

    //label list가 존재할때
    if (label[currentDicomIdx].currentlabelindex > -1) {
      //반복하여 labellist 탐색 후 download 처리
      while (i < labelLen) {
        // labellist 중 labelmap이 그려진 경우에만 동작 나머지는 건너뜀
        if (
          labelmaps3D[label[currentDicomIdx].labelindex[i]].labelmaps2D.length >
          0
        ) {
          //current image convert to png
          const canvas = document.createElement('canvas');

          //vfss 일때 label 크기설정을 위한 예외처리
          let width =
            currentDicomCol === 0 || currentDicomCol === undefined
              ? 512
              : currentDicomCol;
          let height =
            currentDicomRow === 0 || currentDicomRow === undefined
              ? 512
              : currentDicomRow;

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          const imgData = ctx.createImageData(width, height);
          const len = imgData.data.length; // width * height * 4;

          let saveLabelmap =
            labelmaps3D[label[currentDicomIdx].labelindex[i]].labelmaps2D[
              currentDicomIdx
            ].pixelData;
          const colorLut = state.colorLutTables[0];
          for (let j = 0, k = 0; j < len; j += 4, k++) {
            //data[i] ->r
            //data[i+1] ->g
            //data[i+2] ->b
            //data[i+3] ->a
            let color = saveLabelmap[k];
            if (color > 0) {
              imgData.data[j + 0] = colorLut[color][0];
              imgData.data[j + 1] = colorLut[color][1];
              imgData.data[j + 2] = colorLut[color][2];
            } else {
              imgData.data[j + 0] =
                imgData.data[j + 1] =
                imgData.data[j + 2] =
                  0;
            }

            imgData.data[j + 3] = 255;
          }
          ctx.putImageData(imgData, 0, 0);

          //Get data from canvas
          let img_b64_url = canvas.toDataURL();
          //labelIndexs[i] >= 3 * currentDicomIdx + sarcopeniaStartIdx &&
          //labelIndexs[i] <= 3 * currentDicomIdx + sarcopeniaStartIdx + 2
          // 위 범위에 해당하는 labelmap pixelData를 formdata로 flask 서버에 전송할 sarcopeniaimgs에 패키징함
          //img url to base64
          let img_b64 = img_b64_url.replace(
            /^data:image\/(png|jpg);base64,/,
            '',
          );

          if (
            labelmaps3D[label[currentDicomIdx].labelindex[i]].labelmaps2D[
              currentDicomIdx
            ].hasOwnProperty('sarcopenia')
          ) {
            sarcopeniaimgs.push(img_b64);
          }
          // SaveAll 시 다운로드할 zip파일에 이미지 추가
          zip.file(
            label[currentDicomIdx].labelname[i] +
              '_' +
              currentDicomName +
              '.png',
            img_b64,
            {
              base64: true,
            },
          );
        }
        i++;
      }

      formData.append('sarcopeniaimgs', sarcopeniaimgs);
      formData.append('objId', searchParams.get('pjId'));
      formData.append('dicomName', currentDicomName);
      let voi = cornerstone.getViewport(element).voi;
      formData.append('voi', JSON.stringify(voi));
      formData.append(
        'sopUid',
        reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
      );

      if (sarcopeniaimgs.length === 3) {
        axios
          .post(`${process.env.REACT_APP_FLASK_URL}/DownLabel`, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then((response) => {
            //이미지 flask 서버에 전송 후 result 가져옴
            // console.log('finish');
            //console.log(response);
            //console.log(response.data);
            if (response.data.result !== 'none') {
              //zip으로 압축하여 download
              zip.file(
                response.data.filename + '_normal.png',
                response.data.normal_b64,
                {
                  base64: true,
                },
              );
              zip.file(
                response.data.filename + '_maskTotal.png',
                response.data.masktotal_b64,
                {
                  base64: true,
                },
              );
              zip.file(
                response.data.filename + '_heatmap.png',
                response.data.heatmap_b64,
                {
                  base64: true,
                },
              );
              zip.generateAsync({ type: 'blob' }).then(function (content) {
                //다운로드할 label zip file flask 로 전송

                labelZip.append('filename', currentDicomName);
                labelZip.append('labelzip', content);

                // axios
                //   .post(`http://localhost:5000/SaveLabelZip`, labelZip, {
                //     // .post(`https://label.wonrcdm.org/SaveLabelZip`, labelZip, {
                //     'Content-Type': 'multipart/form-data',
                //   })
                //   .then((response) => {});
                // see FileSaver.js
                saveAs(content, currentDicomName + '.zip');
              });
            } else {
              // alert('Result : none');
            }
          })
          //error
          .catch((error) => {
            console.log(error);
            //console.log(error.response);
          });
      } else if (location.pathname === '/labeling/main/VFSS') {
        // vfss 인경우 현재 jpgfile 가져와 압축에 추가처리
        axios
          .post(`${process.env.REACT_APP_FLASK_URL}/VFSSLabelZip`, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then((response) => {
            zip.file(
              response.data.filename + '.jpg',
              response.data.dcmjpg_b64,
              {
                base64: true,
              },
            );

            zip.generateAsync({ type: 'blob' }).then(function (content) {
              // see FileSaver.js
              saveAs(content, currentDicomName + '.zip');
            });
          });
      } else {
        //일반적인 label save all
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          // see FileSaver.js
          saveAs(content, currentDicomName + '.zip');
        });
      }
    }
  }

  const SaveLabel = (
    label,
    currentDicomIdx,
    currentDicomCol,
    currentDicomRow,
  ) => {
    element = document.querySelector('.viewport-element');
    const {
      labelmaps3D, // The `Labelmap2D` for this imageId.
    } = getters.labelmaps3D(element);

    let labelLen = label[currentDicomIdx].labelindex.length;

    //sever에 download한 label save
    let formData = new FormData();
    let labelnames = [];
    let i = 0;

    //label list가 존재할때
    if (label[currentDicomIdx].currentlabelindex > -2) {
      // labelname Empty or labelname Equal Check
      for (let k = 6; k < label[currentDicomIdx].labelname.length; k++) {
        if (label[currentDicomIdx].labelname[k].length === 0) {
          enqueueSnackbar(
            '이름이 공백인 Label이 존재합니다. 저장할 수 없습니다.',
            {
              variant: 'warning',
              autoHideDuration: 1200,
            },
          );

          return;
        }
      }
      let labelUniqueLen = Array.from(
        new Set(label[currentDicomIdx].labelname),
      ).length;
      if (labelUniqueLen !== labelLen) {
        enqueueSnackbar(
          '이름이 동일한 Label이 존재합니다. 저장할 수 없습니다.',
          {
            variant: 'warning',
            autoHideDuration: 1200,
          },
        );

        return;
      } else {
        //반복하여 labellist 탐색 후 download 처리
        for (let i = 0; i < labelLen; i++) {
          let currLabelIdx = label[currentDicomIdx].labelindex[i];
          // vfss 인경우 예외처리 : label index 6부터 시작
          if (location.pathname === '/labeling/main/VFSS') {
            if (currLabelIdx >= 6) {
              // labellist 중 labelmap이 그려진 경우에만 동작 나머지는 건너뜀
              if (labelmaps3D[currLabelIdx].labelmaps2D.length > 0) {
                //current image convert to png
                const canvas = document.createElement('canvas');

                //vfss 일때 label 크기설정을 위한 예외처리
                let width =
                  currentDicomCol === 0 || currentDicomCol === undefined
                    ? 512
                    : currentDicomCol;
                let height =
                  currentDicomRow === 0 || currentDicomRow === undefined
                    ? 512
                    : currentDicomRow;

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                const imgData = ctx.createImageData(width, height);
                const len = imgData.data.length; // width * height * 4;

                let saveLabelmap =
                  labelmaps3D[currLabelIdx].labelmaps2D[currentDicomIdx]
                    .pixelData;

                const colorLut = state.colorLutTables[0];
                for (let j = 0, k = 0; j < len; j += 4, k++) {
                  //data[i] ->r
                  //data[i+1] ->g
                  //data[i+2] ->b
                  //data[i+3] ->a
                  let color = saveLabelmap[k];

                  if (color > 0) {
                    imgData.data[j + 0] = colorLut[color][0];
                    imgData.data[j + 1] = colorLut[color][1];
                    imgData.data[j + 2] = colorLut[color][2];
                  } else {
                    imgData.data[j + 0] =
                      imgData.data[j + 1] =
                      imgData.data[j + 2] =
                        0;
                  }

                  imgData.data[j + 3] = 255;
                }
                ctx.putImageData(imgData, 0, 0);

                //Get data from canvas
                let img_b64_url = canvas.toDataURL();
                let file = dataURLtoFile(img_b64_url, 'label.png');

                formData.append(label[currentDicomIdx].labelname[i], file);
                labelnames.push(label[currentDicomIdx].labelname[i]);
              }
            }
          } else {
            // labellist 중 labelmap이 그려진 경우에만 동작 나머지는 건너뜀
            if (labelmaps3D[currLabelIdx].labelmaps2D.length > 0) {
              //current image convert to png
              const canvas = document.createElement('canvas');

              //vfss 일때 label 크기설정을 위한 예외처리
              let width =
                currentDicomCol === 0 || currentDicomCol === undefined
                  ? 512
                  : currentDicomCol;
              let height =
                currentDicomRow === 0 || currentDicomRow === undefined
                  ? 512
                  : currentDicomRow;

              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d');
              const imgData = ctx.createImageData(width, height);
              const len = imgData.data.length; // width * height * 4;

              let saveLabelmap =
                labelmaps3D[currLabelIdx].labelmaps2D[currentDicomIdx]
                  .pixelData;
              const colorLut = state.colorLutTables[0];
              for (let j = 0, k = 0; j < len; j += 4, k++) {
                //data[i] ->r
                //data[i+1] ->g
                //data[i+2] ->b
                //data[i+3] ->a
                let color = saveLabelmap[k];
                if (color > 0) {
                  imgData.data[j + 0] = colorLut[color][0];
                  imgData.data[j + 1] = colorLut[color][1];
                  imgData.data[j + 2] = colorLut[color][2];
                } else {
                  imgData.data[j + 0] =
                    imgData.data[j + 1] =
                    imgData.data[j + 2] =
                      0;
                }

                imgData.data[j + 3] = 255;
              }
              ctx.putImageData(imgData, 0, 0);

              //Get data from canvas
              let img_b64_url = canvas.toDataURL();
              let file = dataURLtoFile(img_b64_url, 'label.png');

              formData.append(label[currentDicomIdx].labelname[i], file);
              labelnames.push(label[currentDicomIdx].labelname[i]);
            }
          }
        }
        formData.append('labelNames', labelnames);
      }
      formData.append('userId', userId);
      formData.append('objId', searchParams.get('pjId'));
      formData.append('dicomName', currentDicomName);
      formData.append(
        'sopUid',
        reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
      );
      formData.append(
        'studyUid',
        reduxDicom.imgIds[currentDicomIdx].split('&')[1].split('=')[1],
      );
      formData.append('process', searchParams.get('process'));
      formData.append('seriesBodyPart', searchParams.get('seriesBodyPart'));

      axios
        .post(`${process.env.REACT_APP_FLASK_URL}/SaveLabel`, formData, {
          'Content-Type': 'multipart/form-data',
        })
        .then((res) => {
          if (res.data === 'ok') {
            enqueueSnackbar('Save Label Completed', {
              variant: 'success',
              autoHideDuration: 1200,
            });

            if (searchParams.get('process') === '103005') {
              let formData2 = new FormData();
              formData2.append('objId', searchParams.get('pjId'));
              formData2.append(
                'studyId',
                reduxDicom.imgIds[currentDicomIdx].split('&')[1].split('=')[1],
              );
              formData2.append('userId', userId);
              // tscore - process status param추가
              formData2.append('process', searchParams.get('process'));

              axios
                .post(`${process.env.REACT_APP_FLASK_URL}/Tscore`, formData2, {
                  'Content-Type': 'multipart/form-data',
                })
                .then((res) => {
                  if (res.data.status === 'error') {
                    enqueueSnackbar(res.data.result, {
                      variant: 'warning',
                      style: { whiteSpace: 'pre-line' },
                      autoHideDuration: 5000,
                    });
                  } else if (res.data.status === 'ok') {
                    enqueueSnackbar('Analysis completed', {
                      variant: 'success',
                      autoHideDuration: 2000,
                    });
                  }
                });
            } else if (searchParams.get('pjcate') === '101003') {
              let formData3 = new FormData();
              formData3.append('userId', userId);
              formData3.append('objId', searchParams.get('pjId'));
              // formData.append('dicomName', currentDicomName[currentDicogfIdx]);
              formData3.append(
                'sopUid',
                reduxDicom.imgIds[currentDicomIdx].split('&')[3].split('=')[1],
              );

              //brush colotLut 추출 -> label 정보 db 조회후 변환을 위함.
              let colorLut = [];
              colorLut.push(state.colorLutTables[0][1]);
              colorLut.push(state.colorLutTables[0][9]);
              colorLut.push(state.colorLutTables[0][25]);
              colorLut.push(state.colorLutTables[0][2]);
              colorLut.push(state.colorLutTables[0][13]);
              colorLut.push(state.colorLutTables[0][8]);
              colorLut.push(state.colorLutTables[0][16]);

              formData3.append('colorLut', colorLut);
              axios
                .post(
                  `${process.env.REACT_APP_FLASK_URL}/LoadVFSSLabel`,
                  formData3,
                  {
                    'Content-Type': 'multipart/form-data',
                  },
                )
                .then((res) => {
                  if (res.data !== 'none') {
                    let len = res.data.dicomName.length;
                    let promiseArr = [];
                    for (let i = 0; i < len; i++) {
                      promiseArr.push(
                        db.labels.put({
                          dicomName: res.data.dicomName[i],
                          labelColor: res.data.labelColor[i],
                          labelImg: res.data.labelImg[i],
                          labelName: res.data.labelName[i],
                        }),
                      );
                    }
                    Promise.all(promiseArr).then(() => {
                      console.log('Label IdxDB update');
                    });
                  }
                });
            }
          }
        });
    }
  };

  // vfss - Predict Label hide status modify func
  const PredLabelHideAll = () => {
    let hideStatus = predlabelVisible;
    dispatch(updatePredictLabelVisible(!hideStatus));

    let element = document.querySelector('.viewport-element');
    for (let j = 1; j <= 5; j++) {
      if (getters.labelmaps3D(element).labelmaps3D[j] !== undefined) {
        getters.labelmaps3D(element).labelmaps3D[j].segmentsHidden = hideStatus
          ? segPredHideOffArr
          : segPredHideOnArr;
      }
      cornerstone.updateImage(element);
    }
    // labelmap에 여러 색상 segments hide 처리
    for (let k = 0; k < predLabelColorList.length; k++) {
      setters.toggleSegmentVisibility(element, predLabelColorList[k]);
      cornerstone.updateImage(element);
    }
  };

  // vfss - Label hide status modify func
  const LabelHideAll = () => {
    let hideStatus = labelVisible;
    dispatch(updateLabelVislble(!hideStatus));

    let element = document.querySelector('.viewport-element');
    // console.log(getters.labelmaps3D(element));

    for (let j = 6; j <= 10; j++) {
      if (getters.labelmaps3D(element).labelmaps3D[j] !== undefined) {
        getters.labelmaps3D(element).labelmaps3D[j].segmentsHidden = hideStatus
          ? segHideOffArr
          : segHideOnArr;
        cornerstone.updateImage(element);
      }
    }
    // labelmap에 여러 색상 segments hide 처리
    for (let k = 0; k < colorList.length; k++) {
      setters.toggleSegmentVisibility(element, colorList[k]);
      cornerstone.updateImage(element);
    }
  };

  useEffect(() => {
    let dicomCnt = dicom.length;
    if (dicomCnt > 0) {
      // label list del button disable 예외처리
      if (label[currentDicomIdx])
        if (label[currentDicomIdx].labelindex)
          if (label[currentDicomIdx].labelindex.length === 0) {
            // label list 없을때 label del 버튼 비활성화
            SetDelBtnDisable(true);
          } else {
            SetDelBtnDisable(false);
          }

      // label list add button disable 예외처리
      if (togglebuttonState[4] === true) {
        SetAddBtnDisable(true);
      } else {
        SetAddBtnDisable(false);
      }
    } else {
      SetAddBtnDisable(true);
      SetDelBtnDisable(true);
    }
  }, [currentDicomIdx, dicom.length, label, togglebuttonState]);

  //vfss 인경우 DIcom이동시 labelmapmax 초기화
  useEffect(() => {
    if (location.pathname === '/labeling/main/VFSS') {
      dispatch(updateLabelampMaxIdx(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDicomIdx]);

  return (
    <div>
      <Grid
        container
        // columns={12}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          backgroundColor: '#B3B6B7',
        }}
      >
        <Grid
          item
          xs={3}
          container
          columns={location.pathname === '/labeling/main/VFSS' ? 18 : 12}
          justifyContent="center"
        >
          <StyledButton onClick={AddLabel} disabled={addBtnDisable}>
            Add
          </StyledButton>
        </Grid>
        <Grid
          item
          xs={3}
          container
          columns={location.pathname === '/labeling/main/VFSS' ? 18 : 12}
          justifyContent="center"
        >
          <StyledButton
            onClick={() => {
              DelLabel(label, currentDicomIdx);
            }}
            disabled={delBtnDisable}
          >
            Del
          </StyledButton>
        </Grid>
        <Grid
          item
          xs={3}
          container
          columns={location.pathname === '/labeling/main/VFSS' ? 18 : 12}
          justifyContent="center"
        >
          <StyledButton
            onClick={() => {
              SaveLabel(
                label,
                currentDicomIdx,
                currentDicomCol,
                currentDicomRow,
              );
            }}
          >
            Save
          </StyledButton>
        </Grid>
        <Grid
          item
          xs={3}
          container
          columns={location.pathname === '/labeling/main/VFSS' ? 18 : 12}
          justifyContent="center"
        >
          <StyledButton onClick={DownloadLabel}>Down</StyledButton>
        </Grid>
        {location.pathname === '/labeling/main/VFSS' && (
          <Grid item xs={3} container columns={18} justifyContent="center">
            <StyledButton onClick={PredLabelHideAll}>
              PLbl{predlabelVisible ? ' On' : ' Off'}
            </StyledButton>
          </Grid>
        )}
        {location.pathname === '/labeling/main/VFSS' && (
          <Grid item xs={3} container columns={18} justifyContent="center">
            <StyledButton onClick={LabelHideAll}>
              Lbl{labelVisible ? ' On' : ' Off'}
            </StyledButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default LabelBtnGroup;
